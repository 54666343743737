import {Model} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, ModelSchema} from "../validation/Validation";
import {EnumValues} from "../validation/Validators";

export interface SocialMediaProfile extends Model {
    readonly platform: SocialMediaPlatform
    readonly username: string
    // blob, temp
    readonly raw_info: string
    readonly avatar_uri?: string
    readonly enriched_info?: string
    readonly following_count: number
    readonly follower_count: number
    readonly heart_count: number
    readonly video_count: number
    readonly digg_count: number
    readonly nickname: string
    readonly profile_create_time: number
    readonly signature: string
    readonly bio_link?: string
    readonly share_meta_title?: string
    readonly share_meta_description?: string
    readonly tiktok_id: string
}

export interface SocialMediaProfileKey {
    readonly platform: SocialMediaPlatform
    readonly username: string
}

export enum SocialMediaPlatform {
    TIKTOK = "TIKTOK",
    INSTAGRAM = "INSTAGRAM",
}

export class SocialMediaProfileSchemaDef extends ModelSchema<SocialMediaProfile> {
    public get KeyColumns(): IModelField<any, SocialMediaProfile>[] {
        return [this.Platform, this.Username]
    }

    getFriendlyName(value: SocialMediaProfile): string {
        return value.username
    }

    get apiResourceName(): string {
        return "socialMediaProfiles";
    }

    get SchemaName(): string {
        return "SocialMediaProfile"
    }

    public readonly Platform = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.SELECT,
        display_name: "Platform", name: "platform", field_source: FieldSourceType.USER_DEFINED, rules: [
            new EnumValues<SocialMediaProfile>(SocialMediaPlatform),
        ]
    })

    public readonly Username = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Username", name: "username", field_source: FieldSourceType.USER_DEFINED, rules: []
    })


    public readonly RawInfo = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Raw Info", name: "raw_info", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })


    public readonly EnrichedInfo = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Enriched Info", name: "enriched_info", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })


    public readonly FollowingCount = new FieldClass<number, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Following Count",
        name: "following_count",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })


    public readonly FollowerCount = new FieldClass<number, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Follower Count", name: "follower_count", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })


    public readonly HeartCount = new FieldClass<number, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Heart Count", name: "heart_count", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })


    public readonly VideoCount = new FieldClass<number, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Video Count", name: "video_count", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })


    public readonly DiggCount = new FieldClass<number, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Digg Count", name: "digg_count", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })


    public readonly Nickname = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Nickname", name: "nickname", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly AvatarUri = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Avatar URI", name: "avatar_uri", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly ProfileCreateTime = new FieldClass<number, SocialMediaProfile>({
        field_type: FieldType.DATE_TIME,
        display_name: "Profile Create Time",
        name: "profile_create_time",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })


    public readonly Signature = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Signature", name: "signature", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })


    public readonly BioLink = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Bio Link", name: "bio_link", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })


    public readonly ShareMetaTitle = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Sharre Meta Title",
        name: "share_meta_title",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })


    public readonly ShareMetaDescription = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Share Meta Description",
        name: "share_meta_description",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })


    public readonly TiktokId = new FieldClass<string, SocialMediaProfile>({
        field_type: FieldType.TEXT,
        display_name: "Tiktok Id", name: "tiktok_id", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

}

export const SocialMediaProfileSchema: SocialMediaProfileSchemaDef = new SocialMediaProfileSchemaDef()

