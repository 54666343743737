import {useEffect, useState} from "react";
import {Collapse} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useAppNav} from "../../hooks/useAppNav";
import {fixHref} from "../../utils/Utils";

function PowerNavSection(props: Props) {
    const [open, setOpen] = useState(false);
    const navigate = useAppNav()

    const {name, icon, links} = props

    function isActiveLink(link: SectionLink) {
        return link.href == window.location.pathname
    }


    // keep this section open on page load
    useEffect(() => {
        const hasActive = links.some(isActiveLink)
        if (hasActive != open)
            setOpen(hasActive)
    }, [window.location.pathname])

    function click() {
        if (links.length > 1) {
            setOpen(!open)
        } else {
            navigate(links[0].href)
        }
    }

    return (
        <>
            <a onClick={click}
               className={`nav-link d-flex align-items-center rounded-pill clickable ${(open && links.length > 1) ? "active" : ""}`}
               data-bs-toggle={links.length > 1 ? "collapse" : ""}
               aria-controls="example-collapse-text" aria-expanded={open}>
                <i className={`bi bi-${icon}`}/>
                <span>{name}</span>
                <span className="badge badge-sm rounded-pill me-n2 bg-success-subtle text-success ms-auto"></span>
            </a>
            {links.length > 1 && <Collapse in={open}>
                <ul className="nav nav-sm flex-column mt-1">
                    {links.map(link => {
                        const active = isActiveLink(link)
                        return (
                            <li key={link.href} className="nav-item">
                                <Link to={fixHref(link.href)}
                                   className={`nav-link ${active ? "fw-bold" : ""}`}>{link.label}</Link>
                            </li>
                        )
                    })}

                </ul>
            </Collapse>}

        </>
    )
}

export interface Props {
    readonly name: string
    readonly icon: string
    readonly links: SectionLink[]
}

export interface SectionLink {
    readonly label: string
    readonly href: string
}

export default PowerNavSection