import * as React from "react";
import {useContext} from "react";
import {OrgMembership, OrgMembershipSchema, UserSchema} from "lib-shared";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {ColumnSpec, RowData} from "../../components/tables/BaseTable";
import {OrgContext} from "../../context/OrgContext";
import ApiTable, {ApiOrderBy, createColumnFromNested} from "../../components/tables/ApiTable";
import {OrgMembershipsClient} from "lib-client";

function OrgUsersPage() {
    const {org, loading: orgLoading} = useContext(OrgContext);

    const columns: ColumnSpec<OrgMembership, ApiOrderBy>[] = [
        createColumnFromNested(UserSchema.FirstName, true, m => m.user),
        createColumnFromNested(UserSchema.LastName, true, m => m.user),
        createColumnFromNested(UserSchema.Email, true, m => m.user),
        createColumnFromNested(UserSchema.CreatedAt, true, m => m.user),
    ]

    return (
        <PowerPageContent title={"Users"}
                          description={`These are all the users in your org, ${org?.name}.`}
                          buttons={[
                              // <IconButton key={"invite"} action={invite} icon={"plus-circle"} label={"Invite"}/>,
                          ]}>
            <ApiTable<OrgMembership> client={OrgMembershipsClient.defaultClient}
                                     listOptions={{
                                         fullyHydrated: true,
                                     }}
                                     noDataMessage={"No Users."}
                                     tableSettings={{
                                         filterOptions: [],
                                         idExtractor: mem => mem.user_id,
                                         columns
                                     }}
            />
        </PowerPageContent>
    )
}

export default OrgUsersPage