import {ColumnSpec} from "./BaseTable";

function TableSettingsDropdown(props: Props) {

    function toggleAutoSync(e: React.ChangeEvent<HTMLInputElement>): void {
        props.setIsAutoRefresh(e.target.checked)
    }

    function toggleArchive(e: React.ChangeEvent<HTMLInputElement>): void {
        props.setShowArchive(e.target.checked)
    }

    // relies on checkbox name being the column name
    function toggleColumn(e: React.ChangeEvent<HTMLInputElement>): void {
        const checked = e.target.checked
        if (checked) {
            props.setSelectedColumns([...props.selectedColumns, e.target.name])
        } else {
            props.setSelectedColumns(props.selectedColumns.filter(c => c != e.target.name))
        }
    }

    function resetColumns() {
        props.setSelectedColumns(props.columns.filter(c => c.defaultColumn).map(c => c.name))
    }

    return <div className="dropdown">
        <a className="btn neutral text-xs rounded-1" href="#" role="button" id="table-settings-dropdown"
           data-bs-toggle="dropdown" aria-expanded="false">
            <i className="bi bi-layout-three-columns"></i>
        </a>
        <ul className="dropdown-menu" aria-labelledby="table-settings-dropdown">
            {props.exportCsv &&
                <>
                    <li>
                        <div className={"dropdown-item clickable"}
                             onClick={() => props.exportCsv!(props.columns.filter(c => props.selectedColumns.includes(c.name)))}>
                            Download CSV
                        </div>
                    </li>
                    <li>
                        <hr className="dropdown-divider"/>
                    </li>
                </>
            }
            {props.columns.map(c => {
                const checked = props.selectedColumns.includes(c.name)
                return (
                    <li key={`${c.name}-toggle`}>
                        <div className={"dropdown-item"}>
                            <input className="form-check-input" type="checkbox"
                                   onChange={toggleColumn}
                                   name={c.name} checked={checked}/>
                            <span className={"ms-2"}>{c.name}</span>
                        </div>
                    </li>
                )
            })}
            <li>
                <div className={"dropdown-item clickable"} onClick={resetColumns}>
                    Reset Columns
                </div>
            </li>
            {props.isAutoRefresh != undefined &&
                <>
                    <li>
                        <hr className="dropdown-divider"/>
                    </li>
                    <li>
                        <div className={"dropdown-item"}>
                            <input className="form-check-input" type="checkbox"
                                   onChange={toggleAutoSync}
                                   name="auto sync" checked={props.isAutoRefresh}/>
                            <span className={"ms-2"}>Auto Refresh</span>
                        </div>
                    </li>
                    {props.enableArchive &&
                        <li>
                            <div className={"dropdown-item"}>
                                <input className="form-check-input" type="checkbox"
                                       onChange={toggleArchive}
                                       name="Show Archive" checked={props.showArchive}/>
                                <span className={"ms-2"}>Show Archive</span>
                            </div>
                        </li>
                    }
                </>
            }
        </ul>
    </div>
}

interface Props extends ArchiveProps {
    readonly exportCsv?: (columns: ColumnSpec<any, any>[]) => void
    readonly isAutoRefresh: boolean | null
    readonly setIsAutoRefresh: (value: boolean) => void
    readonly columns: ColumnSpec<any, any>[]
    readonly selectedColumns: string[]
    readonly setSelectedColumns: (columns: string[]) => void
}

export interface ArchiveProps {
    readonly enableArchive: boolean
    readonly showArchive: boolean
    readonly setShowArchive: (showArchive: boolean) => void
}

export default TableSettingsDropdown