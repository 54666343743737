import * as React from "react";
import MaterialIcon from "../common/MaterialIcon";
import {BaseFilterField} from "./BaseTable";

function TableFilterItem<TFilter extends BaseFilterField>(props: Props<TFilter>) {

    return (
        <span className="badge text-bg-primary me-2">{`${props.filter.displayName} = ${props.filter.displayValue}`}
            <span onClick={() => props.removeFilter(props.filter)} className={"clickable p-2"}>
               <MaterialIcon icon={"x-circle"} size={12} marginStart={2}/>
           </span>
        </span>
    )
}

interface Props<TFilter extends BaseFilterField> {
    filter: TFilter
    removeFilter: (f: TFilter) => void
}

export default TableFilterItem