import Button from "react-bootstrap/Button";
import {useContext, useState} from "react";
import {UserContext} from "../../context/UserContext";
import MaterialIcon from "../common/MaterialIcon";
import OrgPicker from "./OrgPicker";
import {OrgContext} from "../../context/OrgContext";

function OrgButton(props: Props) {
    const {org} = useContext(OrgContext);

    const [show, setShow] = useState(false)

    function click() {
        setShow(true)
    }

    return (
        <div>
            <Button size={"sm"} variant={"outline-primary"} className={"rounded-pill"} onClick={click}>
                <MaterialIcon icon={"building"} size={12} margin={2}/>
                {org?.name ?? "???"}
                <MaterialIcon icon={"caret-down-fill"} size={12} marginStart={2}/>
            </Button>
            <OrgPicker show={show} setShow={setShow}/>
        </div>
    )
}

interface Props {
}

export default OrgButton