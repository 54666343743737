import ToastContainer from 'react-bootstrap/ToastContainer';
import {createContext, ReactNode, useState} from "react";
import MyToast, {ToastRequest} from "./AppToast";
import {v4 as uuidv4} from "uuid";
import {ApiErrorCode, ApiResponse, getErrorOrNull, isSuccess} from "lib-shared";
import {AppClientContext} from "../../utils/Utils";

export const ToastContext = createContext<ToastManagerProps>({
    toast: (s) => {
        console.log("Error: toasts not ready")
    }
});

function ToastManager({children}: Props) {
    const [toasts, setToasts] = useState<WrappedToast[]>([])

    function toast(toastReq: ToastRequest) {
        setToasts([...toasts, {
            id: uuidv4(),
            toast: toastReq
        }])
    }

    const value: ToastManagerProps = {
        toast
    }

    return (
        <ToastContext.Provider value={value}>
            <ToastContainer position={"top-end"} className={"m-5"}>
                {toasts.map(t => <MyToast key={t.id} toast={t.toast}/>)}
            </ToastContainer>
            {children}
        </ToastContext.Provider>
    );
}

export interface ToastManagerProps {
    readonly toast: (toast: ToastRequest) => void
}

export function toastIfErrors(responses: ApiResponse<any>[], ctx: AppClientContext): void {
    for (let response of responses) {
        toastIfError(ctx, response)
        if (!isSuccess(response))
            break
    }
}


export function toastResult(response: ApiResponse<any>, ctx: AppClientContext): void {
    if (isSuccess(response)) {
        ctx.toastProps.toast({
            title: "Success!",
            icon: "check",
            body: "Request successfully completed."
        })
    } else {
        toastIfError(ctx, response)
    }
}

export function toastIfError(ctx: AppClientContext | undefined, response: ApiResponse<any>): void {
    const error = getErrorOrNull(response)
    if (!error)
        return

    let title = "Error"
    switch (error.code) {
        case ApiErrorCode.FAILED_PRECONDITION:
            title = "Failed Precondition"
            break
        case ApiErrorCode.UNKNOWN_ERROR:
            title = "Error"
            break
        case ApiErrorCode.UNAUTHENTICATED:
            title = "Unauthenticated"
            break
        case ApiErrorCode.PERMISSION_DENIED:
            title = "Permission Denied"
            break
        case ApiErrorCode.RECORD_ALREADY_EXISTS:
            title = "Record Already Exists"
            break
        case ApiErrorCode.RECORD_NOT_FOUND:
            title = "Record Not Found"
            break
        case ApiErrorCode.UNAVAILABLE:
            title = "Server Unavailable"
            break
        case ApiErrorCode.INVALID_ARGUMENT:
            title = "Invalid Argument"
            break
        case ApiErrorCode.UNIMPLEMENTED:
            title = "Unsupported Operation"
            break;
        case ApiErrorCode.RESOURCE_EXHAUSTED:
            title = "Out of Quota"
            break;
        default:
            console.warn(`Unhandled API error code: ${error.code}`)
    }
    ctx!.toastProps.toast({
        title: title,
        body: error.message,
        variant: "danger",
        icon: "warning"
    })
}

interface WrappedToast {
    readonly id: string
    readonly toast: ToastRequest
}

interface Props {
    children?: ReactNode
}

export default ToastManager;