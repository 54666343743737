import {Row} from "react-bootstrap";
import {useRouteError} from "react-router-dom";
import ZLink from "../components/common/ZLink";
import {getDefaultPageForAppMode} from "../AppMode";

function ErrorPage() {
    const error: any = useRouteError();
    console.error(error);

    let title = "An Error Occurred"
    let message = "Please try again shortly"

    if ("status" in error && error.status == 404) {
        title = "Page not Found"
        message = "This page does not exist or has been moved."
    }

    // Uncaught ReferenceError: path is not defined
    return (
        <div className="bg-body-tertiary h-100">
            <div className={"container pt-5"}>
                <Row>
                    <div className={"col-lg-6 offset-lg-3 col-sm-12"}>
                        <h2 className={"text-danger"}>{title}</h2>
                        <div className="card p-5">
                            <p>{message}</p>
                            <p><ZLink href={getDefaultPageForAppMode()}>Return home</ZLink></p>
                        </div>
                    </div>
                </Row>
            </div>
        </div>
    )
}

export default ErrorPage