import {ColumnSpec} from "../../components/tables/BaseTable";
import ApiTable, {ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {useContext, useState} from "react";
import {
    isSuccess,
    LeadGroup,
    SegmentationClass,
    SegmentationJob,
    SegmentationJobResults,
    SegmentationJobResultsSchema
} from "lib-shared";
import {useParams} from "react-router-dom";
import {useApiGet} from "../../hooks/useApiGet";
import {UserContext} from "../../context/UserContext";
import {useApiList} from "../../hooks/useApiList";
import {
    LeadGroupsClient,
    SegmentationClassClient,
    SegmentationJobResultsClient,
    SegmentationJobsClient
} from "lib-client";
import SegmentToGroupMappingModal from "./SegmentToGroupMappingModal";
import IconButton from "../../components/common/IconButton";
import ListPickerModal from "../../components/listPicker/ListPickerModal";
import {toastIfError} from "../../context/toasts/ToastManager";

function SegmentationJobResultsPage() {
    const {clientContext} = useContext(UserContext)

    const [showGroupMapper, setShowGroupMapper] = useState(false)
    const [showGroupPicker, setShowGroupPicker] = useState(false)

    const params = useParams();
    const jobId = params["jobId"]!

    const job = useApiGet<SegmentationJob>(clientContext, SegmentationJobsClient.defaultClient, jobId)

    const segmentsResult = useApiList<SegmentationClass>(clientContext, SegmentationClassClient.defaultClient, {pageSize: 100}, {})

    const segments: SegmentationClass[] = segmentsResult.value ?? []

    const columns: ColumnSpec<SegmentationJobResults, ApiOrderBy>[] = [
        createColumn(SegmentationJobResultsSchema.LeadId, true),
        createColumn(SegmentationJobResultsSchema.PredictedClassId, true, {
            valueFormatter: (classId, result) => segments.find(s => s.class_id == classId)?.name ?? ""
        }),
        createColumn(SegmentationJobResultsSchema.PredictedClassExplanation, true),
        createColumn(SegmentationJobResultsSchema.UpdatedAt, false),
        createColumn(SegmentationJobResultsSchema.CreatedAt, true),
    ];

    return (
        <PowerPageContent title={`${job.value?.name} Results`}
                          description={"Here are the results of the mapping job. You can send more leads through this job any time."}
                          buttons={[
                              <IconButton
                                  key={"segment-group"}
                                  variant={"outline-primary"}
                                  action={() => setShowGroupPicker(true)}
                                  icon={"plus-circle"}
                                  label={"Segment another Group"}
                              />,
                              <IconButton
                                  key={"add-results-to-groups"}
                                  action={() => setShowGroupMapper(true)}
                                  icon={"folder-plus"}
                                  label={"Add to Groups"}
                              />,
                          ]}
        >
            <ApiTable<SegmentationJobResults>
                client={SegmentationJobResultsClient.defaultClient}
                noDataMessage={"No Results yet."}
                listOptions={{
                    filterFields: [
                        {
                            field: SegmentationJobResultsSchema.JobId.name,
                            value: [jobId!]
                        }
                    ]
                }}
                tableSettings={{
                    columns,
                    idExtractor: (c) => c.job_id,
                    filterOptions: [],
                }}
            />
            {showGroupMapper &&
                <SegmentToGroupMappingModal show={showGroupMapper} close={() => setShowGroupMapper(false)}
                                            segmentationJob={job.value!}/>}

            <ListPickerModal<LeadGroup>
                show={showGroupPicker}
                title={"Pick a Group"}
                description={"Pick a group to segment with this job."}
                client={LeadGroupsClient.defaultClient}
                filters={[]}
                getId={(c) => c.lead_group_id}
                mapper={(c) => c.name}
                callback={async (group: LeadGroup | null) => {
                    if (!group) {
                        setShowGroupPicker(false);
                        return;
                    }

                    const result = await SegmentationJobsClient.defaultClient.segmentGroup({
                        lead_group_id: group.lead_group_id,
                        segmentation_job_id: jobId,
                    }, clientContext)
                    toastIfError(clientContext, result);
                    if (isSuccess(result))
                        setShowGroupPicker(false);
                }}
            />

        </PowerPageContent>
    );

}

export default SegmentationJobResultsPage