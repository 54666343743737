import {OrgModel} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {MinLength, Required} from "../validation/Validators";

// todo archivable
export interface FirstPartyField extends OrgModel {
    // also name of the field
    readonly field_id: string
    readonly display_only: boolean
    readonly field_description: string
}

export class FirstPartyFieldSchemaDef extends OrgModelSchema<FirstPartyField> {
    public get apiResourceName(): string {
        return "firstPartyFields"
    }

    getFriendlyName(value: FirstPartyField): string {
        return value.field_id
    }

    get SchemaName(): string {
        return "FirstPartyField"
    }

    get KeyColumns(): IModelField<any, FirstPartyField>[] {
        return [this.OrgId, this.FieldId];
    }

    public readonly FieldId = new FieldClass<string, FirstPartyField>({
        field_type: FieldType.TEXT,
        display_name: "Name", name: "field_id", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<FirstPartyField>(),
            new MinLength<FirstPartyField>(4)
        ]
    })

    public readonly DisplayOnly = new FieldClass<boolean, FirstPartyField>({
        field_type: FieldType.TOGGLE,
        display_name: "Display Only",
        name: "display_only",
        field_source: FieldSourceType.USER_DEFINED,
        defaultValue: false,
        rules: []
    })

    public readonly FieldDescription = new FieldClass<string, FirstPartyField>({
        field_type: FieldType.TEXT,
        display_name: "Description",
        name: "field_description",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [
            new Required<FirstPartyField>(),
            new MinLength<FirstPartyField>(4)
        ]
    })
}

export const FirstPartyFieldSchema: FirstPartyFieldSchemaDef = new FirstPartyFieldSchemaDef()