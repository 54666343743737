import {createContext, ReactNode, useState} from "react";
import {v4 as uuidv4} from "uuid";
import TypeConfirmModal from "./TypeConfirmModal";
import {BaseCrudClient} from "lib-client";
import {Model} from "lib-shared";

export const ConfirmModalContext = createContext<ConfirmModalProps>({
    confirm: (s: ConfirmModalRequest<any>) => {
        console.log("Error: modals not ready")
    }
});

function ConfirmModalManager(props: Props) {
    const [requests, setRequests] = useState<WrappedRequest[]>([])


    function close(id: string) {
        setRequests(requests.filter(r => r.id != id))
    }

    function confirm(request: ConfirmModalRequest<any>) {
        setRequests(
            [...requests, {
                id: uuidv4(),
                req: request
            }]
        )
    }

    const value: ConfirmModalProps = {
        confirm
    }

    return (
        <ConfirmModalContext.Provider value={value}>
            {requests.map(t => <TypeConfirmModal key={t.id} requestId={t.id} req={t.req} close={close}/>)}
            {props.children}
        </ConfirmModalContext.Provider>
    )
}

interface Props {
    children?: ReactNode
}

interface WrappedRequest {
    readonly id: string
    readonly req: ConfirmModalRequest<any>
}

export interface ConfirmModalRequest<T extends Model> {
    readonly client: BaseCrudClient<T>
    // TODO use generic type "T" rather than "Resource" to be more type safe
    readonly resources: Resource[]
    readonly action_type: ResourceActionType
    readonly challenge?: string
}

export type ResourceActionType = "delete" | "archive"

export interface Resource {
    readonly id: string
    readonly name: string
}

export interface ConfirmModalProps {
    readonly confirm: (req: ConfirmModalRequest<any>) => void
}


export default ConfirmModalManager