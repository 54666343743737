import {OrgModel} from "./Model";
import {Organization} from "./Organization";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {CharacterSet, CharacterSetType, Required} from "../validation/Validators";

export interface Invitation extends OrgModel {
    readonly invitation_id: string
    readonly email: string
    // hydrated only
    readonly org?: Organization
}


export class InvitationSchemaDef extends OrgModelSchema<Invitation> {
    get SchemaName(): string {
        return "Invitation"
    }

    getFriendlyName(value: Invitation): string {
        return value.email
    }

    get apiResourceName(): string {
        return "invitations";
    }

    public readonly InvitationId = new FieldClass<string, Invitation>({
        field_type: FieldType.TEXT,
        display_name: "Invite ID", name: "invitation_id", field_source: FieldSourceType.SYSTEM_DEFINED, rules: [
            new Required<Invitation>(),
        ]
    })
    public readonly Email = new FieldClass<string, Invitation>({
        field_type: FieldType.TEXT,
        display_name: "Email", name: "email", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<Invitation>(),
            new CharacterSet<Invitation>(CharacterSetType.Email)
        ]
    })

    get KeyColumns(): IModelField<any, Invitation>[] {
        return [this.OrgId, this.InvitationId];
    }
}

export const InvitationSchema: InvitationSchemaDef = new InvitationSchemaDef()