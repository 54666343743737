import {ReactNode} from "react";
import MaterialIcon from "./MaterialIcon";

function Button(props: ButtonProps) {

    let variant = ""

    switch (props.variant) {
        case "neutral":
            variant = "btn-neutral"
            break;
        case "primary":
            variant = "btn-primary"
            break;
        case "outline-primary":
            variant = "btn-outline-primary"
            break;
        case "secondary":
            variant = "btn-secondary"
            break;
        case "outline-secondary":
            variant = "btn-outline-secondary"
            break;
        case "success":
            variant = "btn-success"
            break;
        case "outline-success":
            variant = "btn-outline-success"
            break;
        case "danger":
            variant = "btn-danger"
            break;
        case "outline-danger":
            variant = "btn-outline-danger"
            break;
        case "warning":
            variant = "btn-warning"
            break;
        case "outline-warning":
            variant = "btn-outline-warning"
            break;
        case "info":
            variant = "btn-info"
            break;
        case "outline-info":
            variant = "btn-outline-info"
            break;
    }

    return (
        <button type="button" onClick={props.onClick}
                disabled={props.disabled}
                className={`btn btn-sm ${variant} ${props.className}`}>{props.icon &&
            <MaterialIcon
                icon={props.icon}
                margin={props.label? 2 : undefined}
                size={16}/>}{props.label}
        </button>
    )
}

export interface ButtonProps {
    readonly variant: ButtonVariant
    readonly label: string
    readonly icon?: string
    readonly disabled?: boolean
    readonly className?: string
    readonly onClick?: () => void
}

export type ButtonVariant =
    "neutral" |
    "primary" |
    "outline-primary" |
    "secondary" |
    "outline-secondary" |
    "success" |
    "outline-success" |
    "danger" |
    "outline-danger" |
    "warning" |
    "outline-warning" |
    "info" |
    "outline-info"

export default Button