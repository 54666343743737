import {UserContext} from "../../context/UserContext";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {
    createKey,
    FieldClass,
    FieldSourceType,
    FieldType,
    IModelField,
    isSuccess,
    ModelSchema,
    Required,
    SocialMediaPlatform
} from "lib-shared";
import {toastIfError} from "../../context/toasts/ToastManager";
import {FormValueSelectItem} from "../../components/forms/FormFields";
import AppModal, {BaseModalProps} from "../../components/AppModal";
import {Modal} from "react-bootstrap";
import {useContext} from "react";
import {parsePostUrl} from "./SocialMediaUtils";
import {createPostPageLink} from "./TikTokPostPage";
import {useAppNav} from "../../hooks/useAppNav";
import {TikTokPostsClient} from "lib-client";

function AddSocialMediaPostModal(props: Props) {

    const {clientContext} = useContext(UserContext)
    const nav = useAppNav()

    async function submit(payload: FormSubmitPayload<AddPost>): Promise<boolean> {
        let value = payload.value

        const post = parsePostUrl(value.url)

        const response = await TikTokPostsClient.defaultClient.addPost(post, clientContext)

        if (!isSuccess(response)) {
            toastIfError(clientContext, response);
            return false;
        }

        props.close();

        nav(createPostPageLink(post))
        return true
    }

    const platformOptions: FormValueSelectItem[] = Object.keys(SocialMediaPlatform).map(p => {
        return {
            id: p,
            label: p
        }
    })

    return (
        <AppModal show={props.show} onHide={props.close} name={"field editor"}>
            <Modal.Header closeButton>
                <Modal.Title>Add Social Media Post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppForm
                    onSubmit={submit}
                    onCancel={props.close}
                    schema={AddPostSchema}
                    fields={[
                        {
                            fieldType: "text",
                            tip: "Enter the URL to a social media post",
                            field: AddPostSchema.Url,
                        },
                    ]}
                />
            </Modal.Body>
        </AppModal>
    )
}

interface AddPost {
    url: string
}

class AddPostSchemaDef extends ModelSchema<AddPost> {
    getFriendlyName(value: AddPost): string {
        return ""
    }

    get apiResourceName(): string {
        return ""
    }

    get KeyColumns(): IModelField<any, AddPost>[] {
        return []
    }

    get SchemaName(): string {
        return "Add Post"
    }

    public readonly Url = new FieldClass<string, AddPost>({
        name: "url",
        display_name: "Post URL",
        field_source: FieldSourceType.USER_DEFINED,
        field_type: FieldType.TEXT,
        rules: [
            new Required<AddPost>(),
        ]
    })
}

const AddPostSchema = new AddPostSchemaDef()


interface Props extends BaseModalProps {

}

export default AddSocialMediaPostModal