import {Model} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, ModelSchema} from "../validation/Validation";
import {SocialMediaPlatform, SocialMediaProfileKey} from "./SocialMediaProfile";

export interface TikTokPost extends Model {
    readonly username: string
    readonly post_id: string
    readonly thumbnail_uri?: string
    readonly create_time: Date
    readonly description: string
    readonly collect_count: number
    readonly comment_count: number
    readonly digg_count: number
    readonly play_count: number
    readonly share_count: number
    readonly video_uri?: string
    readonly music_id?: string
    readonly video_duration_seconds: number
    readonly video_summary?: string
}

export interface SocialMediaPostKey extends SocialMediaProfileKey {
    readonly post_id: string
}

export function createKey(post: TikTokPost): SocialMediaPostKey {
    return {
        platform: SocialMediaPlatform.TIKTOK,
        username: post.username,
        post_id: post.post_id
    }
}


export class TikTokPostSchemaDef extends ModelSchema<TikTokPost> {
    public get KeyColumns(): IModelField<any, TikTokPost>[] {
        return [this.Username, this.PostId]
    }

    getFriendlyName(value: TikTokPost): string {
        return value.post_id;
    }

    get apiResourceName(): string {
        return "tikTokPosts";
    }

    get SchemaName(): string {
        return "TikTokPost"
    }

    public readonly PostId = new FieldClass<string, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Post ID", name: "post_id", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly Username = new FieldClass<string, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Username", name: "username", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly CreateTime = new FieldClass<number, TikTokPost>({
        field_type: FieldType.DATE_TIME,
        display_name: "Create Time", name: "create_time", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly MusicId = new FieldClass<string, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Music Id", name: "music_id", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly Description = new FieldClass<string, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Description", name: "description", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly CollectCount = new FieldClass<number, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Collect Count", name: "collect_count", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly DiggCount = new FieldClass<number, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Diggs", name: "digg_count", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly ShareCount = new FieldClass<number, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Shares", name: "share_count", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly CommentCount = new FieldClass<number, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Comments", name: "comment_count", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly PlayCount = new FieldClass<number, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Plays", name: "play_count", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly ThumbnailUri = new FieldClass<string, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Thumbnail URI", name: "thumbnail_uri", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly VideoDurationSeconds = new FieldClass<number, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Video Duration",
        name: "video_duration_seconds",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })

    public readonly VideoSummary = new FieldClass<string, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Video Summary", name: "video_summary", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })
    public readonly VideoUri = new FieldClass<string, TikTokPost>({
        field_type: FieldType.TEXT,
        display_name: "Video Uri", name: "video_uri", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })
}

export const TikTokPostSchema: TikTokPostSchemaDef = new TikTokPostSchemaDef()
