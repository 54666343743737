import LoadingIndicator from "../../components/LoadingIndicator";
import {isSuccess, Organization, OrganizationSchema} from "lib-shared";
import * as React from "react";
import {useContext, useState} from "react";
import {UserContext} from "../../context/UserContext";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {OrgContext} from "../../context/OrgContext";
import {OrganizationsClient} from "lib-client";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {useApiGet} from "../../hooks/useApiGet";
import {Row} from "react-bootstrap";
import {toastIfError} from "../../context/toasts/ToastManager";

function OrganizationPage(props: Props) {
    const {clientContext} = useContext(UserContext);
    const {loading, org} = useContext(OrgContext);

    const orgResponse = useApiGet<Organization>(clientContext, OrganizationsClient.defaultClient, org!.org_id)

    async function save(payload: FormSubmitPayload<Organization>): Promise<boolean> {
        const updatedOrg: Organization = {
            ...org!,
            name: payload.value.name
        }
        const response = await new OrganizationsClient().update({value: updatedOrg}, clientContext)

        if (isSuccess(response)) {
            toastIfError(clientContext, response)
            return false
        }

        return true
    }

    return (
        <PowerPageContent title={"Organization"}>
            <p>Collaborate with your team by joining the same org</p>
            {loading && <LoadingIndicator/>}
            <Row>
                <div className={"col-6"}>

                    <AppForm
                        onSubmit={save}
                        initialValueLazy={orgResponse}
                        schema={OrganizationSchema}
                        fields={[
                            {
                                fieldType: "text",
                                field: OrganizationSchema.Name
                            },
                            {
                                fieldType: "toggle",
                                tip: "If enabled, your AI will be provided with additional information on each lead from third party sources.",
                                field: OrganizationSchema.EnableThirdPartyDataEnrichment
                            }
                        ]}
                    />
                </div>
            </Row>

        </PowerPageContent>
    )
}

interface Props {

}

export default OrganizationPage