// from https://fonts.google.com/icons?icon.style=Rounded
import {ColorType, colorTypeToClass} from "../../utils/Utils";

function MaterialIcon(props: Props) {

    const size = props.size ?? 20

    let margin = ""
    if (props.margin) {
        margin = `me-${props.margin}`
    }
    let marginStart = ""
    if (props.marginStart) {
        marginStart = `ms-${props.marginStart}`
    }

    const color = colorTypeToClass(props.color!)

    const display = (props.display?? true)? "": "d-none"

    return (
        <i className={`bi bi-${props.icon} align-middle ${color} ${margin} ${marginStart} ${display}`}
           style={{fontSize: `${size}px`}}/>
    );
}

interface Props {
    icon: string
    color?: ColorType
    size?: number
    display?: boolean
    margin?: 1 | 2 | 3 | 4 | 5
    marginStart?: 1 | 2 | 3 | 4 | 5
}


export default MaterialIcon;