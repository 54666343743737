import AppModal from "../../components/AppModal";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {isSuccess, SocialMediaPlatform, SocialMediaProfile, SocialMediaProfileSchema} from "lib-shared";
import {SocialMediaProfilesClient} from "lib-client";
import {toastIfError} from "../../context/toasts/ToastManager";
import {eventBus} from "../../utils/EventBus";
import {Modal} from "react-bootstrap";
import {UserContext} from "../../context/UserContext";
import {useContext} from "react";
import {FormValueSelectItem} from "../../components/forms/FormFields";

function NewSocialMediaProfileModal(props: Props) {
    const {clientContext} = useContext(UserContext)

    async function submit(payload: FormSubmitPayload<SocialMediaProfile>): Promise<boolean> {
        let value = payload.value

        if (payload.value.platform == SocialMediaPlatform.TIKTOK && !value.username.startsWith("@")) {
            value = {
                ...value,
                username: "@" + value.username
            }
        }

        const response = await SocialMediaProfilesClient.defaultClient.create(value, clientContext)

        if (!isSuccess(response)) {
            toastIfError(clientContext, response);
            return false;
        }

        props.close();

        eventBus.trigger("item-changed", value);
        return true
    }

    const platformOptions: FormValueSelectItem[] = Object.keys(SocialMediaPlatform).map(p => {
        return {
            id: p,
            label: p
        }
    })

    return (
        <AppModal show={props.show} onHide={props.close} name={"field editor"}>
            <Modal.Header closeButton>
                <Modal.Title>New Social Media profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppForm
                    onSubmit={submit}
                    onCancel={props.close}
                    schema={SocialMediaProfileSchema}
                    fields={[
                        {
                            fieldType: "select",
                            options: platformOptions,
                            field: SocialMediaProfileSchema.Platform,
                        },
                        {
                            fieldType: "text",
                            field: SocialMediaProfileSchema.Username,
                        },
                    ]}
                />
            </Modal.Body>
        </AppModal>
    )
}

interface Props {
    readonly show: boolean
    readonly close: () => void
}

export default NewSocialMediaProfileModal
