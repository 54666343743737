import {Lead} from "./Lead";
import {OrgModel} from "./Model";
import {Campaign} from "./Campaign";
import {FilterField} from "../requests/Api";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {MinLength, Required} from "../validation/Validators";

export interface Draft extends OrgModel {

    readonly campaign_id: string
    readonly draft_id: string
    readonly lead_id: string
    readonly state: DraftState
    readonly subject?: string
    readonly body?: string
    readonly greeting?: string
    readonly closing?: string
    readonly generation_category: GenerationCategory

    // hydrated fields
    readonly lead?: Lead
    readonly campaign?: Campaign
    readonly prediction_id?: string
}

export class DraftSchemaDef extends OrgModelSchema<Draft> {
    get SchemaName(): string {
        return "Draft"
    }

    getFriendlyName(value: Draft): string {
        return value.lead_id
    }

    get apiResourceName(): string {
        return "drafts";
    }

    get KeyColumns(): IModelField<any, Draft>[] {
        return [this.OrgId, this.DraftId];
    }


    public readonly DraftId = FieldClass.createIdField("draft_id")
    public readonly PredictionId = FieldClass.createIdField("prediction_id")
    public readonly CampaignId = FieldClass.createIdField("campaign_id")

    public readonly LeadId = FieldClass.createIdField("lead_id")


    public readonly Subject = new FieldClass<string, Draft>({
        field_type: FieldType.TEXT,
        display_name: "Subject", name: "subject", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<Draft>(),
            new MinLength<Draft>(3)
        ]
    })
    public readonly Body = new FieldClass<string, Draft>({
        field_type: FieldType.TEXT,
        display_name: "Body", name: "body", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<Draft>(),
            new MinLength<Draft>(32)
        ]
    })
    public readonly Greeting = new FieldClass<string, Draft>({
        field_type: FieldType.TEXT,
        display_name: "Greeting", name: "greeting", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<Draft>(),
            new MinLength<Draft>(32)
        ]
    })
    public readonly Closing = new FieldClass<string, Draft>({
        field_type: FieldType.TEXT,
        display_name: "Closing", name: "closing", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<Draft>(),
            new MinLength<Draft>(32)
        ]
    })
    public readonly State = new FieldClass<DraftState, Draft>({
        field_type: FieldType.TEXT,
        display_name: "State", name: "state", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })
    public readonly GenerationCategory = new FieldClass<GenerationCategory, Draft>({
        field_type: FieldType.TEXT,
        display_name: "Generation Category",
        name: "generation_category",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })
}

export const DraftSchema: DraftSchemaDef = new DraftSchemaDef()


export function isUserEditableState(draftState: DraftState) {
    return draftState == "ready" || draftState == "skipped"
}

export type GenerationCategory =
    'pending'
    | 'defaultNoName'
    | 'defaultWithName'
    | 'generatedNoName'
    | 'generatedWithName'

export const GenerationCategories: GenerationCategory[] = [
    'pending',
    'defaultNoName',
    'defaultWithName',
    'generatedNoName',
    'generatedWithName'
]

export const PersonalizedGenerationCategories: GenerationCategory[] = [
    "generatedNoName",
    "generatedWithName"
]
export const NonPersonalizedGenerationCategories: GenerationCategory[] = [
    "defaultNoName",
    "defaultWithName"
]

export enum EmailSection {
    Subject,
    Greeting,
    Body,
    Closing
}

export function getDraftSection(draft: Draft, section: EmailSection): string | null {
    switch (section) {
        case EmailSection.Subject:
            return draft.subject ?? null
        case EmailSection.Greeting:
            return draft.greeting ?? null
        case EmailSection.Body:
            return draft.body ?? null
        case EmailSection.Closing:
            return draft.closing ?? null
    }
}

export type DraftState = "generating" | "ready" | "sent" | "sending" | "skipped"

export const DraftStates: DraftState[] = ["generating", "ready", "sent", "sending", "skipped"]

export type Tone = "casual" | "professional" | "excited"
export const Tones: Tone[] = ["casual", "professional", "excited"]

export type SimpleGenerationCategory = "pending" | "generated" | "default"
export const Draft_GenerationCategory = "generation_category"

export function getSimpleGenCategory(gen: GenerationCategory): SimpleGenerationCategory {
    switch (gen) {
        case "pending":
            return "pending"
        case "defaultNoName":
            return "default";
        case "defaultWithName":
            return "default";
        case "generatedNoName":
            return "generated";
        case "generatedWithName":
            return "generated";
    }
}

export function getGenerationFilters(gen: SimpleGenerationCategory): FilterField[] {
    return GenerationCategories.filter(c => getSimpleGenCategory(c) == gen).map(c => {
        return {
            field: Draft_GenerationCategory,
            value: [c]
        }
    })
}