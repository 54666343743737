import Form from 'react-bootstrap/Form';
import {LeadGroup} from "lib-shared";
import AddGroupModal from "./groups/AddGroupModal";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {UserContext} from "../context/UserContext";

import {useApiList} from "../hooks/useApiList";
import {LeadGroupsClient} from "lib-client";

function SelectGroup(props: Props) {
    const {clientContext} = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);
    const {group} = props

    const createNewKey = "Create New"

    const groupsResult = useApiList<LeadGroup>(clientContext, LeadGroupsClient.defaultClient, {}, {})

    const groups = groupsResult.value ?? []

    useEffect(() => {
        if (groups.length > 0 && group == null) {
            if (props.allowNull) {
                callback(null)
            } else {
                callback(groups[0])
            }
        }
    }, [groups.length]);

    function callback(group: LeadGroup | null) {
        props.callback(group)
    }

    function groupAdded(group: LeadGroup) {
        callback(group)
    }

    function onChange(e: ChangeEvent<HTMLSelectElement>) {
        if (e.target.value == createNewKey) {
            setShowModal(true)
            return
        }
        const id: string = e.target.value
        if (id == "") {
            callback(null)
            return
        }
        const group = groups.find(g => e.target.value == g.lead_group_id) ?? null
        callback(group)
    }

    const options = groups.map(group => <option key={group.lead_group_id}
                                                value={group.lead_group_id}>{group.name}</option>)

    return (
        <Form.Group className="mb-3" controlId={"select-group"}>
            <Form.Label>Select a group</Form.Label>
            <Form.Select aria-label="Default select example" onChange={onChange} value={group?.lead_group_id ?? ""}>
                {props.allowNull && <option key={"null"} value={""}></option>}
                {options}
                <option value={createNewKey} key="-1">Create New Group...</option>
            </Form.Select>
            <div className={"faux-link"} onClick={() => setShowModal(true)}>Add Group</div>
            <AddGroupModal show={showModal}
                           close={() => setShowModal(false)}
                           callback={groupAdded}
                           callbackCancel={() => setShowModal(false)}/>
        </Form.Group>
    );
}

export interface Props {
    readonly group: LeadGroup | null
    readonly allowNull?: boolean
    readonly callback: (groupId: LeadGroup | null) => void
}

export default SelectGroup;