import {ReactNode} from "react";
import MaterialIcon from "../common/MaterialIcon";
import {fixHref} from "../../utils/Utils";
import {Link} from "react-router-dom";

function PowerPageContent(props: Props) {

    return (
        <>
            <div className="mb-3">
                <div className="row g-3 align-items-center">
                    {props.parentHref &&
                        <span><Link to={fixHref(props.parentHref)}><MaterialIcon icon={"arrow-left"} size={28}/></Link></span>}
                    <div className="col"><h1 className="ls-tight">{props.icon&& <MaterialIcon size={32} margin={4} icon={props.icon}/>}{props.title}</h1></div>
                    {props.description && <p className="text-sm text-muted">{props.description}</p>}
                    <div className="col">
                        <div className="hstack gap-2 justify-content-end">
                            {props.buttons}
                        </div>
                    </div>
                </div>
            </div>
            {props.children}
        </>
    )
}

export interface Props {
    readonly title: string
    readonly icon?: string
    readonly parentHref?: string
    readonly description?: string | ReactNode
    readonly buttons?: ReactNode[]
    readonly children: ReactNode
}

export default PowerPageContent