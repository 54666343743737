function Disabled() {
    return (
        <div>
            <h1>Account Disabled</h1>
            <p className={"mt-5"}>
                Sorry, it looks like your account is disabled. Please reach out to your account manager for help.
            </p>
        </div>
    );
}

export default Disabled;