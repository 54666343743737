import PowerPageContent from "../../components/powerPage/PowerPageContent";
import CounterMetric from "../../components/metrics/CounterMetric";
import {Col} from "react-bootstrap";
import ChecklistWidget from "../../components/metrics/ChecklistWidget";
import {DraftSchema, DraftStates, FilterField, isUserEditableState, OrgMembershipSchema} from "lib-shared";
import ActiveCampaignsWidget from "../../components/metrics/ActiveCampaignsWidget";
import {SenderProfileEditorKey} from "../../components/emailProviders/SenderProfileEditor";
import {CampaignEditorKey} from "../campaigns/list/CampaignsPage";
import {GroupsEditorKey} from "../groups/GroupsPage";
import {
    CampaignsClient,
    DraftsClient,
    LeadGroupsClient,
    LeadsClient,
    OrgMembershipsClient,
    SenderProfilesClient
} from "lib-client";
import {useContext, useEffect} from "react";
import {UserContext} from "../../context/UserContext";
import ZLink from "../../components/common/ZLink";

function DashboardPage() {
    const {clientContext} = useContext(UserContext);

    const draftFilters = DraftStates.filter(state => isUserEditableState(state)).map<FilterField>(state => {
        return {
            field: DraftSchema.State.name,
            value: [state]
        }
    })

    useEffect(() => {
        OrgMembershipsClient.defaultClient.orgUsed(undefined, clientContext)
    }, []);

    return (
        <PowerPageContent title={"Dashboard"}>
            <div className={"row "}>
                <Col className={"mb-5"}>
                    <ZLink href={"/leads"}>
                        <CounterMetric title={"Leads Researched with AI"} icon={"search"}
                                       client={LeadsClient.defaultClient} filters={[]}
                                       gtZeroColor={"success"}
                                       unit={"Leads"}/>
                    </ZLink>
                </Col>
                <Col className={"mb-5"}>
                    <ZLink href={"/outbox"}>
                        <CounterMetric title={"Emails Personalized with AI"} icon={"envelope"}
                                       client={DraftsClient.defaultClient}
                                       filters={[]} unit={"Emails"}/>
                    </ZLink>
                </Col>
                <Col className={"mb-5"}>
                    <ZLink href={"/outbox"}>
                        <CounterMetric title={"Pending Drafts"} icon={"inbox"}
                                       client={DraftsClient.defaultClient}
                                       eqZeroColor={"success"} gtZeroColor={"warning"}
                                       filters={draftFilters} unit={"Drafts"}/></ZLink>
                </Col>
                <Col className={"mb-5"}>
                    <ZLink href={"/campaigns"}>
                        <CounterMetric title={"Campaigns Created"} icon={"megaphone"}
                                       client={CampaignsClient.defaultClient} filters={[]}
                                       unit={"Campaigns"}/></ZLink>
                </Col>
            </div>

            <div className={"row mb-5"}>
                <Col>

                    <ChecklistWidget
                        goals={[

                            {
                                name: "Create a Group",
                                href: `/groups?${GroupsEditorKey}=`,
                                query: {
                                    client: LeadGroupsClient.defaultClient,
                                    filters: [],
                                },
                                target: 1,
                            },
                            {
                                name: "Create a Sender Profile",
                                href: `/settings/profiles?${SenderProfileEditorKey}=`,
                                query: {
                                    client: SenderProfilesClient.defaultClient,
                                    filters: [],
                                },
                                target: 1,
                            },
                            {
                                name: "Create a Campaign",
                                href: `/campaigns?${CampaignEditorKey}=`,
                                query: {
                                    client: CampaignsClient.defaultClient,
                                    filters: [],
                                },
                                target: 1,
                            },

                        ]}
                    />
                </Col>
                <Col>
                    <ActiveCampaignsWidget/>
                </Col>
            </div>
        </PowerPageContent>
    )
}

export default DashboardPage;