import {OrgModel} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {MinLength, Required} from "../validation/Validators";

export interface SegmentationJobResults extends OrgModel {
    // also name of the field
    readonly job_id: string;
    readonly lead_id: string;
    readonly predicted_class_id: string;
    readonly predicted_class_explanation: string;
}

export class SegmentationJobResultsSchemaDef extends OrgModelSchema<SegmentationJobResults> {
    public get KeyColumns(): IModelField<any, SegmentationJobResults>[] {
        return [this.OrgId, this.JobId, this.LeadId]
    }

    getFriendlyName(value: SegmentationJobResults): string {
        return value.job_id
    }

    get apiResourceName(): string {
        return "segmentationJobResults";
    }

    get SchemaName(): string {
        return "SegmentationJobResults";
    }

    public readonly JobId = new FieldClass<string, SegmentationJobResults>({
        field_type: FieldType.TEXT,
        display_name: "Job ID",
        name: "job_id",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [new Required<SegmentationJobResults>(), new MinLength<SegmentationJobResults>(1)],
    });

    public readonly LeadId = new FieldClass<string, SegmentationJobResults>({
        field_type: FieldType.TEXT,
        display_name: "Lead ID",
        name: "lead_id",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [new Required<SegmentationJobResults>(), new MinLength<SegmentationJobResults>(1)],
    });

    public readonly PredictedClassId = new FieldClass<string, SegmentationJobResults>({
        field_type: FieldType.TEXT,
        display_name: "Segment",
        name: "predicted_class_id",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [new Required<SegmentationJobResults>(), new MinLength<SegmentationJobResults>(1)],
    });

    public readonly PredictedClassExplanation = new FieldClass<string, SegmentationJobResults>({
        field_type: FieldType.TEXT,
        display_name: "Prediction Class Explanation",
        name: "predicted_class_explanation",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [new Required<SegmentationJobResults>(), new MinLength<SegmentationJobResults>(1)],
    });
}

export const SegmentationJobResultsSchema: SegmentationJobResultsSchemaDef =
    new SegmentationJobResultsSchemaDef();
