import SlimMetric from "../../components/metrics/SlimMetric";
import {FilterField, FilterOperator, LeadGroupSchema} from "lib-shared";
import {LeadGroupMembershipsClient, LeadsClient} from "lib-client";

function NotSummarizedMetric(props: Props) {

    const filters: FilterField[] = [
        {field: "text_summary", operator: FilterOperator.IS_NULL, value: []},
    ]

    if (props.group_id) {
        filters.push({
            field: LeadGroupSchema.LeadGroupId.name,
            operator: FilterOperator.EQUALS,
            value: [props.group_id]
        })
    }

    return (
        <SlimMetric
            client={props.group_id != null ? LeadGroupMembershipsClient.defaultClient : LeadsClient.defaultClient}
            hydrate={true}
            color={"warning"}
            icon={"exclamation-triangle-fill"}
            minValue={0}
            label={"Leads not Summarized"}
            filters={filters}
        />
    )
}

interface Props {
    readonly group_id: string | null
}

export default NotSummarizedMetric