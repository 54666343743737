import {useContext, useEffect, useState} from "react";
import {ICountApiService, IListApiService, ListPaginateOptions, Model} from "lib-shared";
import {useApiCount, useApiList} from "./useApiList";
import {UserContext} from "../context/UserContext";
import {getPageNums} from "../utils/Utils";
import {OrderByState} from "../components/tables/BaseTable";
import {ApiOrderBy} from "../components/tables/ApiTable";

function usePaginatedCollection<T extends Model>(props: Props): PaginationResult<T> {

    const {clientContext} = useContext(UserContext);


    const pageSize = props.defaultPageSize ?? 25

    const [pageNum, setPageNum] = useState(0)

    const opts: ListPaginateOptions = {
        ...props.listOptions,
        orderBy: props.orderBy?.orderBy.value.name ?? "id",
        orderByDirection: props.orderBy?.dir ?? "asc",
        pageSize, pageNum
    }

    useEffect(() => {
        // we want to reset the pagination if we change the query
        setPageNum(0)
    }, [pageSize, opts.filterFields?.length, opts.orderBy, opts.orderByDirection]);


    const list = useApiList<T>(clientContext, props.client, opts, {refreshIntervalMs: props.refreshIntervalMs}, props.admin)
    const count = useApiCount(clientContext, props.client, opts, {refreshIntervalMs: props.refreshIntervalMs})

    if (list.error) {
        console.error(list.error)
    }

    const {
        startNum,
        endNum,
        totalCount,
        hasPrevPage,
        hasNextPage,
    } = getPageNums(pageNum, pageSize, count.value ?? 0)


    const data: T[] = list.value ?? []

    function next() {
        if (!hasNextPage) {
            console.log("No next page")
            return
        }

        if (!list.value) {
            return
        }

        setPageNum(pageNum + 1)
    }

    function prev() {
        if (!hasPrevPage) {
            console.log("No prev page")
            return
        }

        setPageNum(pageNum - 1)
    }

    const loading = list.isLoading

    return {
        data,
        pageSize,
        loading,
        startNum,
        endNum,
        totalCount,
        hasPrevPage,
        hasNextPage,
        next,
        prev,
        reload: list.rerun
    }
}

interface Props {
    readonly listOptions: ListPaginateOptions
    readonly admin?: boolean
    readonly client: IListApiService<any, any> & ICountApiService<any, any>
    readonly orderBy?: OrderByState<ApiOrderBy>
    readonly refreshIntervalMs?: number
    readonly defaultPageSize?: number
}

export interface PaginationResult<T> {
    readonly data: T[]
    readonly pageSize: number
    readonly loading: boolean
    readonly startNum: number
    readonly endNum: number
    readonly totalCount: number
    readonly hasPrevPage: boolean
    readonly hasNextPage: boolean
    readonly next: () => void
    readonly prev: () => void
    readonly reload: () => void
}

export default usePaginatedCollection