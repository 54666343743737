import {Navigate} from "react-router-dom";
import {fixHref} from "../utils/Utils";

function AppNavigate(props: Props) {
    return <Navigate to={fixHref(props.to)}/>
}

interface Props {
    readonly to: string
}

export default AppNavigate