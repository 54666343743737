import {IOrganizationsService, ModelSchema, Organization, OrganizationSchema,} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext} from "../FetchUtils";

export class OrganizationsClient extends BaseCrudClient<Organization> implements IOrganizationsService<ClientContext> {
    public static readonly defaultClient: OrganizationsClient = new OrganizationsClient()
    get schema(): ModelSchema<Organization> {
        return OrganizationSchema;
    }
}
