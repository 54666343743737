import {
    CharacterRequirement,
    CharacterSet, CharacterSetType,
    FieldClass,
    FieldSourceType,
    FieldType, IModelField,
    MinLength, ModelSchema,
    Required
} from "lib-shared";

export interface EmailAndPasswordCredentials {
    readonly email: string
    readonly password: string
}

export const PasswordSignInField: FieldClass<string, any> = new FieldClass<string, any>({
    field_source: FieldSourceType.USER_DEFINED,
    field_type: FieldType.TEXT,
    display_name: "Password", name: "password", rules: [
        new Required<any>(),
        CharacterRequirement.lowercaseCharacters,
        CharacterRequirement.uppercaseCharacters,
        CharacterRequirement.numericCharacters,
        CharacterRequirement.symbolCharacters,
        new MinLength<any>(6),
    ]
})

export const EmailSignInField = new FieldClass<string, any>({
    display_name: "Email", name: "email",
    field_source: FieldSourceType.USER_DEFINED,
    field_type: FieldType.TEXT,
    rules: [
        new Required<EmailAndPasswordCredentials>(),
        new CharacterSet(CharacterSetType.Email)
    ]
})

export class CredentialsSchemaDef extends ModelSchema<EmailAndPasswordCredentials> {
    get apiResourceName(): string {
        throw new Error('Method not implemented.');
    }

    get KeyColumns(): IModelField<any, EmailAndPasswordCredentials>[] {
        throw new Error('Method not implemented.');
    }

    getFriendlyName(value: EmailAndPasswordCredentials): string {
        throw new Error('Method not implemented.');
    }

    get SchemaName(): string {
        return "Credentials"
    }

    public readonly Email = EmailSignInField
    public readonly Password = PasswordSignInField
}

export const credentialsSchema: CredentialsSchemaDef = new CredentialsSchemaDef()
