import {IArchivable, OrgModel} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema,} from "../validation/Validation";
import {MinLength, Required} from "../validation/Validators";
import {makeIsArchivedField} from "../validation/ValidationUtils";

export interface SegmentationClass extends OrgModel, IArchivable {
    // also name of the field
    readonly class_id: string;
    readonly name: string;
    readonly description: string;
}

class SegmentationClassSchemaDef extends OrgModelSchema<SegmentationClass> {
    public get KeyColumns(): IModelField<any, SegmentationClass>[] {
        return [this.OrgId, this.ClassId]
    }

    getFriendlyName(value: SegmentationClass): string {
        return value.name
    }

    get apiResourceName(): string {
        return "segmentationClass";
    }

    get SchemaName(): string {
        return "SegmentationClass";
    }

    public readonly IsArchived = makeIsArchivedField()

    public readonly ClassId = new FieldClass<string, SegmentationClass>({
        field_type: FieldType.TEXT,
        display_name: "Class ID",
        name: "class_id",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [
            new Required<SegmentationClass>(),
        ],
    });


    public readonly Name = new FieldClass<string, SegmentationClass>({
        field_type: FieldType.TEXT,
        display_name: "Name",
        name: "name",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [
            new Required<SegmentationClass>(),
            new MinLength<SegmentationClass>(1),
        ],
    });

    public readonly Description = new FieldClass<string, SegmentationClass>({
        field_type: FieldType.TEXT,
        display_name: "Description",
        name: "description",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [
            new Required<SegmentationClass>(),
            new MinLength<SegmentationClass>(12),
        ],
    });
}

export const SegmentationClassSchema: SegmentationClassSchemaDef =
    new SegmentationClassSchemaDef();