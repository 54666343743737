import {ReactNode, useState} from "react";
import Toast from "react-bootstrap/Toast";
import {Variant} from "react-bootstrap/types";
import MaterialIcon from "../../components/common/MaterialIcon";

function MyToast(props: Props) {
    const {toast} = props
    const [show, setShow] = useState(true)
    return (
        <Toast onClose={() => setShow(false)} show={show} delay={toast.ttl ?? 3000} bg={toast.variant}
               autohide>
            <Toast.Header>
                <MaterialIcon icon={toast.icon ?? "info"} size={18} margin={2}/>
                <strong className="me-auto">{toast.title}</strong>
                {toast.small && <small className="text-muted">{toast.small}</small>}
            </Toast.Header>
            <Toast.Body>{toast.body}</Toast.Body>
        </Toast>
    );
}

export interface Props {
    readonly toast: ToastRequest
}

export interface ToastRequest {
    readonly title: string
    readonly body: string | ReactNode
    readonly variant?: Variant
    readonly ttl?: number
    readonly icon?: string
    readonly small?: string
}

export default MyToast