import {dateToNiceTimeAgo, numberToShortNum} from "../utils/Utils";
import {isDate} from "lib-shared";

function SimpleTextStat(props: Props) {


    if (typeof props.value === "string") {
        return (
            <div><b>{props.value}</b> <span className={"text-muted"}> {props.label}</span></div>
        )
    } else if (typeof props.value === "number") {
        return (
            <div><b title={props.value + ""}>{numberToShortNum(props.value)}</b> <span
                className={"text-muted"}> {props.label}</span></div>
        )
    } else if (isDate(props.value)) {
        return (
            <div><b title={props.value.toISOString()}>{dateToNiceTimeAgo(props.value)}</b> <span
                className={"text-muted"}> {props.label}</span></div>
        )
    } else {
        return <></>
    }

}

interface Props {
    value: number | string | Date
    label: string
}

export default SimpleTextStat