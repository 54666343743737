import * as React from "react";
import {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import Button, {ButtonProps as ButtonProps} from "../common/Button";
import {FormFieldComponent} from "./FormFields";
import FormLabel from "./FormLabel";

function FormText<T>(props: Props<T>) {

    const [value, setValue] = useState<string>(props.initialValue)

    useEffect(() => {
        setValue(props.initialValue)
    }, [props.initialValue]);

    const textarea = props.stringType == "textarea" || (props.stringType == undefined && props.rows)

    // default value might change because of some wire op
    useEffect(() => {
        if (props.defaultValue) {
            setValue(props.defaultValue)
        }
    }, [props.defaultValue]);



    return (
        <Form.Group className="mb-3" controlId={props.field.name}>
            <FormLabel field={props.field}
                       value={value}
                       labelSubText={props.labelSubText}
                       errors={props.errors}
                       tip={props.tip}/>
            <div className="input-group">
                {!textarea &&
                    <>
                        <input
                            id={props.field.name}
                            type={props.stringType}
                            placeholder={props.placeholder}
                            value={value ?? ""}
                            required={props.required ?? false}
                            autoFocus={props.index == 1}
                            tabIndex={props.index}
                            disabled={props.disabled}
                            autoComplete={"off"}
                            onChange={(e) => setValue(e.target.value)}
                            className="form-control"
                        />
                        {props.clearable &&
                            <button type="button" className="btn bg-transparent" style={{marginLeft: -40, zIndex: 100}}
                                    tabIndex={-1}
                                    onClick={() => setValue("")}>
                                <i className="bi bi-x-lg"/>
                            </button>
                        }
                        {props.buttonProps &&
                            <div className={"input-group-append ms-2"}><Button {...props.buttonProps}/></div>}
                    </>
                }
                {textarea &&
                    <textarea
                        id={props.field.name}
                        rows={props.rows}
                        placeholder={props.placeholder}
                        required={props.required ?? false}
                        autoFocus={props.index == 1}
                        tabIndex={props.index}
                        value={value ?? ""}
                        disabled={props.disabled}
                        onChange={(e) => setValue(e.target.value)}
                        className="form-control"
                    />
                }
            </div>
        </Form.Group>

    )
}

export interface ITextFieldProps {
    readonly placeholder?: string
    readonly rows?: number
    readonly clearable?: boolean
    readonly stringType?: "text" | "textarea" | "password" | "email" | "number"
}

interface Props<T> extends FormFieldComponent<string, T>, ITextFieldProps {
    readonly defaultValue: string | undefined
    readonly buttonProps?: ButtonProps
}

export default FormText