import {BaseCrudClient} from "./BaseCrudClient";
import {FirstPartyField, FirstPartyFieldSchema, IFirstPartyFieldsService, ModelSchema} from "lib-shared";
import {ClientContext} from "../FetchUtils";

export class FirstPartyFieldsClient extends BaseCrudClient<FirstPartyField> implements IFirstPartyFieldsService<ClientContext> {
    public static readonly defaultClient: FirstPartyFieldsClient = new FirstPartyFieldsClient()

    public get schema(): ModelSchema<FirstPartyField> {
        return FirstPartyFieldSchema
    }
}