import * as React from "react";
import {PaginationResult} from "../../hooks/usePaginatedCollection";
import {ReactNode} from "react";


function PaginationButtons(props: PaginationButtonsProps) {
    const {
        pageSize,
        startNum,
        endNum,
        totalCount,
        hasPrevPage,
        hasNextPage,
        next,
        prev,
    } = props.paginationResult

    const pageCount = Math.ceil(totalCount / pageSize)

    return (
        <div className={"d-flex mt-3 mb-3"}>
            <div className="me-auto p-2"><span
                className="text-muted text-sm text-nowrap">{`Showing ${startNum}-${endNum} of ${totalCount} items`}</span>
            </div>
            <div>
                <ul className="pagination pagination-spaced gap-1">
                    <button className="page-link" onClick={() => prev()} type={"button"}
                            disabled={!hasPrevPage}><i className="bi bi-chevron-left"></i></button>
                    <button className="page-link" onClick={() => next()} type={"button"}
                            disabled={!hasNextPage}><i className="bi bi-chevron-right"></i></button>
                </ul>
            </div>
        </div>
    )
}

export interface PaginationButtonsProps {
    readonly buttons?: ReactNode[]
    readonly disablePageSize?: boolean
    readonly paginationResult: PaginationResult<any>
}

export default PaginationButtons