import "./WizardProgress.css"
import {ReactNode, useState} from "react";
import Button from "./Button";
import {Col} from "react-bootstrap";

function WizardProgress(props: Props) {
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false)

    function skip(i: number) {
        if (i > activeStep)
            return
        setActiveStep(i)
    }

    async function finish(): Promise<void> {
        setLoading(true)
        try {
            await props.onSubmit()
        } finally {
            setLoading(false)
        }
    }

    const isLastSection = activeStep == props.sections.length - 1
    return (
        <div>
            <div className="stepper-wrapper">
                {props.sections.map((section, i) => {
                    return (
                        <div key={section.name} className={`stepper-item ${i <= activeStep ? "completed" : ""}`}>
                            <div className={`step-counter ${i < activeStep ? "clickable" : ""}`}
                                 onClick={() => skip(i)}>{i + 1}</div>
                            <div className={`step-name ${i == activeStep ? "fw-semibold" : ""}`}>{section.name}</div>
                        </div>
                    )
                })}

            </div>
            <div className={"row mb-5 mt-5"}>
                {}
                {props.sections[activeStep].element}
            </div>
            <div className={"row"}>
                <Col>
                    <Button disabled={activeStep <= 0} variant={"outline-primary"} label={"Back"}
                            className={"w-100"}
                            onClick={() => setActiveStep(activeStep - 1)}/>
                </Col>
                <Col>
                    {isLastSection ?
                        (<Button variant={"primary"}
                                 className={"w-100"}
                                 label={"Finish"}
                                 onClick={finish}/>) :
                        (<Button variant={"primary"} className={"w-100"} label={"Next"}
                                 onClick={() => setActiveStep(activeStep + 1)}/>)
                    }
                </Col>
            </div>
        </div>
    )
}

export interface WizardSection {
    readonly name: string,
    readonly element: ReactNode
}

interface Props {
    readonly sections: WizardSection[]
    readonly onSubmit: () => Promise<boolean>

}

export default WizardProgress