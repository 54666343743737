import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as React from "react";
import {useContext, useState} from "react";
import IconSelectItem from "../../components/IconSelectItem";
import {EmailProvidersClient} from "lib-client";
import {EmailProvider, EmailProviderType} from "lib-shared";
import {UserContext} from "../../context/UserContext";
import {OrgContext} from "../../context/OrgContext";
import {v4 as uuidv4} from "uuid"
import AppModal from "../../components/AppModal";
import {toastIfError} from "../../context/toasts/ToastManager";

function EmailProviderEditorModal(props: Props) {
    const {appUser, clientContext} = useContext(UserContext);
    const {org, loading: orgLoading} = useContext(OrgContext);

    const [selected, setSelected] = useState<EmailProviderType>(EmailProviderType.Google)
    const {show, close} = props

    async function create() {
        const emailProvider: EmailProvider = {
            org_id: org!.org_id,
            email_provider_id: uuidv4(),
            email: null,
            email_provider_type: selected,
            verification_code: null,
            access_token: null,
            expires_at: null,
            issue_message: null,
            refresh_token: null,
        }
        const response = await new EmailProvidersClient().create(emailProvider, clientContext)

        props.callback(emailProvider)
        toastIfError(clientContext, response)

        if (props.followAppRedirect) {
            if (response.redirectUriForOAuth) {
                window.location.href = response.redirectUriForOAuth
            } else {
                console.error("Failed to create provider")
            }
        }
        close()
    }

    return (

        <AppModal show={show} onHide={close} name={"email provider editor"}>
            <Modal.Header closeButton>
                <Modal.Title>New Email Provider</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="align-items-center">
                    <label className="form-label mb-0">Select Email Provider</label>
                    <div className="d-flex gap-3 scrollable-x">
                        <IconSelectItem id={"google"} selectedId={selected} label={"Google"} icon={"google"}
                                        callback={(str) => setSelected(str as EmailProviderType)}/>
                        <IconSelectItem id={"microsoft"} selectedId={selected} label={"Microsoft"}
                                        icon={"microsoft"} callback={(str) => setSelected(str as EmailProviderType)}/>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={close}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={create}>
                    Create
                </Button>
            </Modal.Footer>
        </AppModal>
    )
}

interface Props {
    readonly followAppRedirect: boolean
    readonly callback: (emailProvider: EmailProvider) => void
    readonly show: boolean
    readonly close: () => void
}

export default EmailProviderEditorModal