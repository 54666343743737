import {Lead} from "lib-shared";
import Modal from "react-bootstrap/Modal";
import LeadEditor from "./add/LeadEditor";
import {useContext} from "react";
import {UserContext} from "../../context/UserContext";
import AppModal from "../../components/AppModal";
import FirstPartyFieldsEditor from "./FirstPartyFieldsEditor";
import LeadGroupsEditor from "./add/LeadGroupsEditor";
import {useApiGet} from "../../hooks/useApiGet";
import AppTabs, {TabSpec} from "../../components/common/AppTabs";
import {LeadsClient} from "lib-client";

function EditLeadModal(props: Props) {
    const {clientContext} = useContext(UserContext);

    const lead = useApiGet<Lead>(clientContext, LeadsClient.defaultClient, props.leadId)

    const isCreate = props.leadId == ""

    const tabs: TabSpec[] = [
        {
            name: "Edit",
            element: <LeadEditor lead={props.leadId!}
                                 isCreate={props.leadId == ""}
                                 closeCallback={isCreate ? props.close : undefined}
            />
        },
        {
            name: "Groups",
            enabled: !isCreate,
            element: <LeadGroupsEditor
                leadId={props.leadId!}
            />
        },
        {
            name: "First Party",
            enabled: !isCreate,
            element: <FirstPartyFieldsEditor leadId={props.leadId!}
            />
        },

    ]


    // new leads don't have a summary yet
    if (!isCreate) {
        tabs.push({
            name: "Summary",
            element: (
                <div>
                    <h6 className={"mb-4 mt-2"}>Here's what we know:</h6>
                    <p>{lead.value?.text_summary ?? "No summary yet. Please check back soon."}</p>
                </div>
            )
        })
    }

    return (
        <AppModal show={props.leadId != null} onHide={props.close} name={"lead editor"}>
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? "Create" : "Edit"} Lead</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppTabs tabs={tabs}/>
            </Modal.Body>
        </AppModal>
    )
}

interface Props {
    readonly leadId: string | null
    // null when closing
    readonly close: () => void
}

export default EditLeadModal