import Modal from "react-bootstrap/Modal";
import * as React from "react";
import {useContext} from "react";
import {InvitationsClient} from "lib-client";
import {Invitation, InvitationSchema, isSuccess} from "lib-shared";
import {UserContext} from "../../context/UserContext";
import {eventBus} from "../../utils/EventBus";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {v4 as uuidv4} from "uuid"
import AppModal from "../../components/AppModal";
import {toastIfError} from "../../context/toasts/ToastManager";

function CreateInvitationModal(props: Props) {
    const {fbUser, clientContext, appUser} = useContext(UserContext);
    const {show, setShow} = props

    async function submit(payload: FormSubmitPayload<Invitation>): Promise<boolean> {
        const invitation: Invitation = {
            ...payload.value,
            org_id: clientContext.org_id!,
            invitation_id: uuidv4(),
        }
        const response = await new InvitationsClient().create(invitation, clientContext)

        if (!isSuccess(response)) {
            toastIfError(clientContext, response)
            return false
        }

        eventBus.trigger("item-changed", invitation)
        setShow(false)
        return true
    }

    function cancel() {
        setShow(false)
    }

    return (
        <AppModal show={show} onHide={cancel} name={"invite creator"}>
            <Modal.Header closeButton>
                <Modal.Title>New Invitation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className={"text-muted text-sm mb-5"}>Enter the email address of the person you want to invite to
                    your
                    org. This will not email the person. You must share the invite link with them separately once you
                    create the invite.</p>
                <AppForm onSubmit={submit}
                         submitLabel={"Invite"}
                         schema={InvitationSchema} fields={[
                    [
                        {
                            fieldType: "text",
                            field: InvitationSchema.Email
                        }
                    ]
                ]}/>
            </Modal.Body>
        </AppModal>
    )
}

interface Props {
    show: boolean
    setShow: (show: boolean) => void
}

export default CreateInvitationModal