import {ReactElement, ReactNode, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";

function AppTabs(props: Props): ReactElement {
    const enabledTabs = props.tabs.filter(t => (t.enabled ?? true))

    if (enabledTabs.length == 1) {
        return (
            <>{enabledTabs[0].element}</>
        )
    }

    const [tab, setTab] = useState<string>(enabledTabs[0].name)

    return (
        <>
            <Tabs
                defaultActiveKey={tab}
                className="mb-3"
                onSelect={(x) => {
                    if (x)
                        setTab(x)
                }}
                fill
            >
                {enabledTabs.map(x => {
                    return <Tab key={x.name} eventKey={x.name} title={x.name}/>
                })}
            </Tabs>
            {
                enabledTabs.map(t => {
                    const isActive = t.name == tab
                    return (
                        <div className={isActive ? "d-block" : "d-none"} key={t.name}>
                            {t.element}
                        </div>
                    )
                })
            }
        </>
    )
}

export interface TabSpec {
    name: string,
    enabled?: boolean,
    element: ReactNode
}

interface Props {
    tabs: TabSpec[]
}

export default AppTabs