import * as React from "react";
import AdminOrg from "./AdminOrg";
import AdminDrafts from "./AdminDrafts";
import AppTabs, {TabSpec} from "../../components/common/AppTabs";


function AdminPage(props: Props) {
    const tabs: TabSpec[] = [
        {
            name: "Org",
            element: <AdminOrg/>
        },
        {
            name: "Drafts",
            element: <AdminDrafts/>
        }
    ]

    return (
        <div>
            <AppTabs tabs={tabs}/>
        </div>
    )
}

interface Props {

}


export default AdminPage