import {useContext, useState} from "react";
import {Modal} from "react-bootstrap";
import AppModal from "../../../components/AppModal";
import WizardProgress from "../../../components/common/WizardProgress";
import WizardSectionGroup from "./WizardSectionGroup";
import {BulkUploadLead, Campaign, LeadGroup, LeadGroupMembership} from "lib-shared";
import WizardSectionCampaign from "./WizardSectionCampaign";
import {CampaignsClient, LeadGroupMembershipsClient, LeadsClient} from "lib-client";
import {UserContext} from "../../../context/UserContext";
import {useAppNav} from "../../../hooks/useAppNav";
import WizardSectionSummary from "./WizardSectionSummary";
import {AppProgressContext, runTasks, SubTask} from "../../../components/progressbar/AppProgressContext";
import ProgressWrapper from "../../../components/progressbar/ProgressWrapper";
import {StagedBulkUploadLead} from "./BulkAddLeadsPage";

function BulkUploadModal(props: Props) {
    const {clientContext} = useContext(UserContext)
    const nav = useAppNav()

    const [group, setGroup] = useState<LeadGroup | null>(null)
    const [campaign, setCampaign] = useState<Campaign | null>(null)
    const progressProps = useContext(AppProgressContext)

    function hide() {
        props.onHide()
    }

    async function finish(): Promise<boolean> {

        const tasks: SubTask[] = []

        tasks.push({
            message: "Uploading leads...",
            cost: props.bulkUploadLeads.length,
            op: async () => {
                const client = new LeadsClient({
                    addProgress: progressProps.addProgress
                })
                return await client.bulkAddLeadsManaged(props.bulkUploadLeads.map<BulkUploadLead>(l => {
                    return {
                        lead: l.lead,
                        fields: l.fields
                            // don't send empty fields over the wire
                            .filter(f => f.field_value.trim() != "")
                            .map(field => {
                                return {
                                    ...field,
                                    lead_id: l.lead.lead_id,
                                }
                            })
                    }
                }), clientContext)
            }
        })

        if (group) {
            tasks.push({
                message: "Adding leads to group...",
                cost: props.bulkUploadLeads.length,
                op: async () => {
                    const memberships: LeadGroupMembership[] = props.bulkUploadLeads.map(l => {
                        return {
                            lead_group_id: group.lead_group_id, lead_id: l.lead.lead_id, org_id: clientContext.org_id!
                        }
                    })
                    const client = new LeadGroupMembershipsClient({
                        addProgress: progressProps.addProgress
                    })
                    return await client.bulkAddManaged(memberships, clientContext)
                }
            })
        }

        if (campaign) {
            tasks.push({
                message: "Adding leads to campaign...",
                cost: 1,
                op: async () => {
                    return await new CampaignsClient({
                        addProgress: progressProps.addProgress
                    }).addLeads({
                        campaign_id: campaign.campaign_id,
                        type: "lead_ids",
                        // todo batch
                        values: props.bulkUploadLeads.map(l => l.lead.lead_id)
                    }, clientContext)
                }
            })
        }

        const result = await runTasks(tasks, progressProps, clientContext)

        if (!result) {
            return false
        }

        console.log("Upload complete!")
        nav("/leads")
        return true
    }

    return (
        <AppModal show={props.show} onHide={hide} name={"bulk upload leads"}>
            <Modal.Header closeButton>
                <Modal.Title>Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"pt-0"}>
                <ProgressWrapper>
                    <WizardProgress
                        onSubmit={finish}
                        sections={[
                            {
                                name: "Groups",
                                element: <WizardSectionGroup key={"groups-section"}
                                                             group={group}
                                                             setGroup={setGroup}/>
                            },
                            {
                                name: "Campaign",
                                element: <WizardSectionCampaign key={"campaign-section"} campaign={campaign}
                                                                setCampaign={setCampaign}/>
                            },
                            {
                                name: "Review",
                                element: <WizardSectionSummary campaign={campaign} group={group}
                                                               leads={props.bulkUploadLeads}/>
                            },
                        ]}/>
                </ProgressWrapper>
            </Modal.Body>
        </AppModal>
    )
}

interface Props {
    show: boolean
    onHide: () => void
    bulkUploadLeads: StagedBulkUploadLead[]
}

export default BulkUploadModal