import {createContext, ReactNode, useState} from "react";
import {AppClientContext, delay} from "../../utils/Utils";
import {ApiResponse, isSuccess} from "lib-shared";
import {toastIfError} from "../../context/toasts/ToastManager";
import {ClientContext} from "lib-client";


export type ProgressUpdateFn = (value: ProgressValue | null) => void

export interface AppProgressProps {
    readonly current: ProgressTask | null
    readonly progress: number
    readonly isActive: boolean
    readonly addProgress: (delta: number) => void
    readonly start: (task: ProgressTask) => void
    readonly updateMessage: (message: string) => void
    readonly finish: () => void
}

export interface ProgressTask {
    readonly message: string
    readonly total: number
}

export interface ProgressValue {
    readonly message: string
    readonly total: number
    readonly value: number
}

export const AppProgressContext = createContext<AppProgressProps>({
    current: null,
    isActive: false,
    progress: 0,
    start: (task: ProgressTask) => console.error("Progress not ready"),
    finish: () => console.error("Progress not ready"),
    addProgress: (value: number) => {
        console.error("Progress not ready");
    },
    updateMessage: function (message: string): void {
        console.error("Progress not ready");
    },
});

export const AppProgressProvider = (props: Props) => {

    const [current, setCurrent] = useState<ProgressTask | null>(null)
    const [progress, setProgress] = useState<number>(0)

    function finish() {
        setCurrent(null)
    }

    function updateMessage(message: string) {
        if (!current)
            return

        setCurrent({
            ...current,
            message: message
        })
    }

    function addProgress(delta: number) {
        setProgress(progress + delta)
    }

    function start(task: ProgressTask) {
        if (current != null) {
            console.warn(`A task is already running: ${JSON.stringify(current)}`)
        }

        console.info(`Starting task: ${JSON.stringify(task)}`)

        setCurrent(task)
        setProgress(0)
    }

    const value: AppProgressProps = {
        current,
        progress,
        isActive: current != null,
        start,
        finish,
        addProgress,
        updateMessage,
    }

    return <AppProgressContext.Provider value={value}>{props.children}</AppProgressContext.Provider>
}

export interface SubTask {
    readonly message: string
    readonly cost: number
    readonly op: () => Promise<boolean | ApiResponse<any>>
}

export async function runTasks(tasks: SubTask[], progressProps: AppProgressProps, clientContext: AppClientContext): Promise<boolean> {
    if (tasks.length == 0) {
        console.warn("Skipping run because no tasks were given.")
        return true
    }

    const total = tasks.map(t => t.cost).reduce((acc, curr) => acc + curr, 0)
    progressProps.start({
        message: tasks[0].message,
        total: total
    })

    try {
        for (let task of tasks) {
            console.log(`Starting task: ${task.message}`)
            progressProps.updateMessage(task.message)
            const result = await task.op()

            if ((typeof result == "boolean")) {
                if (!result) {
                    console.error(`Task didn't complete successfully. Subsequent tasks will not run.`)
                    progressProps.finish()
                    return false
                }
            } else {
                if (!isSuccess(result)) {
                    toastIfError(clientContext, result)
                    console.error(`API Task didn't complete successfully. Subsequent tasks will not run.`)
                    progressProps.finish()
                    return false
                }
            }
        }

        // pause for dramatic effect
        await delay(1000)
        progressProps.finish()
        return true
    } catch (e) {
        progressProps.finish()
        throw e
    }
}

interface Props {
    children?: ReactNode
}