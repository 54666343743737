import {ApiResponse, GenericItemRequest, ILeadGroupsService, LeadGroup, LeadGroupSchema, ModelSchema} from "lib-shared";

import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext, sendApi} from "../FetchUtils";

export class LeadGroupsClient extends BaseCrudClient<LeadGroup> implements ILeadGroupsService<ClientContext> {

    public static readonly defaultClient: LeadGroupsClient = new LeadGroupsClient()
    get schema(): ModelSchema<LeadGroup> {
        return LeadGroupSchema;
    }

    summarizeLeads(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return sendApi(`/${this.resourceName}/${req.id}/summarize`, "POST", null, ctx)
    }
}