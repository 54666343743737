import PowerPageContent from "../../components/powerPage/PowerPageContent";
import IconButton from "../../components/common/IconButton";
import {Invitation, InvitationSchema} from "lib-shared";
import * as React from "react";
import {useContext, useState} from "react";
import {ColumnSpec} from "../../components/tables/BaseTable";
import ApiTable, {ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import CreateInvitationModal from "./CreateInvitationModal";
import CopyLink from "../../utils/CopyLink";
import InfoTip from "../../components/common/InfoTip";
import {JoinOrgPath} from "../setup/AcceptInvitationPage";
import {ConfirmModalContext} from "../../context/confirmModal/ConfirmModalContext";
import {TableAction} from "../../components/tables/TableActions";
import {InvitationsClient} from "lib-client";

function InvitationsPage() {
    const {confirm} = useContext(ConfirmModalContext);

    const [show, setShow] = useState(false);

    function invite() {
        setShow(true)
    }

    const columns: ColumnSpec<Invitation, ApiOrderBy>[] = [
        createColumn(InvitationSchema.Email, true),
        {
            name: "Invite Code",
            defaultColumn: true,
            cellExtractor: value => {
                return {
                    value: value.invitation_id
                }
            },
        },
        {
            name: "",
            defaultColumn: true,
            cellExtractor: invitation => {
                return {
                    value: invitation.invitation_id,
                    displayValue: <CopyLink value={makeInvitationLink(invitation)} label={"Copy Link"}/>,
                }
            },
        },
        createColumn(InvitationSchema.CreatedAt, true),
    ]

    function makeInvitationLink(invitation: Invitation): string {
        const url = new URL(window.location.href)
        url.pathname = JoinOrgPath
        url.searchParams.set(InvitationSchema.InvitationId.name, invitation.invitation_id)
        return url.toString()
    }

    function actionCreator(invitations: Invitation[]): TableAction[] {
        return [
            {
                icon: "trash",
                label: "Delete",
                color: "danger",
                supportsMultiRows: true,
                action: () => {
                    confirm({
                        action_type: "delete",
                        client: InvitationsClient.defaultClient,
                        resources: invitations.map(i => {
                            return {id: i.invitation_id, name: i.email}
                        })
                    })
                }
            }
        ]
    }

    return (
        <PowerPageContent title={"Invitations"}
                          description={
                              <>
                                  Invite new people to your organization.
                                  <InfoTip>
                                      <>
                                          <p>Invite new people to your organization. Members of your organization
                                              have full permissions to the account.</p>
                                          <ol>
                                              <li>Create a new Invitation.</li>
                                              <li>Copy the Invitation link</li>
                                              <li>Have the recipient open the link</li>
                                          </ol>
                                      </>
                                  </InfoTip>
                              </>
                          }
                          buttons={[
                              <IconButton key={"invitation"} action={invite} icon={"plus-circle"} label={"Invite"}/>,

                          ]}>
            <ApiTable<Invitation> client={InvitationsClient.defaultClient}
                                  noDataMessage={"No Invitations."}
                                  tableSettings={{
                                      idExtractor: invitation => invitation.invitation_id,
                                      filterOptions: [],
                                      columns: columns,
                                      actionCreator
                                  }}
            />
            <CreateInvitationModal show={show} setShow={setShow}/>
        </PowerPageContent>
    )
}

export default InvitationsPage