import {FieldValidationResult, IModelField} from "lib-shared";
import {ReactNode} from "react";
import InfoTip from "../common/InfoTip";

function FormLabel(props: Props) {

    return (
        <>
            <label className="form-label"
                   htmlFor={props.field.name}>{props.field.display_name}{props.field.isRequired ?
                <span className={"text-danger"}> *</span> : ""}{props.tip &&
                <InfoTip>{props.tip}</InfoTip>}</label>
            {props.labelSubText && <div className={"mb-3"}>{props.labelSubText(props.value)}</div>}
            {props.errors.map(e => {
                return <div key={e.error} className={"text-danger"}>{e.error}</div>
            })}
        </>

    )
}

interface Props {
    readonly field: IModelField<any, any>
    readonly value: any
    readonly labelSubText?: (value: any) => ReactNode
    readonly tip?: string
    readonly errors: FieldValidationResult[]
}

export default FormLabel