import {Model} from "../models/Model";

export interface ListPaginateOptionsWithId extends ListPaginateOptions {
    readonly id: string
}

export enum UserContextAttribute {
    ORG_ID,
    USER_ID,
    NONE,
}

export interface ApiOptions {
}

export interface ListPaginateOptions extends ApiOptions {
    // get all joined fields/objects too
    readonly fullyHydrated?: boolean
    // zero indexed
    readonly pageNum?: number
    // < 100
    readonly pageSize?: number
    readonly filterFields?: FilterField[]
    readonly orderBy?: string
    readonly orderByDirection?: OrderByDirection
}

export interface CountItemsOptions extends ApiOptions {
    readonly hydrate?: boolean
    readonly filterFields?: FilterField[]
}

export function isSuccess(res: ApiResponse<any>): boolean {
    return getErrorOrNull(res) == null
}

export function getResultOrNull<T>(res: ApiResponse<T>): T | null {
    if (typeof res.value === "object" && "code" in res.value! && "message" in res.value) {
        return null
    }
    return res.value
}

export function getErrorOrNull(res: ApiResponse<any>): ApiError | null {
    if (typeof res.value === "object" && "code" in res.value && "message" in res.value) {
        return res.value
    }
    return null
}

export interface ApiError {
    readonly message: string
    readonly code: ApiErrorCode
}

export interface ApiResponse<T> {
    readonly value: T | ApiError
    readonly redirectUriForOAuth?: string
}

export enum ApiErrorCode {
    UNKNOWN_ERROR,
    // auth was provided, but it wasn't valid or expired
    UNAUTHENTICATED,
    // auth was provided, but you're not allowed to access the resource
    PERMISSION_DENIED,
    RECORD_ALREADY_EXISTS,
    RECORD_NOT_FOUND,
    // the request was invalid in some way
    FAILED_PRECONDITION,
    // request can't be completed, might be transient
    UNAVAILABLE,
    INVALID_ARGUMENT,
    // unimplemented or not supported
    UNIMPLEMENTED,
    // user's quota is exhausted
    RESOURCE_EXHAUSTED,
}

export function apiErrorCodeToString(error: ApiErrorCode): string {
    switch (error) {
        case ApiErrorCode.RESOURCE_EXHAUSTED:
            return "RESOURCE_EXHAUSTED"
        case ApiErrorCode.FAILED_PRECONDITION:
            return "FAILED_PRECONDITION"
        case ApiErrorCode.UNKNOWN_ERROR:
            return "UNKNOWN_ERROR"
        case ApiErrorCode.UNAUTHENTICATED:
            return "UNAUTHENTICATED"
        case ApiErrorCode.PERMISSION_DENIED:
            return "PERMISSION_DENIED"
        case ApiErrorCode.RECORD_ALREADY_EXISTS:
            return "RECORD_ALREADY_EXISTS"
        case ApiErrorCode.RECORD_NOT_FOUND:
            return "RECORD_NOT_FOUND"
        case ApiErrorCode.UNAVAILABLE:
            return "UNAVAILABLE"
        case ApiErrorCode.INVALID_ARGUMENT:
            return "INVALID_ARGUMENT"
        case ApiErrorCode.UNIMPLEMENTED:
            return "UNIMPLEMENTED"
    }
}

export const RequestIdHeaderName = "zs-request-id"

export interface GenericItemRequest extends ApiOptions {
    readonly id: string
    readonly fullyHydrated?: boolean
}

export declare type OrderByDirection = 'desc' | 'asc';

export enum FilterOperator {
    EQUALS = "eq",
    NOT_EQUALS = "neq",
    NOT_NULL = "nn",
    IS_NULL = "nl",
}

export interface FilterField {
    field: string
    operator?: FilterOperator
    value: (string | number | boolean)[]
}

export function encodeFilterFields(url: URLSearchParams, filters: FilterField[]): void {
    url.delete("filter")
    for (let filter of filters) {
        const encoded = encodeFilterField(filter)
        url.append("filter", encoded)
    }
}

export function encodeFilterField(filter: FilterField): string {
    let value: string = filter.value.join("&")
    return `${filter.field}.${filter.operator ?? FilterOperator.EQUALS}.${value}`
}

export function decodeFilterField(param: string): FilterField {
    const splits = param.split(".")

    if (splits.length < 3) {
        throw new Error("Invalid filter format")
    }

    const field = splits[0]

    const op = splits[1] as FilterOperator

    const value = splits.slice(2).join(".").split("&")

    const filter: FilterField = {
        field: field,
        operator: op,
        value: value.map(decodeFilterValues)
    }
    return filter
}

function decodeFilterValues(str: string): string | number | boolean {
    if (str == "true")
        return true
    if (str == "false")
        return false
    return str
}

export const FilterFieldSeparator: string = "."

export interface BulkMutateItemsRequest<T extends Model> {
    readonly values: T[]
}

export type EnvType = "local" | "dev" | "prod"
