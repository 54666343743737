import {ApiResponse, GenericItemRequest, Invitation, InvitationSchema, ModelSchema, OrgMembership} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {IInvitationService} from "lib-shared/dist/services/IInvitationService";
import {ClientContext, sendApi} from "../FetchUtils";

export class InvitationsClient extends BaseCrudClient<Invitation> implements IInvitationService<ClientContext> {
    public static readonly defaultClient: InvitationsClient = new InvitationsClient()
    public get schema(): ModelSchema<Invitation> {
        return InvitationSchema
    }

    async acceptInvitation(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<OrgMembership>> {
        const result = await sendApi<void, OrgMembership>(`/${this.resourceName}/acceptInvitation/${req.id}`, "POST", undefined, ctx);
        return result
    }
}