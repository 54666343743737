import {useContext, useEffect, useState} from "react";
import {CampaignsClient} from "lib-client";
import {UserContext} from "../../context/UserContext";
import {CampaignAndDraftCount, getResultOrNull} from "lib-shared";
import {fixHref} from "../../utils/Utils";
import {getCampaignIdOutboxLink} from "../../routes/outbox/Outbox";
import MaterialIcon from "../common/MaterialIcon";
import ResultBlock from "../ResultBlock";
import {Link} from "react-router-dom";

function ActiveCampaignsWidget(props: Props) {
    const {clientContext} = useContext(UserContext);

    const [counts, setCounts] = useState<CampaignAndDraftCount[]>([])
    const [loading, setLoading] = useState(true)

    async function getCampaignCounts() {
        setLoading(true)
        const apiResponse = await CampaignsClient.defaultClient.getCampaignDraftCounts(undefined, clientContext)
        setCounts(getResultOrNull(apiResponse) ?? [])
        setLoading(false)
    }

    useEffect(() => {
        if (clientContext.user)
            getCampaignCounts()
    }, [clientContext]);

    return (
        <div className="card">
            <div className="card-body p-7">
                <h5 className="h3 font-display fw-bold mb-3">Review Drafts</h5>
                <div className="list-group list-group-flush">
                    <ResultBlock isLoading={loading} error={null}>
                        {counts.length == 0 &&
                            <div><MaterialIcon icon={"checked"} color={"success"}/>Nothing to review!</div>}
                        {counts.map(c => {
                            return (
                                <Link key={c.campaign_id} to={fixHref(getCampaignIdOutboxLink(c.campaign_id))}
                                      className={"mb-2"}>
                                    <div className="d-flex bg-body-secondary gap-3 rounded-3 p-4">
                                        <div className="vstack gap-2">
                                            <div className="d-flex mb-1">
                                                <div className=""><span
                                                    className="d-block text-heading text-sm fw-semibold">{c.name}</span>
                                                    <span className="d-block text-muted text-xs">{c.count} drafts need your review</span>
                                                </div>
                                                <div
                                                    className="ms-auto d-block fw-bold fs-2">
                                                    {c.count}<MaterialIcon icon={"envelope-exclamation"}
                                                                           marginStart={2}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                    </ResultBlock>
                </div>
            </div>
        </div>
    )
}

interface Props {

}

export default ActiveCampaignsWidget