import {User} from "firebase/auth";
import * as App from "lib-shared";
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {auth, getEnvType} from "../utils/Firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {ToastContext} from "./toasts/ToastManager";
import {ClientContext, UsersClient} from "lib-client";
import {useApiGet} from "../hooks/useApiGet";
import {AppClientContext} from "../utils/Utils";
import {makeOrgId, Organization_Id} from "lib-shared";
import {ToastRequest} from "./toasts/AppToast";

interface Props {
    children?: ReactNode
}

export interface AuthProps {
    readonly fbUser?: User
    readonly loadingFbUser: boolean
    readonly loadingAppUser: boolean
    readonly clientContext: AppClientContext
    readonly setAppUser: (appUser: App.User) => void
    // null if not setup
    readonly appUser: App.User | null
}

export const UserContext = createContext<AuthProps>({
    loadingFbUser: true,
    loadingAppUser: true,
    appUser: null,
    setAppUser: (u: App.User) => {
        console.log("setAppUser not ready")
    },
    clientContext: {
        env: "prod",
        loadingFirebase: true,
        org_id: null,
// @ts-ignore
        user: null,
        toastProps: {
            toast: (t: ToastRequest) => console.log("Toast not ready")
        }
    }
});


export const AuthProvider = ({children}: Props) => {

    const env = getEnvType()

    switch (env) {
        case "local":
            document.title = "ZENSEND / local"
            break;
        case "prod":
            break;
        case "dev":
            document.title = "ZENSEND / dev"
            break;
    }

    const [user, loadingAuth, error] = useAuthState(auth);
    const toastProps = useContext(ToastContext)
    const [appUser, setAppUser] = useState<App.User | null>(null)
    const [isLoadingAppUser, setIsLoadingAppUser] = useState(true)
    const org_id = new URLSearchParams(window.location.search).get(Organization_Id)

    if (error) {
        console.log(`AuthProvider: ${error.name} ${error.message}`)
    }

    const clientContext: AppClientContext = {
        user: user!,
        org_id: makeOrgId(org_id ?? ""),
        env: getEnvType(),
        loadingFirebase: loadingAuth,
        toastProps: toastProps
    }

    const appUserResponse = useApiGet<App.User>(clientContext, UsersClient.defaultClient, "me")

    useEffect(() => {
        if (!appUserResponse.isLoading) {
            setAppUser(appUserResponse.value)
            setIsLoadingAppUser(false)
        }
    }, [appUserResponse.isLoading]);

    function doSetAppUser(user: App.User): void {
        if (!user) {
            console.error("App.User cannot be null")
            return
        }
        setAppUser(user)
    }

    const value: AuthProps = {
        fbUser: user!,
        appUser: appUser,
        setAppUser: doSetAppUser,
        loadingFbUser: loadingAuth,
        loadingAppUser: isLoadingAppUser,
        clientContext,
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}