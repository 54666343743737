import * as React from "react";
import {useContext} from "react";
import {
    FieldClass,
    FieldSourceType,
    FieldType,
    getResultOrNull,
    IModelField,
    InvitationSchema,
    MaxLength,
    MinLength,
    ModelSchema,
    Required
} from "lib-shared";
import {InvitationsClient} from "lib-client";
import {UserContext} from "../../context/UserContext";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {useQueryState} from "../../hooks/useQueryParam";
import {toastIfError} from "../../context/toasts/ToastManager";
import {hardNavToOrg} from "../../utils/Utils";
import {Row} from "react-bootstrap";
import InfoBlock from "../../components/common/InfoBlock";

function AcceptInvitationPage() {
    const {clientContext} = useContext(UserContext);
    const [urlInviteCode, setUrlInviteCode] = useQueryState(InvitationSchema.InvitationId.name);

    async function acceptInvite(payload: FormSubmitPayload<InviteCodeRequest>): Promise<boolean> {
        const response = await new InvitationsClient().acceptInvitation({id: payload.value.invite_code}, clientContext)

        const result = getResultOrNull(response)
        if (!result) {
            toastIfError(clientContext, response)
            return false
        }

        hardNavToOrg(result)
        return true
    }

    return (
        <div className="mx-auto">
            <Row>
                <h3 className={"text-center"}>Join an Existing Org</h3>
                <p className={"text-muted text-sm text-center m-2"}>If someone from your company has already created an Organization you can join it with an invite code.</p>
            </Row>
            <AppForm
                onSubmit={acceptInvite}
                schema={InviteCodeRequestSchema}
                submitLabel={"Join"}
                fields={[
                    {
                        fieldType: "text",
                        defaultValue: urlInviteCode ?? undefined,
                        placeholder: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
                        field: InviteCodeRequestSchema.InviteCode
                    }
                ]}
            />
        </div>
    )
}

interface InviteCodeRequest {
    readonly invite_code: string
}

export class InviteCodeRequestSchemaDef extends ModelSchema<InviteCodeRequest> {
    getFriendlyName(value: InviteCodeRequest): string {
        return value.invite_code
    }

    get apiResourceName(): string {
        throw new Error("Method not implemented.");
    }

    get KeyColumns(): IModelField<any, InviteCodeRequest>[] {
        throw new Error("Method not implemented.");
    }

    get SchemaName(): string {
        return "Invite Code Request"
    }

    public readonly InviteCode = new FieldClass<string, InviteCodeRequest>({
        field_type: FieldType.TEXT,
        field_source: FieldSourceType.USER_DEFINED,
        display_name: "Invite Code", name: "invite_code", rules: [
            new Required<InviteCodeRequest>(),
            new MinLength(36),
            new MaxLength(36),
        ]
    })
}

export const InviteCodeRequestSchema: InviteCodeRequestSchemaDef = new InviteCodeRequestSchemaDef()

export const JoinOrgPath = "/join-org"

export default AcceptInvitationPage