import * as React from "react";
import {useContext, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { OrganizationSchema, OrgMembership, OrgMembershipSchema} from "lib-shared";
import {ColumnSpec} from "../tables/BaseTable";
import ApiTable, {ApiOrderBy, createColumn} from "../tables/ApiTable";
import {OrgContext} from "../../context/OrgContext";
import AppModal from "../AppModal";
import CreateOrgModal from "../../routes/setup/CreateOrgModal";
import {MyOrgMembershipsListClient} from "../../clientwrappers/MyOrgMembershipsListClient";
import {dateToNiceTimeAgo, hardNavToOrg} from "../../utils/Utils";

function OrgPicker(props: Props) {
    const {org: currentOrg} = useContext(OrgContext)

    const [showCreateOrg, setShowCreateOrg] = useState(false)

    const columns: ColumnSpec<OrgMembership, ApiOrderBy>[] = [
        {
            name: "Name",
            defaultColumn: true,
            orderBy: {
                value: OrganizationSchema.Name,
            },
            cellExtractor: value => {
                return {
                    value: value.org!.name,
                    config: {
                        weight: "fw-semibold"
                    }
                }
            }
        },
        createColumn(OrgMembershipSchema.LastUsedAt, true, {
            isDefaultOrderBy: true,
            defaultOrderByDir: "desc",
            valueFormatter: (v: string, model: OrgMembership) => {
                return dateToNiceTimeAgo(new Date(v))
            }
        }),
        {
            name: "",
            defaultColumn: true,
            cellExtractor: value => {
                const isActive = value.org_id == currentOrg?.org_id
                return {
                    value: "",
                    displayValue: <Button size={"sm"} disabled={isActive} onClick={() => hardNavToOrg(value)}>Select</Button>
                }
            }
        }
    ]

    return (
        <>
            <AppModal show={props.show} onHide={() => props.setShow(false)} name={"org-picker"}>
                <Modal.Header closeButton>
                    <Modal.Title>Select an Organization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ApiTable<OrgMembership> client={MyOrgMembershipsListClient.defaultClient}
                                             noDataMessage={"No Orgs yet."}
                                             listOptions={{
                                                 fullyHydrated: true,
                                             }}
                                             tableSettings={{
                                                 idExtractor: org => org.org_id,
                                                 filterOptions: [],
                                                 small: true,
                                                 columns
                                             }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => setShowCreateOrg(true)}>
                        Create Org
                    </Button>
                    <Button variant="light" onClick={() => props.setShow(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </AppModal>
            <CreateOrgModal show={showCreateOrg} onHide={() => setShowCreateOrg(false)}/>
        </>
    )
}

interface Props {
    readonly show: boolean
    readonly setShow: (show: boolean) => void
}

export default OrgPicker