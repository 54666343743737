import {useContext} from "react";
import {ToastContext} from "../context/toasts/ToastManager";
import MaterialIcon from "../components/common/MaterialIcon";
import Button from "react-bootstrap/Button";

function CopyLink(props: Props) {
    const toastProps = useContext(ToastContext)

    async function updateClipboard() {
        // Copy the text inside the text field
        await navigator.clipboard.writeText(props.value);
        toastProps.toast({
            title: "Copied!",
            icon: "clipboard-check",
            body: "Copied value"
        })
    }

    return (
        <Button onClick={updateClipboard} size={"sm"} variant={"light"}>
            <MaterialIcon icon={"clipboard"} size={14} margin={2}/>{props.label}
        </Button>

    )
}

interface Props {
    readonly label: string
    readonly value: string
}

export default CopyLink