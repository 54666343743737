import {
    ApiResponse,
    ITikTokPostsService, LoadSocialMediaPostsRequest,
    ModelSchema,
    SocialMediaPostKey, SocialMediaProfileKey,
    TikTokPost,
    TikTokPostSchema
} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext, sendApi} from "../FetchUtils";


export class TikTokPostsClient extends BaseCrudClient<TikTokPost> implements ITikTokPostsService<ClientContext> {

    public static readonly defaultClient: TikTokPostsClient = new TikTokPostsClient()

    get schema(): ModelSchema<TikTokPost> {
        return TikTokPostSchema;
    }

    async enrichPost(req: SocialMediaPostKey, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendApi<SocialMediaPostKey, void>(`/${this.resourceName}/enrichPost`, "POST", req, ctx);
    }

    async addPost(req: SocialMediaPostKey, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendApi<SocialMediaPostKey, void>(`/${this.resourceName}/addPost`, "POST", req, ctx);
    }

    async loadMorePosts(req: LoadSocialMediaPostsRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendApi<LoadSocialMediaPostsRequest, void>(`/${this.resourceName}/loadMorePosts`, "POST", req, ctx)
    }

    refresh(profile: SocialMediaPostKey, ctx: ClientContext): Promise<ApiResponse<void>> {
        return sendApi<SocialMediaPostKey, void>(`/${this.resourceName}/refresh`, "POST", profile, ctx)
    }
}