import {ColumnSpec, FilterOption} from "../../components/tables/BaseTable";
import {EmailProviderSchema, SenderProfile, SenderProfileSchema} from "lib-shared";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import IconButton from "../../components/common/IconButton";
import * as React from "react";
import SenderProfileEditor, {SenderProfileEditorKey} from "../../components/emailProviders/SenderProfileEditor";
import {emailProviderTypeWithIcon} from "../../utils/ReactUtils";
import {useQueryState} from "../../hooks/useQueryParam";
import ApiTable, {ApiFilter, ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import {TableAction} from "../../components/tables/TableActions";
import {SenderProfilesClient} from "lib-client";

function SenderProfilesPage(props: Props) {

    const [editId, setEditId] = useQueryState(SenderProfileEditorKey);

    function addProfile() {
        setEditId("")
    }


    const columns: ColumnSpec<SenderProfile, ApiOrderBy>[] = [
        createColumn(SenderProfileSchema.LastName, true),
        createColumn(SenderProfileSchema.FirstName, true),
        createColumn(SenderProfileSchema.Email, true),
        createColumn(SenderProfileSchema.EmailProviderType, true, {
            displayValueFormatter: (value, senderProfile) => emailProviderTypeWithIcon(senderProfile.email_provider_type),
        }),
        createColumn(SenderProfileSchema.UpdatedAt, true),
        createColumn(SenderProfileSchema.CreatedAt, true),
    ]

    const filters: FilterOption<ApiFilter>[] = [
        {
            name: "Provider Type",
            filters: [
                {
                    displayName: "Provider Type",
                    field: EmailProviderSchema.Type.name,
                    value: ["google"],
                    displayValue: "Google"
                },
                {
                    displayName: "Provider Type",
                    field: EmailProviderSchema.Type.name,
                    value: ["microsoft"],
                    displayValue: "Microsoft"
                },
                {
                    displayName: "Provider Type",
                    field: EmailProviderSchema.Type.name,
                    value: ["manual"],
                    displayValue: "Manual CSV"
                },

            ]
        },
    ]

    function actionCreator(senderProfiles: SenderProfile[]): TableAction[] {
        return [
            {
                icon: "pencil",
                label: "Edit",
                action: () => setEditId(senderProfiles[0].sender_profile_id)
            }
        ]
    }

    return (
        <PowerPageContent title={"Sender Profiles"}
                          description={"Sender profiles are used configure who the generated email is from and how the email will be sent."}
                          buttons={[
                              <IconButton key={"add-profile"} action={addProfile} icon={"plus-circle"}
                                          label={"New Profile"}/>,
                          ]}>
            <ApiTable<SenderProfile> client={SenderProfilesClient.defaultClient}
                                     noDataMessage={"No Sender Profiles yet."}
                                     tableSettings={{
                                         columns,
                                         idExtractor: sp => sp.sender_profile_id,
                                         filterOptions: filters,
                                         rowClickCallback: (profile: SenderProfile) => setEditId(profile.sender_profile_id),
                                         actionCreator
                                     }}
            />

            <SenderProfileEditor show={editId != null} close={() => setEditId(null)} editId={editId!}/>
        </PowerPageContent>
    )
}

interface Props {

}

export default SenderProfilesPage