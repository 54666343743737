import {ReactNode} from "react";
import * as React from "react";
import {Dropdown} from "react-bootstrap";
import MaterialIcon from "../../components/common/MaterialIcon";
import {ColorType, colorTypeToClass} from "../../utils/Utils";

// parent must have position-relative class
function OptionsDropdown(props: Props) {

    return (

        <div className="btn-group">
            <button type="button" className={`btn btn-neutral text-xs ${props.className} rounded-1`}
                    data-bs-auto-close="true" aria-expanded="false"
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    data-bs-toggle="dropdown">
                {props.buttonContent}
            </button>
            <ul className="dropdown-menu">
                {props.actions.filter(a => a.enabled ?? true).map(a => {
                    return (
                        <li key={a.label}>
                            <a key={a.label}
                               className="dropdown-item"
                               href={"#"}
                               onClick={(e) => {
                                   e.stopPropagation()
                                   a.action()
                               }}>
                                {a.icon && <MaterialIcon icon={a.icon} size={18} color={a.color} margin={2}/>}<span
                                className={colorTypeToClass(a.color)}>{a.label}</span>{a.subText && <p
                                className={"text-muted"}>{a.subText}</p>}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

interface Props {
    readonly id: string
    readonly disabled?: boolean
    readonly buttonContent: ReactNode | string
    readonly className?: string
    readonly actions: LabelAndAction[]
}

export interface LabelAndAction {
    readonly icon?: string
    readonly color?: ColorType
    readonly label: string
    readonly enabled?: boolean
    readonly subText?: string
    readonly action: () => void
}

export default OptionsDropdown

interface ToggleProps {
    readonly children: ReactNode
    readonly onClick: (e: React.MouseEvent) => void
}


