import * as React from "react";
import {useContext, useState} from "react";
import EmailExporterFieldItem from "./EmailExporterFieldItem";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    Campaign, CampaignSchema,
    Draft,
    DraftSchema,
    DraftState,
    EmailSection,
    FilterField,
    getDraftSectionOrDefault, getErrorOrNull, getResultOrNull
} from "lib-shared";
import Form from "react-bootstrap/Form";
import * as Papa from "papaparse";
import {UserContext} from "../../../context/UserContext";
import {ToastContext} from "../../../context/toasts/ToastManager";
import {DraftsClient} from "lib-client";
import AppModal from "../../AppModal";
import {AppProgressContext, runTasks, SubTask} from "../../progressbar/AppProgressContext";
import ProgressWrapper from "../../progressbar/ProgressWrapper";

function EmailExporter(props: Props) {
    const {clientContext} = useContext(UserContext);

    const [selectedState, setSelectedState] = useState<DraftState>("ready")

    const [fields, setFields] = useState<ExporterField[]>(defaultFields)
    const {toast} = useContext(ToastContext);

    const progressProps = useContext(AppProgressContext)

    function editField(field: ExporterField) {
        const i = fields.findIndex(f => f.name == field.name)
        const arr = [...fields]
        arr[i] = field
        setFields(arr)
    }

    function moveOrder(field: ExporterField, delta: number) {
        const i = fields.findIndex(f => f.name == field.name)
        const arr = [...fields]
        const targetIndex = Math.max(0, Math.min(fields.length - 1, i + delta))

        // nothing to do
        if (i == targetIndex)
            return

        const swp = arr[targetIndex]
        arr[targetIndex] = fields[i]
        arr[i] = swp
        setFields(arr)
    }

    function getValue(draft: Draft, fieldType: FieldId): string {
        switch (fieldType) {
            case FieldId.First_Name:
                return draft.lead?.first_name ?? ""
            case FieldId.Last_Name:
                return draft.lead?.last_name ?? ""
            case FieldId.Email:
                return draft.lead?.email ?? ""
            case FieldId.ZS_Subject_Line:
                return getDraftSectionOrDefault(draft, props.campaign, EmailSection.Subject)
            case FieldId.ZS_Email_Body:
                return getDraftSectionOrDefault(draft, props.campaign, EmailSection.Body)
            case FieldId.ZS_Email:
                const greeting = getDraftSectionOrDefault(draft, props.campaign, EmailSection.Subject)
                const body = getDraftSectionOrDefault(draft, props.campaign, EmailSection.Subject)
                const closing = getDraftSectionOrDefault(draft, props.campaign, EmailSection.Subject)
                return `${greeting}\n\n${body}\n\n${closing}`
            case FieldId.ZS_Email_Greeting:
                return getDraftSectionOrDefault(draft, props.campaign, EmailSection.Greeting)
            case FieldId.ZS_Email_Closing:
                return getDraftSectionOrDefault(draft, props.campaign, EmailSection.Closing)
            case FieldId.ZS_TEXT_SUMMARY:
                return draft.lead?.text_summary ?? ""
            default:
                throw Error("Unknown field id")
        }
    }

    function makeFile(emails: Draft[]) {

        if (emails.length == 0) {
            toast({
                title: "No Emails Found!",
                icon: "warning",
                body: "No emails found to export."
            })
            return
        }

        const columnNames: string[] = fields.filter(f => f.enabled).map(f => f.name)
        const values: string[][] = [columnNames, ...emails.map(email => {
            return fields.filter(f => f.enabled).map(f => getValue(email, f.name))
        })]

        const csvString = Papa.unparse(values, {
            quotes: false, //or array of booleans
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ",",
            newline: "\r\n",
        })

        const url = window.URL.createObjectURL(
            new Blob([csvString]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `zensend-export.csv`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
    }

    async function exportEmails() {

        const tasks: SubTask[] = []

        const draftsClient = new DraftsClient({addProgress: progressProps.addProgress})

        const filters: FilterField[] = [
            {field: DraftSchema.State.name, value: [selectedState]},
            {field: CampaignSchema.CampaignId.name, value: [props.campaign.campaign_id]}
        ]

        const response = await draftsClient.count({filterFields: filters}, clientContext)

        const count = getResultOrNull(response)

        tasks.push({
            message: "Exporting...",
            cost: count ?? 100,
            op: async () => {
                const emailsResponse = await draftsClient.listAll({
                        fullyHydrated: true,
                        filterFields: filters
                    },
                    clientContext)

                const result = getResultOrNull(emailsResponse)
                if (!result) {
                    const error = getErrorOrNull(emailsResponse)
                    console.error("Export failed. error occurred:" + error)
                    return false
                }
                makeFile(result)
                return true
            }
        })

        const result = runTasks(tasks, progressProps, clientContext)

        if (!result) {
            return false
        }

        props.setShow(false)
    }

    const isValidConfig = fields.some(f => f.enabled)

    const validEmailStates: DraftState[] = [
        "ready",
        "sending",
        "sent",
        "skipped"
    ]

    return (
        <AppModal show={props.show} onHide={() => props.setShow(false)} size={"xl"} name={"email-exporter"}>
            <Modal.Header closeButton className={"bg-light"}>
                <Modal.Title>Download CSV</Modal.Title>
            </Modal.Header>
            <ProgressWrapper>
                <>
                    <Modal.Body className={"bg-light"}>
                        <p>Download all emails as a CSV for sending via another tool. You can customize field mappings
                            and
                            which
                            are included below.</p>
                        <Form>
                            <div className={"row"}>
                                <Form.Group className="col-6" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email Category</Form.Label>
                                    <Form.Select aria-label="Email Category"
                                                 onChange={(e) => setSelectedState(e.target.value as DraftState)}
                                                 value={selectedState}>
                                        {validEmailStates.map(state => {
                                            return <option key={`state-${state}`} value={state}>{state}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="col-6" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Last Changed</Form.Label>
                                    <Form.Select aria-label="Email Category"
                                                 onChange={(e) => {
                                                 }}
                                                 value={selectedState}>
                                        <option value={"all_time"}>All Time</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>

                            <div className={"overflow-y-scroll"} style={{maxHeight: "500px"}}>
                                {fields.map((f, i) => <EmailExporterFieldItem key={f.name} index={i} field={f}
                                                                              editCallback={editField}
                                                                              moveOrderCallback={moveOrder}/>)}
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className={"bg-light"}>
                        <Button onClick={() => props.setShow(false)} variant={"secondary"}>Cancel</Button>
                        <Button onClick={exportEmails} disabled={!isValidConfig}>Export</Button>
                    </Modal.Footer>
                </>
            </ProgressWrapper>
        </AppModal>
    )
}

interface Props {
    campaign: Campaign
    setShow: (show: boolean) => void
    show: boolean
}

export enum FieldId {
    First_Name = "First Name",
    Last_Name = "Last Name",
    Email = "Email",
    ZS_Subject_Line = "ZS: Subject Line",
    ZS_Email = "ZS: Email",
    ZS_Email_Body = "ZS: Email Body",
    ZS_Email_Greeting = "ZS: Email Greeting",
    ZS_Email_Closing = "ZS: Email Closing",
    ZS_TEXT_SUMMARY = "ZS: Text Summary",
}

const defaultFields: ExporterField[] = Object.values(FieldId).map((id, index) => {
    return {
        name: id,
        outputName: id,
        enabled: true
    }
})

export interface ExporterField {
    readonly name: FieldId
    readonly outputName: string
    readonly enabled: boolean
}

export default EmailExporter
