import * as React from "react";

function IconSelectItem(props: Props) {

    function click(){
        props.callback(props.id)
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        console.log(`OnChange: ${e}`)
    }

    return (
        <div className="form-item-checkable" onClick={click}>
            <input className="form-item-check" type="radio" name={props.id} id={props.id}
                   onChange={onChange}
                   checked={props.selectedId == props.id}/>
            <label className="form-item cursor-pointer" htmlFor="asset-type-crypto">
                <span className="form-item-click d-inline-flex flex-column gap-3 align-items-center
                     justify-content-center form-control w-rem-24 h-rem-24 text-center text-muted">
                                <i className={`bi bi-${props.icon} text-xl`}></i>
                                <span className="fw-semibold text-xs">{props.label}</span>
                </span>
            </label>
        </div>
    )
}

interface Props {
    readonly id: string
    readonly selectedId: string
    readonly label: string
    readonly icon: string
    readonly callback: (id: string) => void
}

export default IconSelectItem