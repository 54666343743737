import {ReactNode} from "react";
import {Modal} from "react-bootstrap";
import Button from "../common/Button";
import {FilterField, ICountApiService, IListApiService, Model} from "lib-shared";
import {ColumnSpec, RowData} from "../tables/BaseTable";
import AppModal from "../AppModal";
import ApiTable, {ApiOrderBy} from "../tables/ApiTable";
import {ClientContext} from "lib-client";
import {IReadonlyClient} from "lib-client/dist/clients/BaseReadOnlyClient";

function ListPickerModal<T extends Model>(props: Props<T>) {

    function close() {
        if (isRequired)
            return

        props.callback(null)
    }

    const columns: ColumnSpec<T, ApiOrderBy>[] = [
        {
            name: "Name",
            defaultColumn: true,
            orderBy: props.orderBy,
            cellExtractor: value => {
                return {
                    value: props.mapper(value),
                    config: {
                        weight: "fw-semibold"
                    }
                }
            }
        }
    ]

    function onClick(value: T): void {
        props.callback(value)
    }

    const isRequired = props.isRequired ?? false

    return (
        <AppModal show={props.show} onHide={close} name={`list picker: ${props.title}`}>
            <Modal.Header closeButton={!isRequired}>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className={"text-muted text-sm mb-5"}>{props.description}</p>
                <ApiTable<T> client={props.client}
                             listOptions={{
                                 fullyHydrated: true
                             }}

                             noDataMessage={"Nothing to select."}
                             tableSettings={{
                                 columns,
                                 idExtractor: props.getId,
                                 filterOptions: [],
                                 rowClickCallback: onClick
                             }}
                />
            </Modal.Body>
            <Modal.Footer>
                {props.extraButtons}
                {!isRequired && <Button variant="neutral" onClick={close} label={"Cancel"}/>}
            </Modal.Footer>
        </AppModal>
    )
}

interface Props<T extends Model> {
    show: boolean
    title: string
    description: string
    orderBy?: ApiOrderBy,
    isRequired?: boolean
    client: IReadonlyClient<T>
    filters: FilterField[]
    extraButtons?: ReactNode
    // todo use schema principle id fn
    getId: (value: T) => string
    // todo use schema friendly name fn
    mapper: (value: T) => string
    callback: (value: T | null) => void
}

export default ListPickerModal