import * as React from "react";
import {RowData, TableSettings} from "./BaseTable";
import OptionsDropdown from "../common/OptionsDropdown";
import {ellipsis} from "../../utils/Utils";
import TableActions from "./TableActions";
import InfoTip from "../common/InfoTip";
import {ReactNode} from "react";

function TableRow<TValue>(props: Props<TValue>) {

    const actions = props.tableSettings.actionCreator != undefined ? props.tableSettings.actionCreator([props.data.value]) : []

    function onClick(e: React.MouseEvent) {
        if (props.tableSettings.rowClickCallback) {
            props.tableSettings.rowClickCallback(props.data.value)
        }
    }

    function onCheckClicked(e: React.MouseEvent<HTMLInputElement>) {
        e.stopPropagation();
        props.toggleChecked(props.data.id)
    }


    return (
        <tr className={`${props.tableSettings.rowClickCallback != undefined ? "clickable" : ""}`}
            onClick={(e) => onClick(e)}>
            {props.showCheckbox &&
                <td><input type={"checkbox"} checked={props.isChecked} onClick={onCheckClicked}
                           onChange={() => {
                           }}
                /></td>
            }
            {props.data.cells.map((cell, index) => {
                let value: string | ReactNode = cell.displayValue ?? cell.value

                if (typeof value === "string" && value.length > 50) {
                    value = <span>{ellipsis(value, 50)}<InfoTip>{value}</InfoTip></span>
                }

                return <td key={index} className={`${cell.config?.weight} ${cell.config?.color}`}>{value}</td>
            })}
            {
                props.tableSettings.actionCreator &&
                <td className={"text-end"}>
                    <TableActions actions={actions}/>
                </td>
            }
        </tr>
    )
}

interface Props<TValue> {
    readonly data: RowData<TValue>
    readonly showCheckbox: boolean
    readonly rowNum: number
    readonly isChecked: boolean
    readonly toggleChecked: (id: string) => void
    readonly tableSettings: TableSettings<TValue, any, any>
}

export default TableRow