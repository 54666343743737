import {OrgModel} from "../models/Model";
import {Required} from "./Validators";
import {User} from "../models/User";

export function validate<T>(model: T, form: IModelSchema<T>): ModelValidationResult {
    const errors = form.getFields()
        .filter(field => field.field_source == FieldSourceType.USER_DEFINED)
        .flatMap<FieldValidationResult>(f => {
            const errors = f.rules
                .map(r => r.check(f, model))
                .filter(r => r != null)
                .map(r => r as FieldValidationResult)
            return errors;
        })

    return {
        isValid: errors.length == 0,
        errors: errors,
    }
}


export interface IModelSchema<TModel> {
    get SchemaName(): string

    getFields(): IModelField<any, TModel>[]
}


export abstract class BaseModelSchema<TModel> implements IModelSchema<TModel> {

    public abstract get apiResourceName(): string

    public abstract get SchemaName(): string

    public abstract get KeyColumns(): IModelField<any, TModel>[]

    public abstract getFriendlyName(value: TModel): string

    public get PrincipalColumn(): IModelField<any, TModel> {
        const keyCols = this.KeyColumns
        return keyCols[keyCols.length - 1]
    }

    public extractId(value: TModel): string {
        const keyCols = this.KeyColumns
        if (keyCols.length == 1) {
            // @ts-ignore
            return value[keyCols[0].name]
        }

        let result = ""
        for (let keyCol of keyCols) {
            // @ts-ignore
            result = result + "#" + value[keyCol.name]
        }
        return result
    }

    public getPrincipalId(value: TModel): string {
        // @ts-ignore
        return value[this.PrincipalColumn.name]
    }

    // todo optimize
    public containsField(name: string): boolean {
        return this.getFields().find(f => f.name == name) != undefined
    }

    public getFields(): IModelField<any, TModel>[] {
        const result: IModelField<any, TModel>[] = []
        for (let key of Object.keys(this)) {
            // @ts-ignore
            const field = this[key]

            // check if is Field
            if (typeof field?.display_name === 'string') {
                result.push(field as IModelField<any, TModel>)
            }
        }
        return result
    }
}

export abstract class ModelSchema<TModel> extends BaseModelSchema<TModel> {


    public readonly CreatedAt = new FieldClass<number, TModel>({
        display_name: "Created At",
        name: "created_at",
        field_type: FieldType.DATE_TIME,
        field_source: FieldSourceType.SYSTEM_DEFINED
    })

    public readonly UpdatedAt = new FieldClass<number, TModel>({
        display_name: "Updated At",
        name: "updated_at",
        field_type: FieldType.DATE_TIME,
        field_source: FieldSourceType.SYSTEM_DEFINED
    })
}

export class DynamicSchema<TModel> implements IModelSchema<TModel> {

    private readonly name: string
    private readonly fields: IModelField<any, TModel>[]

    constructor(name: string, fields: IModelField<any, TModel>[]) {
        this.name = name
        this.fields = fields
    }

    getFields(): IModelField<any, TModel>[] {
        return this.fields
    }

    get SchemaName(): string {
        return this.name
    }

}

export abstract class OrgModelSchema<TModel extends OrgModel> extends ModelSchema<TModel> {

    public readonly OrgId = FieldClass.createIdField<TModel>("org_id")


}

export class FieldClass<TValue, TModel> implements IModelField<TValue, TModel> {
    public readonly props: FieldProps<TValue, TModel>

    public static createIdField<T>(name: string): FieldClass<string, T> {
        return new FieldClass<string, T>({
            field_type: FieldType.TEXT,
            display_name: name, name: name, field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
        })
    }

    public static createSecretField<T>(name: string): IModelField<string, T> {
        return new FieldClass<string, T>({
            field_type: FieldType.TEXT,
            display_name: name, name: name, field_source: FieldSourceType.SYSTEM_SECRET, rules: []
        })
    }

    constructor(props: FieldProps<TValue, TModel>) {
        this.props = props
    }

    public get field_source(): FieldSourceType {
        return this.props.field_source
    }

    public get field_type(): FieldType {
        return this.props.field_type
    }

    public get display_name(): string {
        return this.props.display_name
    }

    get name(): string {
        return this.props.name
    }

    public get isRequired(): boolean {
        return this.rules.some(r => r.is_required)
    }

    public get defaultValue(): TValue | undefined {
        return this.props.defaultValue
    }

    public get rules(): ModelRule<TValue, TModel>[] {
        return this.props.rules ?? []
    }
}

export interface FieldProps<TValue, TModel> {
    readonly display_name: string
    readonly name: string
    readonly field_source: FieldSourceType
    readonly field_type: FieldType
    readonly defaultValue?: TValue
    readonly rules?: ModelRule<TValue, TModel>[]
}

export enum FieldType {
    TEXT,
    TOGGLE,
    SELECT,
    MULTI_SELECT,
    DATE_TIME,
}

export enum FieldSourceType {
    USER_DEFINED,
    SYSTEM_DEFINED,
    // we redact this value when it leaves the API
    SYSTEM_SECRET,
}

export interface IModelField<TValue, TModel> {
    readonly name: string
    readonly display_name: string
    readonly field_type: FieldType
    readonly field_source: FieldSourceType
    readonly isRequired: boolean
    readonly defaultValue?: TValue
    readonly rules: ModelRule<TValue, TModel>[]
}

export interface FieldValidationResult {
    readonly field: string
    readonly error: string
}

export interface ModelValidationResult {
    readonly isValid: boolean
    readonly errors: FieldValidationResult[]
}

export abstract class ModelRule<TValue, TModel> {
    public readonly name: string
    public readonly is_required: boolean;

    protected constructor(name: string, is_required: boolean = false) {
        this.name = name;
        this.is_required = is_required;
    }

    private extract(field: IModelField<TValue, TModel>, model: TModel): TValue | undefined {
        // @ts-ignore
        const value = model[field.name]
        return value
    }


    public check(field: IModelField<TValue, TModel>, model: TModel): FieldValidationResult | null {
        const value = this.extract(field, model)

        const error = this.doCheck(field, model, value)

        if (!error)
            return null

        return {
            field: field.name,
            error
        }
    }

    protected abstract doCheck(field: IModelField<TValue, TModel>, model: TModel, value: TValue | undefined): string | null
}


