import LoadingIndicator from "../LoadingIndicator";

function LoadingFullPage() {

    return (
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
            <img
                src="/logo.png"
                className="logo-dark h-rem-8 h-rem-md-10"
                alt="..."
            />
            <LoadingIndicator/>
        </div>
    )
}

export default LoadingFullPage