import {ReactNode} from "react"
import MaterialIcon from "./MaterialIcon";

function InfoBlock(props: Props) {


    let className = "alert-info"
    let icon = "info-circle-fill"
    switch (props.type) {
        case "info":
            className = "alert-info"
            icon = "info-circle-fill"
            break;
        case "warning":
            className = "alert-warning"
            icon = "exclamation-diamond-fill"
            break;
    }

    return (
        <div className={`alert ${className} mb-3`} role="alert">
            <div className={"d-flex"}>
                <div className={"flex-column me-4"}><MaterialIcon icon={icon} size={18}/></div>
                <div className={"flex-column flex-fill"}>{props.children}</div>
            </div>
        </div>
    )
}

interface Props {
    readonly type: "info" | "warning"
    readonly children: ReactNode
}

export default InfoBlock