import MaterialIcon from "./MaterialIcon";

function ProfileAvatarIcon(props: Props) {
    const {src} = props

    const size = `${props.size ?? 100}px`
    return (
        <img className={props.style + " " + props.className ?? ""} src={src ?? "/person.png"} alt={"avatar for use"}
             style={{maxWidth: size, maxHeight: size}}/>
    )
}

interface Props {
    readonly src?: string
    readonly size?: number
    readonly style: "rounded-circle" | "rounded"
    readonly className?: string
}

export default ProfileAvatarIcon