import {
    AddLeadsRequest,
    ApiResponse,
    Campaign,
    CampaignAndDraftCount,
    CampaignSchema,
    GenericItemRequest,
    ICampaignsService,
    ModelSchema,
} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext, fetchApi, sendApi} from "../FetchUtils";

export class CampaignsClient extends BaseCrudClient<Campaign> implements ICampaignsService<ClientContext> {

    public static readonly defaultClient: CampaignsClient = new CampaignsClient()
    public get schema(): ModelSchema<Campaign> {
        return CampaignSchema
    }


    async getCampaignDraftCounts(ignored: void, ctx: ClientContext): Promise<ApiResponse<CampaignAndDraftCount[]>> {
        const result = await fetchApi<CampaignAndDraftCount[]>(`/${this.resourceName}/draftCounts`, ctx)
        return result
    }

    async addLeads(req: AddLeadsRequest, ctx: ClientContext): Promise<ApiResponse<number>> {
        const result = await sendApi<AddLeadsRequest, number>(`/${this.resourceName}/addLeads`, "POST", req, ctx);
        return result
    }

    async regenerate(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        const result = await sendApi<void, void>(`/${this.resourceName}/${req.id}/regenerate`, "POST", undefined, ctx);
        return result
    }
}