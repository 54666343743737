import MaterialIcon from "../common/MaterialIcon";
import {FilterField, IReadonlyApiService} from "lib-shared";
import {useContext, useState} from "react";
import {UserContext} from "../../context/UserContext";
import {ColorType, colorTypeToClass, fixHref} from "../../utils/Utils";
import ApiResultBlock from "../ApiResultBlock";
import {useApiCount} from "../../hooks/useApiList";
import {Link} from "react-router-dom";
import {ClientContext} from "lib-client";

function CounterMetric(props: Props) {
    const {clientContext} = useContext(UserContext)

    const result = useApiCount(clientContext, props.client, {filterFields: props.filters}, {})

    let color: ColorType | undefined = undefined

    let value = (result.value + "") ?? "-"
    if (result.value == null) {
        color = undefined
    } else if (result.value > 0) {
        color = props.gtZeroColor
    } else {
        color = props.eqZeroColor
    }


    return (
            <div className="card">
                <div className="p-4">
                    <ApiResultBlock result={result}>
                        {(value) => (
                            <>
                                <h6 className="text-limit text-muted mb-3 text-truncate"><MaterialIcon icon={props.icon}
                                                                                                       margin={2}/>{props.title}
                                </h6>
                                <span className="text-sm text-muted text-opacity-90 fw-semibold">{props.unit}</span>
                                <span
                                    className={`d-block h3 ls-tight fw-bold ${colorTypeToClass(color)}`}>{value}</span>
                            </>
                        )}
                    </ApiResultBlock>
                </div>
            </div>
    )
}

interface Props {
    readonly title: string
    readonly unit: string
    readonly icon: string
    readonly sub?: string
    readonly gtZeroColor?: ColorType
    readonly eqZeroColor?: ColorType
    readonly client: IReadonlyApiService<any, ClientContext>
    readonly filters: FilterField[]
}

export default CounterMetric