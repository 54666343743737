import {useQueryState} from "../../hooks/useQueryParam";
import {ColumnSpec} from "../../components/tables/BaseTable";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import IconButton from "../../components/common/IconButton";
import {SegmentationJob, SegmentationJobSchema} from "lib-shared";
import SegmentationJobEditorModal from "./SegmentationJobEditorModal";
import {useContext} from "react";
import ApiTable, {ApiOrderBy, createColumn,} from "../../components/tables/ApiTable";
import {TableAction} from "../../components/tables/TableActions";
import {useAppNav} from "../../hooks/useAppNav";
import {SegmentationClassClient, SegmentationJobsClient} from "lib-client";
import {useApiList} from "../../hooks/useApiList";
import {UserContext} from "../../context/UserContext";

export const SegmentationJobEditorKey = "job_id";

function SegmentationJobPage(props: Props) {
    const [editId, setEditId] = useQueryState(SegmentationJobEditorKey);
    const navigate = useAppNav();
    const {clientContext} = useContext(UserContext);

    const segmentsResult = useApiList(clientContext, SegmentationClassClient.defaultClient, {pageSize: 100}, {})

    const segments = segmentsResult.value ?? []

    const columns: ColumnSpec<SegmentationJob, ApiOrderBy>[] = [
        createColumn(SegmentationJobSchema.Name, true),
        createColumn(SegmentationJobSchema.SegmentClassIds, true, {
            valueFormatter: (classes: string[], model: SegmentationJob) => {
                return classes.map(c => segments.find(s => s.class_id == c)?.name ?? c).join(", ")
            }
        }),
        createColumn(SegmentationJobSchema.CreatedAt, true),
        createColumn(SegmentationJobSchema.JobState, true),
    ];

    function actionCreator(jb: SegmentationJob[]): TableAction[] {
        return [
            // todo archive
        ];
    }

    return (
        <PowerPageContent
            title={"Segmentation Jobs"}
            buttons={[
                <IconButton
                    key={"create-job"}
                    action={() => setEditId("")}
                    icon={"plus-circle"}
                    label={"New Job"}
                />,
            ]}
        >
            <ApiTable<SegmentationJob>
                client={SegmentationJobsClient.defaultClient}
                noDataMessage={"No Jobs yet."}
                tableSettings={{
                    columns,
                    idExtractor: (c) => c.job_id,
                    filterOptions: [],
                    actionCreator: actionCreator,
                    rowClickCallback: (cls: SegmentationJob) => navigate(`/segmentation/jobs/${cls.job_id}`),
                }}
            />
            <SegmentationJobEditorModal
                show={editId != null}
                edit_id={editId}
                close={() => setEditId(null)}
            />
        </PowerPageContent>
    );
}

interface Props {
}

export default SegmentationJobPage;
