import AppModal from "../../components/AppModal";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {
    CompanySizeType,
    IndustryType, isSuccess,
    ProspectingJob,
    ProspectingJobSchema,
    ProspectingJobState,
    RoleType
} from "lib-shared";
import {ProspectingJobsClient} from "lib-client";
import {v4 as uuidv4} from "uuid"
import {UserContext} from "../../context/UserContext";
import {eventBus} from "../../utils/EventBus";
import {useContext} from "react";
import {Modal} from "react-bootstrap";
import {FormValueSelectItem} from "../../components/forms/FormFields";
import {useApiGet} from "../../hooks/useApiGet";
import {toastIfError} from "../../context/toasts/ToastManager";

function ProspectingJobEditorModal(props: Props) {
    const {clientContext} = useContext(UserContext);

    const isEdit = props.editId != ""

    const initialJob = useApiGet<ProspectingJob>(clientContext, ProspectingJobsClient.defaultClient, props.editId)

    async function submit(payload: FormSubmitPayload<ProspectingJob>): Promise<boolean> {
        if (isEdit)
            return false

        const job: ProspectingJob = {
            ...payload.value,
            job_id: uuidv4(),
            state: ProspectingJobState.pending,
            org_id: clientContext.org_id!,
        }

        const response = await new ProspectingJobsClient().create(job, clientContext)

        if (!isSuccess(response)) {
            toastIfError(clientContext, response)
            return false
        }

        eventBus.trigger("item-changed", undefined)
        props.close()
        return true
    }

    return (
        <AppModal show={props.editId != null} onHide={props.close} name={"prospecting job editor"}>
            <Modal.Header closeButton>
                <Modal.Title>Create Job</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppForm
                    onSubmit={submit}
                    initialValueLazy={initialJob}
                    disableSubmit={isEdit}
                    schema={ProspectingJobSchema}
                    fields={[
                        {
                            fieldType: "text",
                            field: ProspectingJobSchema.Name,
                            tip: "We put the prospected leads into a group. If you don't supply a name we'll pick one for you.",
                        },
                        {
                            fieldType: "text",
                            field: ProspectingJobSchema.NumLeads,
                        },
                        {
                            fieldType: "multi-select",
                            field: ProspectingJobSchema.IndustryFilters,
                            options: enumOptions(IndustryType)
                        },
                        {
                            fieldType: "multi-select",
                            field: ProspectingJobSchema.RoleFilters,
                            options: enumOptions(RoleType)
                        },
                        {
                            fieldType: "multi-select",
                            field: ProspectingJobSchema.CompanySizeFilters,
                            options: enumOptions(CompanySizeType)
                        },

                    ]}
                />
            </Modal.Body>
        </AppModal>
    )
}

function enumOptions(enumValue: any): FormValueSelectItem[] {
    return Object.keys(enumValue).map(key => {
        return {
            id: enumValue[key],
            label: tidyEnum(enumValue[key])
        }
    })
}

function tidyEnum(value: string): string {
    return value.replace("_", " ").replace("and", "&")
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
}

interface Props {
    readonly editId: string | null
    readonly close: () => void
    callback?: (profile: ProspectingJob) => void
}

export default ProspectingJobEditorModal