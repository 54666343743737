import {
    ApiErrorCode,
    ApiResponse,
    BulkUploadRequest,
    CountItemsOptions,
    encodeFilterFields,
    GenericItemRequest,
    getErrorOrNull, getResultOrNull,
    IBaseModel,
    ListPaginateOptions,
    Organization_Id, OrgId,
    RequestIdHeaderName
} from "lib-shared";
import {v4 as uuidv4} from "uuid"
import {BaseReadOnlyClient, ClientProps, IReadonlyClient} from "./clients/BaseReadOnlyClient";

export interface FirebaseUser {
    readonly uid: string

    getIdToken(forceRefresh?: boolean): Promise<string>;
}

export interface ClientContext {
    readonly user: FirebaseUser
    readonly org_id: OrgId | null
    readonly env: EnvType
    readonly loadingFirebase: boolean
}

export async function listAll<T extends IBaseModel>(client: IReadonlyClient<T>, req: ListPaginateOptions, ctx: ClientContext, clientProps: ClientProps = {}): Promise<ApiResponse<T[]>> {
    const resultIds = new Set<string>()
    const results: T[] = []

    let pageNum = 0

    while (true) {
        req = {
            ...req,
            pageNum,
            pageSize: 100,
            orderBy: req.orderBy,
            orderByDirection: req.orderByDirection ?? "asc",
        }
        const response = await client.list(req, ctx)

        const error = getErrorOrNull(response)
        if (error) {
            return Promise.reject(error.message)
        }


        const items = getResultOrNull<T[]>(response)
        if (items != null) {
            if (items.length == 0) {
                // we've read all items
                break;
            }

            for (let item of items) {
                const id = client.schema.getPrincipalId(item)

                if (resultIds.has(id)) {
                    // someone else must've added a new item mid-operation
                    console.log(`already have id: ${id}`)
                    continue
                }

                results.push(item)
                resultIds.add(id)
            }

            if (clientProps.addProgress) {
                clientProps.addProgress(items.length)
            }
        }

        pageNum += 1;
    }

    return {
        value: results,
    }
}

export function addHydrationParam(params: URLSearchParams, isHydrated?: boolean): void {
    if (isHydrated) {
        params.set("fullyHydrated", "true")
    }
}

export async function fetchApiGet<T>(path: string, req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<T>> {
    const params: URLSearchParams = new URLSearchParams()

    addHydrationParam(params, req.fullyHydrated ?? false)

    const result = await fetchApi<T>(`${path}?${params.toString()}`, ctx)
    return result
}

export async function fetchApiPaginated<T>(path: string, paginateOptions: ListPaginateOptions, client: ClientContext): Promise<ApiResponse<T[]>> {
    const params: URLSearchParams = new URLSearchParams({
        pageNum: (paginateOptions.pageNum ?? 0).toString(),
        pageSize: (paginateOptions.pageSize ?? 25).toString(),
        orderByDir: (paginateOptions.orderByDirection ?? "asc"),
    });

    params.set("orderBy", paginateOptions.orderBy ?? "id")
    params.set("orderByDir", paginateOptions.orderByDirection ?? "asc")

    encodeFilterFields(params, paginateOptions.filterFields ?? [])

    addHydrationParam(params, paginateOptions.fullyHydrated ?? false)
    return await fetchApi<T[]>(`${path}?${params.toString()}`, client)
}


export async function fetchApiCount(path: string, countItemsOptions: CountItemsOptions, client: ClientContext): Promise<ApiResponse<number>> {
    const params: URLSearchParams = new URLSearchParams()

    addHydrationParam(params, countItemsOptions.hydrate)
    encodeFilterFields(params, countItemsOptions.filterFields ?? [])
    return await fetchApi<number>(`${path}?${params.toString()}`, client)
}

export type EnvType = "local" | "dev" | "prod"

export function getDomain(cc: ClientContext) {
    switch (cc.env) {
        case "local":
            return "http://localhost:3001"
        case "dev":
            return "https://api.dev.zensend.ai"
        case "prod":
            return "https://api.prod.zensend.ai"
        default:
            throw new Error(`Unrecognized env: '${cc.env}'`)
    }
}

export async function bulkUpload<T>(values: T[], operation: (req: BulkUploadRequest<T>, ctx: ClientContext) => Promise<ApiResponse<void>>, ctx: ClientContext, chunkSize: number = 50, clientProps: ClientProps = {}): Promise<ApiResponse<void>> {
    const id = uuidv4()

    for (let i = 0; i < values.length; i += chunkSize) {
        const batch = values.slice(i, i + chunkSize);
        const isDone = i + chunkSize >= values.length
        const req: BulkUploadRequest<T> = {
            id,
            total: values.length,
            done: isDone,
            batch,
        }
        const result = await operation(req, ctx)

        if (getErrorOrNull(result)) {
            return result
        }

        if (clientProps.addProgress) {
            clientProps.addProgress(batch.length)
        }
    }

    return Promise.resolve({value: undefined, success: true})
}

function getErrorResponseFromResponseCode(code: number): ApiResponse<any> {
    switch (code) {
        case 401:
            return {
                value: {
                    message: "Auth is invalid or expired.",
                    code: ApiErrorCode.UNAUTHENTICATED,
                }
            }
        case 403:
            return {
                value: {
                    message: "Not authorized to access resource",
                    code: ApiErrorCode.PERMISSION_DENIED,
                }
            }
        default:
            return {
                value: {
                    message: "Unknown error",
                    code: ApiErrorCode.UNKNOWN_ERROR,
                }
            }
    }

}


export async function fetchApi<T>(path: string, cc: ClientContext, req: RequestInit = {}): Promise<ApiResponse<T>> {
    const {user} = cc

    const domain = getDomain(cc)

    const url = new URL(domain + path)

    if (cc.org_id) {
        url.searchParams.set(Organization_Id, cc.org_id)
    }

    const request_id = uuidv4()

    const token = await user.getIdToken(false)
    const headers: HeadersInit = [
        ["authorization", `Bearer ${token}`],
        ["content-type", "application/json"],
        [RequestIdHeaderName, request_id],
    ]

    let response: Response
    try {
        response = await fetch(url,
            {
                ...req,
                headers
            });
    } catch (e) {
        console.error(`Failed to fetch ${url}`)
        console.error(e)
        return Promise.resolve({
            value: {
                code: ApiErrorCode.UNAVAILABLE,
                message: "Server unavailable"
            }
        })
    }


    try {
        const result = await response.json()
        return Promise.resolve(result as ApiResponse<T>)
    } catch (e) {
        // failed to parse response... try to convert the status to an error code
        if (!response.ok) {
            console.info(`Response bad: ${response.status} ${response.statusText}`)
            return Promise.resolve(getErrorResponseFromResponseCode(response.status))
        }

        return Promise.resolve({
            value: {
                message: "Failed to parse response",
                code: ApiErrorCode.UNKNOWN_ERROR
            }
        })
    }
}

export async function sendArchive(client: BaseReadOnlyClient<any>, req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
    return await sendApi<void, void>(`/${client.resourceName}/${req.id}/archive`, "POST", undefined, ctx);
}

export async function sendUnarchive(client: BaseReadOnlyClient<any>, req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
    return await sendApi<void, void>(`/${client.resourceName}/${req.id}/unarchive`, "POST", undefined, ctx);
}

export async function sendApi<Req, Res>(path: string, method: "POST" | "PATCH" | "DELETE", body: Req, client: ClientContext): Promise<ApiResponse<Res>> {

    const props: RequestInit = {
        method,
        // API fails requests that have null bodies
        body: JSON.stringify(body ?? {}),
    }

    return await fetchApi(path, client, props)
}