import {OrgModel} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {CharacterSet, CharacterSetType, Required} from "../validation/Validators";

export interface Lead extends OrgModel {
    readonly lead_id: string
    readonly owner: string | null
    readonly first_name?: string
    readonly last_name?: string
    readonly email: string
    readonly is_researched: boolean
    readonly is_personal_email?: boolean
    readonly gender?: string
    readonly industry?: string
    readonly job_title?: string
    readonly job_company_name?: string
    readonly skills?: string[]
    readonly job_company_id?: string
    readonly text_summary?: string
}


export class LeadSchemaDef extends OrgModelSchema<Lead> {
    get SchemaName(): string {
        return "Lead"
    }

    getFriendlyName(value: Lead): string {
        return value.email
    }

    get apiResourceName(): string {
        return "leads";
    }

    get KeyColumns(): IModelField<any, Lead>[] {
        return [this.OrgId, this.LeadId];
    }

    public readonly LeadId = FieldClass.createIdField("lead_id")

    public readonly Skills = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Skills",
        name: "skills",
        field_source: FieldSourceType.USER_DEFINED
    })

    public readonly FirstName = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "First Name",
        name: "first_name",
        field_source: FieldSourceType.USER_DEFINED
    })


    public readonly IsResearched = new FieldClass<boolean, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Is Researched",
        name: "is_researched",
        field_source: FieldSourceType.SYSTEM_DEFINED
    })

    public readonly IsPersonalEmail = new FieldClass<boolean, Lead>({
        field_type: FieldType.TOGGLE,
        display_name: "Is Personal Email",
        name: "is_personal_email",
        field_source: FieldSourceType.SYSTEM_DEFINED
    })

    public readonly Gender = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Gender",
        name: "gender",
        field_source: FieldSourceType.USER_DEFINED
    })


    public readonly Industry = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Industry",
        name: "industry",
        field_source: FieldSourceType.USER_DEFINED
    })

    public readonly DisplaySummary = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Summary",
        name: "display_summary",
        field_source: FieldSourceType.SYSTEM_DEFINED
    })


    public readonly JobTitle = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Job Title",
        name: "job_title",
        field_source: FieldSourceType.USER_DEFINED
    })

    public readonly JobCompanyName = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Job Company Name",
        name: "job_company_name",
        field_source: FieldSourceType.USER_DEFINED
    })

    public readonly LastName = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Last Name",
        name: "last_name",
        field_source: FieldSourceType.USER_DEFINED
    })

    public readonly Email = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Email", name: "email",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [
            new Required<Lead>(),
            new CharacterSet<Lead>(CharacterSetType.Email)
        ]
    })

    public readonly TextSummary = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Summary", name: "text_summary",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })

    public readonly Owner = new FieldClass<string, Lead>({
        field_type: FieldType.TEXT,
        display_name: "Owner",
        name: "owner",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })

    public readonly JobCompanyId = FieldClass.createIdField("job_company_id")
}

export const LeadSchema: LeadSchemaDef = new LeadSchemaDef()

export function niceLeadString(lead: Lead): string {
    if (!lead.first_name && !lead.last_name) {
        return `Unknown Name (${lead.email})`
    }
    return `${lead.first_name} ${lead.last_name} (${lead.email})`
}
