import {BaseCrudClient} from "./BaseCrudClient";
import {
    ApiResponse,
    ISegmentationJobService,
    ModelSchema,
    SegmentationJob,
    SegmentationJobSchema,
    SegmentGroupRequest
} from "lib-shared";
import {ClientContext, sendApi} from "../FetchUtils";

export class SegmentationJobsClient
    extends BaseCrudClient<SegmentationJob>
    implements ISegmentationJobService<ClientContext> {
    public static readonly defaultClient: SegmentationJobsClient = new SegmentationJobsClient()


    async segmentGroup(req: SegmentGroupRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendApi<SegmentGroupRequest, void>(`/${this.resourceName}/segmentGroup`, "POST", req, ctx);
    }

    get schema(): ModelSchema<SegmentationJob> {
        return SegmentationJobSchema;
    }
}