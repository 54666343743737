import {Draft, EmailSection, getDraftSection, Tone} from "./Draft";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {EnumValues, MaxLength, MinLength, Required} from "../validation/Validators";
import {IArchivable, OrgModel} from "./Model";
import {makeIsArchivedField} from "../validation/ValidationUtils";

export interface Campaign extends OrgModel, IArchivable {
    readonly campaign_id: string
    readonly owner: string
    readonly name: string
    readonly sender_profile_id: string
    readonly tone: Tone
    readonly state: CampaignState
    readonly email_size: EmailSize
    readonly core_message: string
    readonly call_to_action: string
    readonly default_body?: string
    readonly default_subject?: string
    readonly default_closing?: string
    readonly default_greeting?: string
}

export enum EmailSize {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
}

export const CoreMessageMinLength = 50
export const CoreMessageMaxLength = 1024

class CampaignSchemaDef extends OrgModelSchema<Campaign> {

    public get apiResourceName(): string {
        return "campaigns"
    }

    getFriendlyName(value: Campaign): string {
        return value.name
    }

    get SchemaName(): string {
        return "Campaign"
    }

    get KeyColumns(): IModelField<any, Campaign>[] {
        return [this.OrgId, this.CampaignId];
    }

    public readonly CampaignId = FieldClass.createIdField("campaign_id")

    public readonly IsArchived = makeIsArchivedField()


    public readonly Name = new FieldClass<string, Campaign>({
        field_type: FieldType.TEXT,
        display_name: "Name",
        name: "name",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [
            new Required<Campaign>(),
            new MinLength<Campaign>(4)
        ]
    })

    public readonly State = new FieldClass<string, Campaign>({
        field_type: FieldType.SELECT,
        display_name: "State",
        name: "state",
        field_source: FieldSourceType.USER_DEFINED,
        rules: []
    })

    public readonly Owner = new FieldClass<string, Campaign>({
        field_type: FieldType.SELECT,
        display_name: "Owner",
        name: "owner",
        field_source: FieldSourceType.USER_DEFINED,
        rules: []
    })

    public readonly Tone = new FieldClass<string, Campaign>({
        field_type: FieldType.SELECT,
        display_name: "Tone",
        name: "tone",
        field_source: FieldSourceType.USER_DEFINED,
        rules: []
    })

    public readonly CallToAction = new FieldClass<string, Campaign>({
        field_type: FieldType.SELECT,
        display_name: "Call to Action",
        name: "call_to_action",
        field_source: FieldSourceType.USER_DEFINED,
        rules: []
    })

    public readonly SenderProfileId = new FieldClass<string, Campaign>({
        field_type: FieldType.SELECT,
        display_name: "Sender Profile",
        name: "sender_profile_id",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [
            new Required<Campaign>(),
        ]
    })

    public readonly CoreMessage = new FieldClass<string, Campaign>({
        field_type: FieldType.TEXT,
        display_name: "Core Message",
        name: "core_message",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [
            new Required<Campaign>(),
            new MinLength<Campaign>(CoreMessageMinLength),
            new MaxLength<Campaign>(CoreMessageMaxLength),
        ]
    })
    public readonly EmailSize = new FieldClass<string, Campaign>({
        field_type: FieldType.SELECT,
        display_name: "Email Size",
        name: "email_size",
        field_source: FieldSourceType.USER_DEFINED,
        defaultValue: EmailSize.SMALL,
        rules: [
            new Required<Campaign>(),
            new EnumValues<Campaign>(EmailSize),
        ]
    })


    public readonly DefaultBody = new FieldClass<string, Campaign>({
        field_type: FieldType.TEXT,
        display_name: "DefaultBody",
        name: "default_body",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })
    public readonly DefaultSubject = new FieldClass<string, Campaign>({
        field_type: FieldType.TEXT,
        display_name: "DefaultSubject",
        name: "default_subject",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })
    public readonly DefaultClosing = new FieldClass<string, Campaign>({
        field_type: FieldType.TEXT,
        display_name: "DefaultClosing",
        name: "default_closing",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })
    public readonly DefaultGreeting = new FieldClass<string, Campaign>({
        field_type: FieldType.TEXT,
        display_name: "DefaultGreeting",
        name: "default_greeting",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })
}

export const CampaignSchema: CampaignSchemaDef = new CampaignSchemaDef()

export function getCampaignDefaultSection(campaign: Campaign, section: EmailSection): string {
    switch (section) {
        case EmailSection.Subject:
            return campaign?.default_subject!
        case EmailSection.Greeting:
            return campaign?.default_greeting!
        case EmailSection.Body:
            return campaign?.default_body!
        case EmailSection.Closing:
            return campaign?.default_closing!
    }
}

export function getDraftSectionOrDefault(draft: Draft, campaign: Campaign, section: EmailSection): string {
    const draftValue = getDraftSection(draft, section)
    if (draftValue != null) {
        return draftValue
    }

    return getCampaignDefaultSection(campaign, section)
}

export type CampaignState = "draft" | "launched"
export const CampaignStates: CampaignState[] = ["draft", "launched"]
