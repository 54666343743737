import {
    ApiResponse,
    GenericItemRequest,
    ISenderProfilesService,
    ModelSchema,
    SenderProfile,
    SenderProfileSchema
} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext, sendArchive, sendUnarchive} from "../FetchUtils";


export class SenderProfilesClient extends BaseCrudClient<SenderProfile> implements ISenderProfilesService<ClientContext> {
    public static readonly defaultClient: SenderProfilesClient = new SenderProfilesClient()

    get schema(): ModelSchema<SenderProfile> {
        return SenderProfileSchema;
    }

    async archive(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendArchive(this, req, ctx)
    }

    async unarchive(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendUnarchive(this, req, ctx)
    }
}