import {useContext, useState} from "react";
import {UserContext} from "../../context/UserContext";
import OrgPicker from "../orgPicker/OrgPicker";
import {auth} from "../../utils/Firebase";
import {useAppNav} from "../../hooks/useAppNav";
import {signOut} from "firebase/auth";
import {fixHref} from "../../utils/Utils";
import {Link} from "react-router-dom";
import ZLink from "../common/ZLink";

function NavbarUser(props: Props) {
    const {fbUser, clientContext, appUser} = useContext(UserContext);
    const [show, setShow] = useState(false)
    const navigate = useAppNav()

    async function signout() {
        await signOut(auth)
        navigate('/')
    }

    if (!fbUser || !appUser) {
        return <></>
    }

    return (
        <div className="">
            <div className="dropdown">
                <a className="d-flex align-items-center" href="#" role="button"
                   data-bs-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                    <div className="avatar avatar-sm text-bg-dark rounded-circle">
                        {fbUser?.photoURL && <img alt="account" width={32} height={32} src={fbUser.photoURL}/>}
                    </div>
                    <div className="ms-3"><span className="h6">{appUser?.first_name}</span></div>
                    <div className="ms-md-2"><i
                        className="bi bi-chevron-down text-muted text-xs"></i></div>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                    <div className="dropdown-header"><span
                        className="d-block text-sm text-muted mb-1">Signed in as</span>
                        <span
                            className="d-block text-heading fw-semibold">{appUser?.first_name} {appUser?.last_name}</span>
                    </div>
                    {props.enableOrgFeatures && <>
                        <div className="dropdown-divider"/>
                        <a className="dropdown-item" href="#" onClick={() => setShow(true)}>
                            <i className="bi bi-building me-3"/>Change Org
                        </a></>
                    }
                    <ZLink className="dropdown-item" href={"/profile"}>
                        <i className="bi bi-person me-3"/>Profile
                    </ZLink>
                    <Link className="dropdown-item" to={fixHref("/settings")}>
                        <i className="bi bi-gear me-3"/>Settings
                    </Link>
                    <div className="dropdown-divider"/>
                    <a className="dropdown-item" href="#" onClick={signout}><i className="bi bi-box-arrow-right me-3"/>Logout</a>
                </div>
            </div>
            <OrgPicker show={show} setShow={setShow}/>
        </div>
    )
}

interface Props {
    readonly enableOrgFeatures: boolean
}

export default NavbarUser