import {
    ApiResponse,
    autoBind,
    BulkUploadRequest,
    containsElement,
    GenericItemRequest,
    IBaseModel,
    ICrudApiService,
    SetRequest
} from "lib-shared"
import {bulkUpload, ClientContext, sendApi, sendArchive, sendUnarchive,} from "../FetchUtils"
import {BaseReadOnlyClient, ClientProps} from "./BaseReadOnlyClient";


export abstract class BaseCrudClient<TValue extends IBaseModel> extends BaseReadOnlyClient<TValue> implements ICrudApiService<TValue, ClientContext> {

    constructor(props: ClientProps = {}) {
        super(props)
        autoBind(this)
    }

    async createOrUpdate(req: TValue, isCreate: boolean, ctx: ClientContext): Promise<ApiResponse<TValue>> {
        if (isCreate) {
            const result = await this.create(req, ctx)
            return result
        } else {
            const result = await this.update(req, ctx)
            // fake a CreateItemResult
            return Promise.resolve({
                ...result,
                value: req
            })
        }
    }

    async bulkAddManaged(values: TValue[], ctx: ClientContext): Promise<ApiResponse<void>> {
        return await bulkUpload(values, this.bulkAdd, ctx)
    }

    async bulkAdd(req: BulkUploadRequest<TValue>, ctx: ClientContext): Promise<ApiResponse<void>> {
        return this.doBulkAdd(`/${this.resourceName}/bulkAdd`, req, ctx)
    }

    protected async doBulkAdd<TOther>(path: string, req: BulkUploadRequest<TOther>, ctx: ClientContext): Promise<ApiResponse<void>> {
        if (req.batch.length == 0)
            return Promise.resolve({value: undefined})

        const result = await sendApi<BulkUploadRequest<TOther>, void>(path, "POST", req, ctx);
        return result
    }

    async setIds(prev: TValue[], curr: TValue[], idExtractor: (t: TValue) => string, isDirty: (a: TValue, b: TValue) => boolean, ctx: ClientContext): Promise<ApiResponse<void>> {
        const toDelete: TValue[] = prev.filter(i => !containsElement(curr, i, idExtractor))
        const toAdd: TValue[] = curr.filter(i => !containsElement(prev, i, idExtractor))

        const toUpdate: TValue[] = curr
            .filter(i => !containsElement([...toDelete, ...toAdd], i, idExtractor))
            .filter(currValue => {
                const prevValue = prev.find(p => idExtractor(p) == idExtractor(currValue))

                // shouldn't happen
                if (!prevValue)
                    return null

                if (isDirty(currValue, prevValue)) {
                    return currValue
                }
                return null
            })
            .filter(i => i != null)

        return this.set({
            toAdd,
            toUpdate,
            toDelete
        }, ctx)
    }

    async set(req: SetRequest<TValue>, ctx: ClientContext): Promise<ApiResponse<void>> {
        if (req.toUpdate.length == 0 && req.toAdd.length == 0 && req.toDelete.length == 0) {
            return Promise.resolve({value: undefined, success: true})
        }

        const result = await sendApi<SetRequest<TValue>, void>(`/${this.resourceName}/set`, "POST", req, ctx)
        return result
    }

    async delete(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        const result = await sendApi<void, void>(`/${this.resourceName}/${req.id}`, "DELETE", undefined, ctx);
        return result
    }

    async archive(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendArchive(this, req, ctx)
    }

    async unarchive(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendUnarchive(this, req, ctx)
    }

    async create(value: TValue, ctx: ClientContext): Promise<ApiResponse<TValue>> {
        return await sendApi<TValue, TValue>(`/${this.resourceName}`, "POST", value, ctx);
    }

    async update<TValue>(req: TValue, ctx: ClientContext): Promise<ApiResponse<void>> {
        const result = await sendApi<TValue, void>(`/${this.resourceName}`, "PATCH", req, ctx);
        return result
    }
}
