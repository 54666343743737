import {SocialMediaPlatform, SocialMediaPostKey} from "lib-shared";

export function parsePostUrl(url: string): SocialMediaPostKey {
    if (url.includes("tiktok.com")) {
        // https://www.tiktok.com/@lustraclear/video/7326380003074002219
        const groups = /https:\/\/www.tiktok.com\/(@[a-zA-Z0-9]+)\/video\/([0-9]+)/.exec(url)
        if (groups==null){
            throw new Error("Unable to parse url")
        }
        return {platform: SocialMediaPlatform.TIKTOK, username: groups[1], post_id: groups[2]}
    }
    throw new Error("Unknown url")
}