// todo make this class private and only expose a safer version of it to the user
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {OrgModel} from "./Model";
import {CharacterSet, CharacterSetType, Required} from "../validation/Validators";
import {EnvType} from "../requests/Api";

export interface EmailProvider extends OrgModel, OAuth2Credentials {
    readonly email_provider_id: string
    readonly email_provider_type: EmailProviderType
    readonly email: string | null
    // null if no issue otherwise has issue
    readonly issue_message: string | null
    readonly access_token: string | null
    readonly refresh_token: string | null
    // unix epoch. get date with `new Date(expiryDate)`
    readonly expires_at: number | null

    /**
     * used to verify that the request is valid. We pass this code to the email provider during the initial auth code handoff.
     * The callback to our server is done by the email provider so we can't use traditional auth methods. If this is
     * null we aren't expecting to handle an auth code and we should reject the request.
     */
    readonly verification_code: string | null
}


export class EmailProviderSchemaDef extends OrgModelSchema<EmailProvider> {
    public get apiResourceName(): string {
        return "emailProviders"
    }

    getFriendlyName(value: EmailProvider): string {
        return value.email ?? value.email_provider_id
    }

    get SchemaName(): string {
        return "Email Provider"
    }


    get KeyColumns(): IModelField<any, EmailProvider>[] {
        return [this.OrgId, this.EmailProviderId];
    }

    public readonly EmailProviderId = FieldClass.createIdField("email_provider_id")

    public readonly AccessToken = FieldClass.createSecretField("access_token")

    public readonly RefreshToken = FieldClass.createSecretField("refresh_token")

    public readonly AccessTokenExpiresAt = FieldClass.createSecretField("expires_at")

    public readonly VerificationCode = FieldClass.createSecretField("verification_code")

    public readonly Email = new FieldClass<string, EmailProvider>({
        field_type: FieldType.TEXT,
        display_name: "Email",
        name: "email",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [
            new CharacterSet(CharacterSetType.Email),
        ]
    })

    public readonly IssueMessage = new FieldClass<string, EmailProvider>({
        field_type: FieldType.TEXT,
        display_name: "Issue",
        name: "issue_message",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })

    public readonly Type = new FieldClass<string, EmailProvider>({
        field_type: FieldType.TEXT,
        display_name: "Type",
        name: "email_provider_type",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })
}

export const EmailProviderSchema: EmailProviderSchemaDef = new EmailProviderSchemaDef()

export interface OAuth2Credentials {
    readonly access_token: string | null
    readonly refresh_token: string | null
    // unix epoch. get date with `new Date(expiryDate)`
    readonly expires_at: number | null
}

export enum EmailProviderType {
    Google = "google",
    Microsoft = "microsoft"
}

export interface HandleAuthCodeCallbackRequest {
    readonly id: string
    readonly type: EmailProviderType
    readonly code: string
}

export interface SetupEmailProviderResponse {
    readonly configId: string
    readonly redirectUrl: string
}

export interface SetupEmailProviderRequest {
    readonly type: EmailProviderType
    readonly envType: EnvType
}