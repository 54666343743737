import {
    ApiResponse,
    BulkUploadLead,
    BulkUploadRequest,
    GenericItemRequest,
    ILeadsService,
    Lead,
    LeadSchema,
    ModelSchema,
} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {bulkUpload, ClientContext, sendApi} from "../FetchUtils";


export class LeadsClient extends BaseCrudClient<Lead> implements ILeadsService<ClientContext> {
    public static readonly defaultClient: LeadsClient = new LeadsClient()

    get schema(): ModelSchema<Lead> {
        return LeadSchema;
    }

    bulkAddLeadsManaged(values: BulkUploadLead[], ctx: ClientContext): Promise<ApiResponse<void>> {
        return bulkUpload(values, this.bulkAddLeads, ctx, 50, this.props)
    }

    bulkAddLeads(req: BulkUploadRequest<BulkUploadLead>, ctx: ClientContext): Promise<ApiResponse<void>> {
        return this.doBulkAdd(`/${this.resourceName}/bulkAddLeads`, req, ctx)
    }

    async summarizeLead(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return sendApi(`/${this.resourceName}/${req.id}/summarize`, "POST", {}, ctx);
    }
}
