import {
    ApiResponse,
    CountItemsOptions, getErrorOrNull, ICountApiService,
    IListApiService,
    IReadonlyApiService,
    ListPaginateOptions,
    Model
} from "lib-shared";
import {ClientContext,} from "lib-client";
import {ApiResult, doApiThing, RequestParams} from "./useApi";
import {toastIfError} from "../context/toasts/ToastManager";
import {AppClientContext} from "../utils/Utils";

export function useApiCount(clientContext: AppClientContext | undefined, client: ICountApiService<any, ClientContext>, opt: CountItemsOptions, requestParams: RequestParams): ApiResult<number> {
    return doApiThing<number>(clientContext, async (cc): Promise<ApiResponse<number>> => {
        return await client.count(opt, cc)
    }, requestParams, [JSON.stringify(opt.filterFields)])
}

export function useApiList<T extends Model>(clientContext: AppClientContext | undefined, client: IListApiService<T, ClientContext>, opt: ListPaginateOptions, requestParams: RequestParams, admin: boolean = false): ApiResult<T[]> {
    return doApiThing<T[]>(clientContext, async (cc): Promise<ApiResponse<T[]>> => {
        return await client.list(opt, cc)
    }, requestParams, [opt.pageNum, opt.pageSize, opt.orderBy, opt.orderByDirection, JSON.stringify(opt.filterFields)])
}

export function mapApiResult<TOriginal, TFinal>(result: ApiResult<TOriginal>, mapper: (o: TOriginal | null) => TFinal): ApiResult<TFinal> {
    if (result.isLoading || result.error) {
        return {
            ...result,
            value: null
        }
    }


    return {
        ...result,
        value: mapper(result.value)
    }
}
