export type KeyValue<T> = { [key: string]: T }


export function randomElement<T>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)]
}

export function isDate(x: any): boolean {
    const isDate = typeof x?.getMonth === 'function'
    return isDate
}

export function arraysEqual<T>(a: T[], b: T[]): boolean {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export function getDuplicates<T>(values: T[], idExtractor: (value: T) => string): T[] {

    const map = new Map<string, T[]>()

    for (let v of values) {
        const id = idExtractor(v)
        if (!map.has(id)) {
            map.set(id, [])
        }
        map.get(id)!.push(v)
    }

    const results: T[] = []

    map.forEach((value, key) => {
        if (value.length > 1) {
            results.push(...value)
        }
    })

    return results
}

export function autoBind(self: any) {
    function getAllProperties(object: any): Set<[any, string | symbol]> {
        const properties = new Set<[any, string | symbol]>();

        do {
            for (const key of Reflect.ownKeys(object)) {
                properties.add([object, key]);
            }
        } while ((object = Reflect.getPrototypeOf(object)) && object !== Object.prototype);

        return properties;
    }

    for (const [object, key] of getAllProperties(self.constructor.prototype)) {
        if (key === 'constructor') {
            continue;
        }

        const descriptor = Reflect.getOwnPropertyDescriptor(object, key);
        if (descriptor && typeof descriptor.value === 'function') {
            self[key] = self[key].bind(self);
        }
    }

    return self;
}

export function containsElement<T>(values: T[], target: T, extractor: (t: T) => string): boolean {
    return values.some(c => extractor(c) == extractor(target))
}
