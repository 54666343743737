import * as React from "react";
import {useContext, useState} from "react";
import {Campaign, CampaignSchema, isSuccess, LeadGroup, LeadGroupSchema} from "lib-shared";
import AddGroupModal from "../../components/groups/AddGroupModal";
import IconButton from "../../components/common/IconButton";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {ColumnSpec, RowData} from "../../components/tables/BaseTable";
import {useAppNav} from "../../hooks/useAppNav";
import {UserContext} from "../../context/UserContext";
import {useQueryState} from "../../hooks/useQueryParam";
import ListPickerModal from "../../components/listPicker/ListPickerModal";
import {CampaignsClient, LeadGroupMembershipsClient, LeadGroupsClient, LeadsClient} from "lib-client";
import {ConfirmModalContext} from "../../context/confirmModal/ConfirmModalContext";
import ApiTable, {ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import {TableAction} from "../../components/tables/TableActions";
import {toastIfError} from "../../context/toasts/ToastManager";

export const GroupsEditorKey = "lead_group_id"

export function createGroupLink(group: LeadGroup): string {
    return `/groups?${GroupsEditorKey}=${group}`
}

function GroupsPage() {
    const {confirm} = useContext(ConfirmModalContext);
    const {clientContext} = useContext(UserContext);

    const orderByOptions = [{label: "Name", value: "name"}]
    const nav = useAppNav()
    const [editId, setEditId] = useQueryState(GroupsEditorKey);
    const [sendGroupId, setSendGroupId] = useState<string | null>(null);

    const columns: ColumnSpec<LeadGroup, ApiOrderBy>[] = [
        createColumn(LeadGroupSchema.Name, true),
        createColumn(LeadGroupSchema.UpdatedAt, true),
        createColumn(LeadGroupSchema.CreatedAt, true),
    ]

    function actionCreator(groups: LeadGroup[]): TableAction[] {
        return [
            {
                icon: "send",
                label: "Send to Campaign",
                action: () => setSendGroupId(groups[0].lead_group_id)
            },
            {
                icon: "arrow-clockwise",
                supportsMultiRows: false,
                label: "Re-summarize Leads",
                action: async () => {
                    const result = await new LeadGroupsClient().summarizeLeads({id: groups[0].lead_group_id}, clientContext)
                    toastIfError(clientContext, result)
                }
            },
            {
                icon: "trash",
                label: "Delete",
                color: "danger",
                supportsMultiRows: true,
                action: () => {
                    confirm({
                        action_type: "delete",
                        client: LeadGroupsClient.defaultClient,
                        resources: groups.map(g => {
                            return {id: g.lead_group_id, name: g.name}
                        }),
                    })
                },
            },
        ]
    }

    function clickRow(group: LeadGroup) {
        nav(`/groups/${group.lead_group_id}`)
    }

    return (
        <PowerPageContent title={"Groups"}
                          description={"Groups are a way of organizing your leads."}
                          buttons={[
                              <IconButton key={"add-group"} action={() => setEditId("")} icon={"plus-circle"}
                                          label={"Add Group"}/>,
                          ]}>
            <ApiTable<LeadGroup> client={LeadGroupsClient.defaultClient}
                                 noDataMessage={"No Groups."}
                                 tableSettings={{
                                     columns,
                                     idExtractor: group => group.lead_group_id,
                                     filterOptions: [],
                                     actionCreator: actionCreator,
                                     rowClickCallback: clickRow
                                 }}
            />
            <AddGroupModal show={editId != null} close={() => setEditId(null)}/>

            <ListPickerModal<Campaign> show={sendGroupId != null}
                                       title={"Pick a Campaign"}
                                       description={"Pick a campaign to send this group to."}
                                       client={CampaignsClient.defaultClient}
                                       filters={[{
                                           field: CampaignSchema.State.name,
                                           value: ["launched"]
                                       }]}
                                       getId={c => c.campaign_id}
                                       mapper={c => c.name}
                                       callback={async (campaign: Campaign | null) => {
                                           if (!campaign) {
                                               setSendGroupId(null)

                                               return
                                           }
                                           const result = await new CampaignsClient().addLeads({
                                               campaign_id: campaign.campaign_id,
                                               type: "group_ids",
                                               values: [sendGroupId!]
                                           }, clientContext)
                                           toastIfError(clientContext, result)
                                           if (isSuccess(result))
                                               setSendGroupId(null)
                                       }}/>
        </PowerPageContent>
    )
}

export default GroupsPage