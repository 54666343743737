import Button from "../../components/common/Button";
import AppModal from "../../components/AppModal";
import {Col, Modal, Row} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import SegmentToGroupMappingItem, {SegmentToGroupMapping} from "./SegmentToGroupMappingItem";
import {useApiList} from "../../hooks/useApiList";
import {isSuccess, LeadGroup, LeadGroupSchema, SegmentationClass, SegmentationJob} from "lib-shared";
import {UserContext} from "../../context/UserContext";
import {LeadGroupsClient, SegmentationClassClient} from "lib-client";
import AddGroupModal from "../../components/groups/AddGroupModal";
import {toastIfError} from "../../context/toasts/ToastManager";

function SegmentToGroupMappingModal(props: Props) {
    const {clientContext} = useContext(UserContext)

    const [mappings, setMappings] = useState<SegmentToGroupMapping[]>([])
    const [showGroupCreator, setShowGroupCreator] = useState(false)

    // todo list all
    const groupsResult = useApiList<LeadGroup>(clientContext, LeadGroupsClient.defaultClient, {
        orderBy: LeadGroupSchema.Name.name,
        orderByDirection: "asc",
        pageSize: 100
    }, {})

    const allGroups = groupsResult.value ?? []

    const segmentsResults = useApiList<SegmentationClass>(clientContext, SegmentationClassClient.defaultClient, {}, {})

    const segments = segmentsResults.value ?? []

    useEffect(() => {
        if (segments.length == 0)
            return

        const initialMappings: SegmentToGroupMapping[] = props.segmentationJob.class_ids.map(id => {
            const segment = segments.find(s => s.class_id == id)!
            if (!segment) {
                throw Error("Unknown segment")
            }
            return {
                segment: segment,
                enabled: true,
                group: null
            }
        })
        setMappings(initialMappings)
    }, [segmentsResults.isLoading]);

    function updateMapping(mapping: SegmentToGroupMapping): void {
        const result = [...mappings]
        const index = result.findIndex(m => m.segment.class_id == mapping.segment.class_id)
        result[index] = mapping
        setMappings(result)
    }

    async function submit() {
        let failure = false
        for (let mapping of mappings) {
            if (!mapping.group || !mapping.enabled) {
                continue
            }

            const result = await SegmentationClassClient.defaultClient.addSegmentToGroup({
                segment_id: mapping.segment.class_id,
                lead_group_id: mapping.group.lead_group_id,
                segmentation_job_id: props.segmentationJob.job_id,
            }, clientContext)

            toastIfError(clientContext, result)

            if (!isSuccess(result))
                failure = true
        }

        if (!failure)
            props.close()
    }

    return (
        <>
            <AppModal show={props.show} onHide={props.close} name={"segment-results-mapper"}>
                <Modal.Header closeButton>Add Results to Groups</Modal.Header>
                <Modal.Body>
                    <p>Results from each segment will be added to the specified groups.</p>
                    <Row className={"m-4"}>
                        <span className={"faux-link"} onClick={() => setShowGroupCreator(true)}>New Group</span>
                    </Row>
                    <div className={"mb-5"}>
                        {mappings.map((mapping, index) => {
                            return <SegmentToGroupMappingItem key={index}
                                                              mapping={mapping}
                                                              allGroups={allGroups}
                                                              callback={updateMapping}/>
                        })}
                    </div>
                    <Row>
                        <Col>
                            <Button variant={"neutral"} label={"Cancel"}
                                    className={"w-100"}
                                    onClick={close}/>
                        </Col>
                        <Col>
                            <Button variant={"primary"} label={"Add"} className={"w-100"} onClick={submit}/>
                        </Col>
                    </Row>
                </Modal.Body>
            </AppModal>
            <AddGroupModal show={showGroupCreator}
                           close={() => setShowGroupCreator(false)}
                           callback={() => groupsResult.rerun()}
                           callbackCancel={() => setShowGroupCreator(false)}/>
        </>

    )
}

interface Props {
    readonly show: boolean
    readonly segmentationJob: SegmentationJob
    readonly close: () => void

}

export default SegmentToGroupMappingModal