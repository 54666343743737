import {
    createUserWithEmailAndPassword,
    getAuth,
    sendEmailVerification,
    signInWithEmailAndPassword,
    UserCredential
} from "firebase/auth";
import {auth} from "../../utils/Firebase";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {useContext, useState} from 'react';
import {PasswordResetPagePath} from "./PasswordResetPage";
import {credentialsSchema, EmailAndPasswordCredentials} from "./PasswordSignInSchema";
import ZLink from "../../components/common/ZLink";
import {ToastContext} from "../../context/toasts/ToastManager";
import {fixUrl} from "../../utils/Utils";
import {getDefaultPageForAppMode} from "../../AppMode";


function UsernamePasswordSignin(props: Props) {

    const toastProps = useContext(ToastContext)

    const {isRegisterMode, toggleRegisterMode} = props
    const [showVerifyEmail, setShowVerifyEmail] = useState(false)

    async function handleSubmit(req: FormSubmitPayload<EmailAndPasswordCredentials>): Promise<boolean> {
        let userCredential: UserCredential
        try {
            userCredential = isRegisterMode ?
                await createUserWithEmailAndPassword(auth, req.value.email, req.value.password) :
                await signInWithEmailAndPassword(auth, req.value.email, req.value.password)
        } catch (error) {
            if (error instanceof Error) {
                if ("code" in error && error.code == "auth/email-already-in-use") {
                    return Promise.reject(new Error("This email is already registered."))
                }
                if ("code" in error && error.code == "auth/invalid-login-credentials") {
                    return Promise.reject(new Error("Invalid credentials."))
                }
            }
            console.error("Failed to sign in/register")
            console.error(error)
            return false
        }

        if (!userCredential.user.emailVerified) {
            setShowVerifyEmail(true)
            await sendEmailVerification(userCredential.user)
            return true
        }

        if (userCredential) {
            return await props.signInCallback(userCredential)
        }
        return false
    }

    async function resendVerificationEmail() {
        const auth = getAuth()
        await sendEmailVerification(auth.currentUser!, {url: fixUrl(getDefaultPageForAppMode())})
    }

    console.log(fixUrl("/test"))

    if (showVerifyEmail) {
        return (
            <div>
                <p>Please verify your email. A verification link should be in your inbox shortly.</p>
                <p>Don't see it? <a onClick={resendVerificationEmail}>Resend verification email.</a></p>
            </div>
        )
    }

    return (
        <div>
            <AppForm submitLabel={isRegisterMode ? "Register" : "Sign In"}
                     onSubmit={handleSubmit}
                     schema={credentialsSchema}
                     fields={[
                         {
                             fieldType: "text",
                             stringType: "email",
                             field: credentialsSchema.Email,
                         },
                         {
                             fieldType: "text",
                             stringType: "password",
                             field: credentialsSchema.Password,
                         }
                     ]}
            />
            <div className="m-3 border-bottom"/>
            <div className={"m-2 text-muted text-sm"}>
                Forgot your password? <ZLink href={PasswordResetPagePath}>Reset it here</ZLink>
            </div>
            <div className={"m-2 text-muted text-sm"}>
                or use another <a href={"#"} onClick={props.cancel}>{isRegisterMode ? "Sign Up" : "Sign In"} method</a>
            </div>
        </div>
    )
}

interface Props {
    readonly isRegisterMode: boolean,
    readonly toggleRegisterMode: (isRegisterMode: boolean) => void,
    readonly cancel: () => void,
    readonly signInCallback: (creds: UserCredential) => Promise<boolean>
}


export default UsernamePasswordSignin
