export interface IBaseModel {
}

export interface Model {
    // epoch milliseconds
    readonly created_at?: number;
    // epoch milliseconds
    readonly updated_at?: number;
}

export interface OrgModel extends Model {
    readonly org_id: OrgId;
}


// "branded types" prevent two type aliases that both resolve to strings from resolving to each other
export type OrgId = string & { __brand: "org_id" }

export function makeOrgId(id: string): OrgId {
    return id as OrgId
}

export interface IArchivable extends Model {
    readonly is_archived: boolean
}
