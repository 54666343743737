import {ExporterField} from "./EmailExporter";
import Form from "react-bootstrap/Form";
import * as React from "react";
import {ChangeEvent} from "react";
import IconButton from "../../common/IconButton";

function EmailExporterFieldItem(props: Props) {
    const {field, moveOrderCallback} = props;


    function setOutputName(e: ChangeEvent<HTMLInputElement>) {
        props.editCallback({
            ...field,
            outputName: e.target.value
        })
    }

    function setEnabled(e: ChangeEvent<HTMLInputElement>) {
        props.editCallback({
            ...field,
            enabled: e.target.checked
        })
    }

    return (
        <div className={"col-12 p-4 bg-light row border-bottom"}>
            <div className={(field.enabled ? "" : "text-muted") + " h5"}>{props.index}.  {field.name}</div>
            <div className={"col-6"}>
                <Form.Control value={field.outputName} onChange={setOutputName} disabled={!field.enabled}/>
            </div>
            <div className={"col-3"}>
                <Form.Check // prettier-ignore
                    type="switch"
                    id={`${field.name}-switch`}
                    checked={field.enabled}
                    onChange={setEnabled}
                    label="Enabled"
                />
            </div>
            <div className={"col-3"}>
                <IconButton className={"m-1"} icon={"caret-up"} label={""}
                            variant={"outline-primary"}
                            action={() => moveOrderCallback(field, -1)}/>
                <IconButton className={"m-1"} icon={"caret-down"} label={""}
                            variant={"outline-primary"}
                            action={() => moveOrderCallback(field, 1)}/>
            </div>
        </div>
    )
}

interface Props {
    readonly index: number
    readonly field: ExporterField
    readonly editCallback: (field: ExporterField) => void
    readonly moveOrderCallback: (field: ExporterField, delta: number) => void
}

export default EmailExporterFieldItem