import {OrgModel} from "./Model";
import {Organization} from "./Organization";
import {User} from "./User";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {Required} from "../validation/Validators";

export interface OrgMembership extends OrgModel {
    readonly user_id: string
    // last time the user signed in to this org
    readonly last_used_at: string
    // hydrated only
    readonly org?: Organization
    readonly user?: User
}

class OrgMembershipSchemaDef extends OrgModelSchema<OrgMembership> {
    get SchemaName(): string {
        return "OrgMembership"
    }

    getFriendlyName(value: OrgMembership): string {
        return value.org_id;
    }

    get apiResourceName(): string {
        return "orgMemberships";
    }

    get KeyColumns(): IModelField<any, OrgMembership>[] {
        return [this.OrgId, this.UserId];
    }

    public readonly UserId = new FieldClass<string, OrgMembership>({
        field_type: FieldType.TEXT,
        display_name: "User ID", name: "user_id", field_source: FieldSourceType.SYSTEM_DEFINED, rules: [
            new Required<OrgMembership>(),
        ]
    })

    public readonly LastUsedAt = new FieldClass<string, OrgMembership>({
        display_name: "Last Used",
        name: "last_used_at",
        field_type: FieldType.DATE_TIME,
        field_source: FieldSourceType.USER_DEFINED
    })
}

export const OrgMembershipSchema: OrgMembershipSchemaDef = new OrgMembershipSchemaDef()
