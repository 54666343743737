import {ApiResponse, getErrorOrNull, IGetApiService, Model} from "lib-shared";
import {ClientContext} from "lib-client";
import {ApiResult, doApiThing, RequestParams} from "./useApi";
import {AppClientContext} from "../utils/Utils";
import {toastIfError} from "../context/toasts/ToastManager";

export function useApiGet<T extends Model>(clientContext: AppClientContext | undefined, client: IGetApiService<any, ClientContext>, id: string | null | undefined, fullyHydrated: boolean = false, requestParams: RequestParams = {}): ApiResult<T> {

    // add id to prereqs because it shouldn't be null
    requestParams = {...requestParams, prereqs: [...(requestParams.prereqs ?? []), id ?? null]}
    return doApiThing<T>(clientContext, async (cc: ClientContext): Promise<ApiResponse<T> | null> => {
        if (id == null || id == "") {
            return null
        }

        return await client.get({id: id, fullyHydrated}, cc)
    }, requestParams, [id])
}


export function useApiGetV2<T extends Model>(clientContext: AppClientContext | undefined, client: IGetApiService<any, ClientContext>, id: string | null | undefined, fullyHydrated: boolean = false, requestParams: RequestParams = {}): ApiResult<T> {

    // add id to prereqs because it shouldn't be null
    requestParams = {...requestParams, prereqs: [...(requestParams.prereqs ?? []), id ?? null]}
    return doApiThing<T>(clientContext, async (cc: ClientContext): Promise<ApiResponse<T> | null> => {
        if (id == null || id == "") {
            return null
        }

        return await client.get({id: id, fullyHydrated}, cc)
    }, requestParams, [id])
}