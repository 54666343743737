import * as React from "react";
import {BaseFilterField, FilterOption} from "./BaseTable";
import OptionsDropdown, {LabelAndAction} from "../common/OptionsDropdown";

function TableFilterButton<TFilter extends BaseFilterField>(props: Props<TFilter>) {

    const actions: LabelAndAction[] = props.option.filters.map(f => {
        return {
            label: `${f.displayValue}`,
            action: () => props.addFilter(f)
        }
    })

    return (
        <OptionsDropdown id={props.option.name} actions={actions}
                         buttonContent={<><i className="bi bi-filter opacity-70 me-2"></i>{props.option.name}</>}
        />

    )
}

interface Props<TFilter extends BaseFilterField> {
    readonly addFilter: (filter: TFilter) => void
    readonly option: FilterOption<TFilter>
}

export default TableFilterButton