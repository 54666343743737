import usePaginatedCollection from "../../hooks/usePaginatedCollection";
import * as React from "react";
import {ReactNode, useContext, useEffect, useState} from "react";
import PaginationButtons from "./PaginationButtons";
import {
    arraysEqual,
    decodeFilterField,
    encodeFilterField,
    FieldClass,
    FieldType,
    FilterField,
    FilterOperator, getResultOrNull,
    IBaseModel, IModelField,
    ListPaginateOptions,
    OrderByDirection
} from "lib-shared";
import {eventBus} from "../../utils/EventBus";
import BaseTable, {
    BaseFilterField,
    BaseOrderBy,
    ColumnSize,
    ColumnSpec,
    OrderByState,
    TableCell,
    TableCellConfig,
    TableSettings
} from "./BaseTable";
import {useQueryStateArray} from "../../hooks/useQueryStateArray";
import {listAll} from "lib-client";
import * as Papa from "papaparse";
import {numberToShortNumWithTip} from "../../utils/ReactUtils";
import {AppProgressContext} from "../progressbar/AppProgressContext";
import {UserContext} from "../../context/UserContext";
import {IReadonlyClient} from "lib-client/dist/clients/BaseReadOnlyClient";
import {ToastContext} from "../../context/toasts/ToastManager";

// we aren't going to push pagination to url params because we use documents to paginate and those don't go into the url nicely
function ApiTable<T extends IBaseModel>(props: Props<T>) {
    const progressProps = useContext(AppProgressContext)
    const {clientContext} = useContext(UserContext);
    const {toast} = useContext(ToastContext);

    const defaultOrderBy: ApiOrderBy | undefined =
        props.tableSettings.columns.map(c => c.orderBy).find(c => c?.isDefaultOrderBy) ??
        props.tableSettings.columns.map(c => c.orderBy).find(c => c != undefined)

    const [autoRefresh, setAutoRefresh] = useState(true)
    const [showArchive, setShowArchive] = useState(false)

    const defaultOrderByState: OrderByState<ApiOrderBy> | undefined = defaultOrderBy ? {
        orderBy: defaultOrderBy,
        dir: defaultOrderBy?.defaultOrderByDir ?? "asc",
    } : undefined
    const [orderBy, setOrderByOption] = useState<OrderByState<ApiOrderBy> | undefined>(defaultOrderByState)


    let apiFilters: ApiFilter[]
    let setFilters: (value: ApiFilter[]) => void

    if (props.useQueryParamFilters) {
        const [filtersRaw, setFiltersRaw] = useQueryStateArray("filter")
        const filters = filtersRaw.map(fr => decodeFilterField(fr))
        const allFilters = props.tableSettings.filterOptions.flatMap(f => f.filters)
        apiFilters = filters.map(fr => convertFilterFieldToApiFilter(fr, allFilters))
        setFilters = (value: ApiFilter[]) => {
            const filters = value.flatMap(convertApiFilterToFilterField)
            setFiltersRaw(filters.map(encodeFilterField))
        }
    } else {
        [apiFilters, setFilters] = useState<ApiFilter[]>([])
    }

    const filters: FilterField[] = [...(props.listOptions?.filterFields ?? []), ...(apiFilters.flatMap(convertApiFilterToFilterField))]

    if (props.enableArchive) {
        filters.push({
            field: "is_archived",
            value: [showArchive ? "true" : "false"]
        })
    }

    const paginationResult = usePaginatedCollection<T>({
        client: props.client,
        orderBy,
        listOptions: {
            ...props.listOptions,
            filterFields: filters
        },
        defaultPageSize: 25,
        refreshIntervalMs: autoRefresh ? 10000 : undefined,
        admin: props.admin
    })

    const {data, loading} = paginationResult

    function reload() {
        console.log("reloading list...")
        paginationResult.reload()
    }

    // reload the list if an item-changed event is trigger - most likely the user just used a modal to edit an item from this list
    useEffect(() => {
        eventBus.addListener("item-changed", reload)
        // return a function to be called when component is unmounted
        return () => {
            eventBus.removeListener("item-changed", reload)
        }
    }, []);

    const loadingAll = (props.loadingDependencies ?? false) || loading

    async function exportCsv(columns: ColumnSpec<T, ApiOrderBy>[]) {
        const all = await listAll<T>(props.client, {
            orderBy: orderBy?.orderBy.value.name,
            orderByDirection: orderBy?.dir,
            filterFields: [
                ...apiFilters,
                ...(props.listOptions?.filterFields ?? [])
            ],
        }, clientContext, {addProgress: progressProps.addProgress})

        makeFile(getResultOrNull(all) ?? [], columns)
    }

    function makeFile(rows: T[], columns: ColumnSpec<T, ApiOrderBy>[]): void {

        if (rows.length == 0) {
            toast({
                title: `No Rows Found!`,
                icon: "warning",
                body: "No rows found to export."
            })
            return
        }

        const columnNames: string[] = columns.map(f => f.name)
        const values: string[][] = [columnNames, ...rows.map(row => {
            // todo handle react nodes
            return columns.map(f => f.cellExtractor(row).value?.toString() ?? "")
        })]

        const csvString = Papa.unparse(values, {
            quotes: false, //or array of booleans
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ",",
            newline: "\r\n",
        })

        const url = window.URL.createObjectURL(
            new Blob([csvString]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `zensend-export-${props.client.schema.SchemaName.toLowerCase()}.csv`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
    }


    return (
        <>
            <BaseTable<T, ApiFilter, ApiOrderBy> tableSettings={props.tableSettings}
                                                 data={data}
                                                 enableArchive={props.enableArchive ?? false}
                                                 showArchive={showArchive}
                                                 setShowArchive={setShowArchive}
                                                 filters={apiFilters}
                                                 loading={paginationResult.loading}
                                                 exportCsv={exportCsv}
                                                 setFilters={setFilters}
                                                 isAutoRefresh={autoRefresh}
                                                 setIsAutoRefresh={setAutoRefresh}
                                                 order={orderBy}
                                                 setOrder={setOrderByOption}/>
            {!loadingAll && data.length == 0 &&
                <div className={"text-center p-5 m-5"}>
                    {props.noDataMessage}
                </div>}
            <PaginationButtons paginationResult={paginationResult} buttons={props.buttons}/>
        </>
    )
}

interface Props<T extends IBaseModel> {
    readonly client: IReadonlyClient<T>
    readonly listOptions?: ListPaginateOptions
    readonly admin?: boolean
    readonly enableArchive?: boolean
    readonly buttons?: ReactNode[]
    readonly noDataMessage: string
    readonly useQueryParamFilters?: boolean
    readonly tableSettings: TableSettings<T, ApiFilter, ApiOrderBy>
    readonly loadingDependencies?: boolean
}

function convertApiFilterToFilterField(f: ApiFilter): FilterField {
    return {
        field: f.field,
        operator: f.operator,
        value: f.value
    }
}

function convertFilterFieldToApiFilter(f: FilterField, possibleOptions: ApiFilter[]): ApiFilter {
    for (let candidate of possibleOptions) {
        if (f.field == candidate.field && arraysEqual(f.value, candidate.value) && (f.operator ?? FilterOperator.EQUALS) == (candidate.operator ?? FilterOperator.EQUALS)) {
            return candidate
        }
    }
    return {
        field: f.field,
        displayName: f.field,
        displayValue: f.value.join(", "),
        operator: f.operator,
        value: f.value.map(v => v.toString()),
    }
}

export function createColumnFromNested<TModel, TModelInner, TValue>(field: FieldClass<TValue, TModelInner>, defaultColumn: boolean, extractor: (value: TModel) => TModelInner,
                                                                    config: CreateColumnConfig<TModelInner, TValue> | undefined = undefined): ColumnSpec<TModel, ApiOrderBy> {
    function innerCellExtractor(value: TModel): TableCell {
        const innerValue = extractor(value)
        return {
            // @ts-ignore
            value: innerValue[field.name],
            config: config?.cellConfig
        }
    }

    return {
        name: field.display_name,
        orderBy: {
            value: field,
        },
        defaultColumn: defaultColumn,
        cellExtractor: innerCellExtractor,
    }
}

export function createColumn<TModel, TValue>(field: FieldClass<TValue, TModel>, defaultColumn: boolean, config: CreateColumnConfig<TModel, TValue> | undefined = undefined): ColumnSpec<TModel, ApiOrderBy> {
    const orderBy: ApiOrderBy | undefined = (config?.orderByEnabled ?? true) ? {
        value: field,
        defaultOrderByDir: config?.defaultOrderByDir,
        isDefaultOrderBy: config?.isDefaultOrderBy,
    } : undefined

    return {
        name: field.display_name,
        orderBy,
        size: config?.size,
        defaultColumn: defaultColumn,
        cellExtractor: createCellExtractor(field, config),
    }
}

export interface CreateColumnConfig<TModel, TValue> {
    readonly cellConfig?: TableCellConfig
    readonly size?: ColumnSize
    readonly isDefaultOrderBy?: boolean,
    readonly defaultOrderByDir?: OrderByDirection,
    readonly orderByEnabled?: boolean
    // for html based display (optional)
    readonly displayValueFormatter?: (value: TValue, model: TModel) => ReactNode
    // for html display (if displayValue not defined) and CSV export
    readonly valueFormatter?: (value: TValue, model: TModel) => string
}

export function createCellExtractor<TModel, TValue>(field: FieldClass<TValue, TModel>, config: CreateColumnConfig<TModel, TValue> | undefined = undefined): (value: TModel) => TableCell {
    function result(rowValue: TModel): TableCell {
        // @ts-ignore
        let value = rowValue[field.name]


        if (config?.valueFormatter) {
            value = config.valueFormatter(value, rowValue)
        } else if (field.field_type == FieldType.DATE_TIME) {
            value = new Date(value).toLocaleDateString()
        } else if (typeof value === "number") {
            value = numberToShortNumWithTip(value)
        }


        return {
            value: value,
            config: config?.cellConfig
        }
    }

    return result
}

// todo use Model Schema Fields instead of strings
export interface ApiFilter extends BaseFilterField {
    field: string
    operator?: FilterOperator
    value: string[]
}

export interface ApiOrderBy extends BaseOrderBy {
    readonly value: FieldClass<any, any>,
    readonly defaultOrderByDir?: OrderByDirection,
    readonly isDefaultOrderBy?: boolean,
}

export default ApiTable