import {randomElement} from "lib-shared";

export function generateName() {
    const adjectives: string[] = [
        "Adorable",
        "Adventurous",
        "Aggressive",
        "Agreeable",
        "Alert",
        "Alive",
        "Amused",
        "Angry",
        "Annoyed",
        "Annoying",
        "Anxious",
        "Arrogant",
        "Ashamed",
        "Attractive",
        "Average",
        "Awful",
        "Bad",
        "Beautiful",
        "Better",
        "Bewildered",
        "Bloody",
        "Blushing",
        "Bored",
        "Brainy",
        "Brave",
        "Breakable",
        "Bright",
        "Busy",
        "Calm",
        "Careful",
        "Cautious",
        "Charming",
        "Cheerful",
        "Clean",
        "Clear",
        "Clever",
        "Cloudy",
        "Clumsy",
        "Colorful",
        "Combative",
        "Comfortable",
        "Concerned",
        "Condemned",
        "Confused",
        "Cooperative",
        "Courageous",
        "Crazy",
        "Creepy",
        "Crowded",
        "Cruel",
        "Curious",
        "Cute",
        "Dangerous",
        "Dark",
        "Dead",
        "Defeated",
        "Defiant",
        "Delightful",
        "Depressed",
        "Determined",
        "Different",
        "Difficult",
        "Disgusted",
        "Distinct",
        "Disturbed",
        "Dizzy",
        "Doubtful",
        "Drab",
        "Dull",
        "Easy",
        "Elated",
        "Elegant",
        "Embarrassed",
        "Enchanting",
        "Encouraging",
        "Energetic",
        "Enthusiastic",
        "Envious",
        "Evil",
        "Excited",
        "Expensive",
        "Exuberant",
        "Fair",
        "Faithful",
        "Famous",
        "Fancy",
        "Fantastic",
        "Fierce",
        "Filthy",
        "Fine",
        "Foolish",
        "Fragile",
        "Frail",
        "Frantic",
        "Friendly",
        "Frightened",
        "Funny",
        "Gentle",
        "Gifted",
        "Glamorous",
        "Gleaming",
        "Glorious",
        "Good",
        "Gorgeous",
        "Graceful",
        "Grieving",
        "Grotesque",
        "Grumpy",
        "Handsome",
        "Happy",
        "Healthy",
        "Helpful",
        "Helpless",
        "Hilarious",
        "Homeless",
        "Homely",
        "Horrible",
        "Hungry",
        "Hurt",
        "Ill",
        "Important",
        "Impossible",
        "Inexpensive",
        "Innocent",
        "Inquisitive",
        "Itchy",
        "Jealous",
        "Jittery",
        "Jolly",
        "Joyous",
        "Kind",
        "Lazy",
        "Light",
        "Lively",
        "Lonely",
        "Long",
        "Lovely",
        "Lucky",
        "Magnificent",
        "Meek",
        "Misty",
        "Modern",
        "Motionless",
        "Muddy",
        "Mushy",
        "Mysterious",
        "Nasty",
        "Naughty",
        "Nervous",
        "Nice",
        "Nutty",
        "Obedient",
        "Obnoxious",
        "Odd",
        "Old-fashioned",
        "Open",
        "Outrageous",
        "Outstanding",
        "Panicky",
        "Perfect",
        "Plain",
        "Pleasant",
        "Poised",
        "Poor",
        "Powerful",
        "Precious",
        "Prickly",
        "Proud",
        "Putrid",
        "Puzzled",
        "Quaint",
        "Real",
        "Relieved",
        "Repulsive",
        "Rich",
        "Scary",
        "Selfish",
        "Shiny",
        "Shy",
        "Silly",
        "Sleepy",
        "Smiling",
        "Smoggy",
        "Sore",
        "Sparkling",
        "Splendid",
        "Spotless",
        "Stormy",
        "Strange",
        "Stupid",
        "Successful",
        "Super",
    ]
    const colors: string[] = [
        "Amber",
        "Aqua",
        "Beige",
        "Blue",
        "Bronze",
        "Chartreuse",
        "Clay",
        "Cyan",
        "Dark Salmon",
        "Gainsboro",
        "Golden",
        "Grape",
        "Green",
        "Grey",
        "Indigo",
        "Lime",
        "Magenta",
        "Maroon",
        "Metallic",
        "Mint",
        "Mustard",
        "Navy Blue",
        "Neon Green",
        "Olive",
        "Orange",
        "Peru",
        "Pink",
        "Plum",
        "Purple",
        "Rose",
        "Ruby",
        "Rust",
        "Salmon",
        "Silver",
        "Snow",
        "Teal",
        "Turquoise",
        "Violet",
        "Wheat",
    ]
    const animals: string[] = [
        "Dog",
        "Cow",
        "Cat",
        "Horse",
        "Donkey",
        "Tiger",
        "Lion",
        "Panther",
        "Leopard",
        "Cheetah",
        "Bear",
        "Elephant",
        "Polar bear",
        "Turtle",
        "Tortoise",
        "Crocodile",
        "Rabbit",
        "Porcupine",
        "Hare",
        "Hen",
        "Pigeon",
        "Albatross",
        "Crow",
        "Fish",
        "Dolphin",
        "Frog",
        "Whale",
        "Alligator",
        "Eagle",
        "Squirrel",
        "Ostrich",
        "Fox",
        "Goat",
        "Jackal",
        "Emu",
        "Armadillo",
        "Eel",
        "Goose",
        "Arctic fox",
        "Wolf",
        "Beagle",
        "Gorilla",
        "Chimpanzee",
        "Monkey",
        "Beaver",
        "Orangutan",
        "Antelope",
        "Bat",
        "Badger",
        "Giraffe",
        "Hermit Crab",
        "Panda",
        "Hamster",
        "Cobra",
        "Hammerhead",
        "Camel",
        "Hawk",
        "Deer",
        "Chameleon",
        "Hippopotamus",
        "Jaguar",
        "Chihuahua",
        "Cobra",
        "Ibex",
        "Lizard",
        "Koala",
        "Kangaroo",
        "Iguana",
        "Llama",
        "Chinchillas",
        "Dodo",
        "Jellyfish",
        "Rhinoceros",
        "Hedgehog",
        "Zebra",
        "Possum",
        "Wombat",
        "Bison",
        "Bull",
        "Buffalo",
        "Sheep",
        "Meerkat",
        "Mouse",
        "Otter",
        "Sloth",
        "Owl",
        "Vulture",
        "Flamingo",
        "Racoon",
        "Mole",
        "Duck",
        "Swan",
        "Lynx",
        "Monitor",
        "Elk",
        "Boar",
        "Lemur",
        "Mule",
        "Baboon",
        "Mammoth",
        "Whale",
        "Rat",
        "Snake",
        "Peacock",
    ]

    const adjective = randomElement(adjectives)
    const color = randomElement(colors)
    const animal = randomElement(animals)

    return `${adjective} ${color} ${animal}`
}