import Form from "react-bootstrap/Form";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import LoadingIndicator from "../LoadingIndicator";
import Col from "react-bootstrap/Col";
import {ButtonVariant} from "../common/Button";
import {FieldType, FieldValidationResult, IModelSchema, KeyValue, ModelValidationResult, validate} from "lib-shared";
import FormText from "./FormText";
import FormSelect from "./FormSelect";
import {FormField} from "./FormFields";
import FormToggle from "./FormToggle";
import FormMultiToggle from "./FormMultiToggle";
import {ApiResult} from "../../hooks/useApi";


function AppForm<T>(props: Props<T>) {

    const [isLoadingInitialValue, setIsLoadingInitialValue] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSubmitOnce, setIsSubmitOnce] = useState(false)
    const isLoading = isSubmitting || props.loading || isLoadingInitialValue
    const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(null)

    const formRef = useRef<HTMLFormElement>(null);

    const [errors, setErrors] = useState<FieldValidationResult[]>([])

    function extractValueFromFormEvent(): T {
        let result: KeyValue<any> = {}

        // If we don't have an input field for this value we still need to pass things through like ID
        if (props.initialValue) {
            result = {...props.initialValue}
        } else if (props.initialValueLazy?.value) {
            result = {...props.initialValueLazy.value}
        }

        for (let field of props.schema.getFields()) {
            const formInput = formRef.current![field.name]

            if (!formInput) {
                if (field.field_type == FieldType.MULTI_SELECT) {
                    let values: string[] = []
                    for (let formFieldValue of Object.values(formRef.current!)) {
                        if (formFieldValue.name == `${field.name}[]` && formFieldValue.checked) {
                            values.push(formFieldValue.id)
                        }
                    }

                    result[field.name] = values
                }
                continue
            }

            let value = formInput.value

            if (field.field_type == FieldType.TOGGLE) {
                // checkboxes "value" is set to "on" when checked
                value = formInput.checked
            }

            result[field.name] = value
        }

        return result as T
    }

    function validateForm(updated: T): ModelValidationResult {
        const errs = validate(updated, props.schema)
        setErrors(errs.errors)
        return errs
    }

    // todo can we ditch this?
    useEffect(() => {
        const value = props.initialValue
        if (value) {
            console.log(`Form updating initial value ${JSON.stringify(value)}`)
            if (props.forceValidation) {
                validateForm(value)
            } else {
                setErrors([])
            }
        }
    }, [JSON.stringify(props.initialValue)])

    if (props.initialValueLazy && props.initialValue) {
        console.error("Don't provide both initialValueLazy and initialValue to forms")
    }

    useEffect(() => {
        const result = props.initialValueLazy

        if (!result || !result.value)
            return

        if (result.isLoading) {
            console.log(`Form loading initial value...`)
            setIsLoadingInitialValue(true)
        } else {
            console.log(`Form got initial value: ${JSON.stringify(result.value)}`)
            setIsLoadingInitialValue(false)
        }
    }, [props.initialValueLazy?.isLoading, props.initialValueLazy?.value])

    async function submitForm(e: React.FormEvent<HTMLFormElement> | null) {
        e?.preventDefault()
        await submitDirect(false)
    }

    async function submitDirect(altSubmitButtonClicked: boolean) {
        const value = extractValueFromFormEvent()

        if (!isSubmitOnce)
            setIsSubmitOnce(true)

        const validation = validateForm(value)

        if (!validation.isValid) {
            console.log(`Can't submit form because of ${validation.errors.length} errors`)
            for (let err of validation.errors) {
                console.log(`Error ${err.field}: ${err.error}`)
            }
            return
        }

        if (props.disableSubmit) {
            return
        }

        if (!props.onSubmit) {
            return
        }

        if (isLoading) {
            return
        }

        setIsSubmitting(true)
        try {
            console.log(`Submitting form value: ${JSON.stringify(value)}`)
            const success = await props.onSubmit({
                value: value,
                altSubmitButtonClicked
            })
        } catch (error) {
            if (error instanceof Error) {
                setSubmitErrorMessage(error.message)
            } else {
                setSubmitErrorMessage("Something went wrong.")
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    function getFormJsx(formField: FormField<T>, index: number): JSX.Element {
        const f = formField.field

        let value: any = undefined

        if (props.initialValueLazy && props.initialValueLazy.value) {
            // @ts-ignore
            value = props.initialValueLazy.value[formField.field.name]
            // console.log(`Using lazy value for ${f.name}: ${value}`)
        } else if (props.initialValue) {
            // @ts-ignore
            value = props.initialValue[formField.field.name]
            // console.log(`Using initial value for ${f.name}: ${value}`)
        }

        if (!value) {
            value = f.defaultValue
        }

        // only give errors if we've submitted once
        const errs = isSubmitOnce || props.forceValidation ? errors.filter(e => e.field == f.name) : []

        switch (formField.fieldType) {
            case "multi-select":
                return <FormMultiToggle<T> key={f.name}
                                           index={index}
                                           {...formField}
                                           tip={formField.tip}
                                           errors={errs}
                                           initialValue={value}
                                           disabled={isLoading || formField.disabled}/>
            case "toggle":
                return <FormToggle<T>
                    {...formField}
                    index={index}
                    key={f.name}
                    field={f}
                    errors={errs}
                    initialValue={value}
                />
            case "text":
                return <FormText<T> key={f.name}
                                    index={index}
                                    {...formField}
                                    defaultValue={formField.defaultValue}
                                    errors={errs}
                                    labelSubText={formField.labelSubText}
                                    tip={formField.tip}
                                    initialValue={value}
                                    disabled={isLoading || formField.disabled}/>
            case "select":
                return <FormSelect<T> key={f.name}
                                      index={index}
                                      {...formField}
                                      field={f}
                                      tip={formField.tip}
                                      errors={errs}
                                      initialValue={value}
                                      disabled={isLoading || formField.disabled}/>

        }
    }

    function formChanged(e: React.FormEvent<HTMLFormElement>) {
        const updated = extractValueFromFormEvent()
        const errs = validateForm(updated)

        if (props.onChange) {
            console.log(`AppForm onChange: ${JSON.stringify(updated)}`)
            props.onChange(updated, errs)
        }
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }

    let fieldIndex = 1
    return (
        <div>
            <Form onSubmit={submitForm} onChange={formChanged} ref={formRef}>
                {submitErrorMessage && <div className={"text-danger"}>{submitErrorMessage}</div>}
                {/*form fields*/}
                {
                    props.fields.map(row => {
                        if (Array.isArray(row)) {
                            return (
                                <div key={`row-${row.map(f => f.field.name).join("_")}`} className={"row"}>
                                    {row.map(r => {
                                        return <Col key={`col-${r.field.name}`}>{getFormJsx(r, fieldIndex++)}</Col>
                                    })}
                                </div>
                            )
                        } else {
                            return getFormJsx(row, fieldIndex++)
                        }
                    })
                }
                {/*submit buttons*/}
                <Row className={"mb-5"}></Row>
                <Row className={"mt-5"}>
                    {props.onCancel && <Col><Button type="button" size={"sm"} variant={"light"} onClick={props.onCancel}
                                                    className={"w-100"} disabled={isLoading}>Cancel</Button></Col>}
                    {props.altSubmitLabel != null && <Col>
                        <Button size={"sm"} disabled={isLoading || props.disableSubmit}
                                onClick={() => submitDirect(true)}
                                variant={"outline-primary"}
                                className={"w-100"}>{props.altSubmitLabel}</Button>
                    </Col>}
                    {props.onSubmit != null && <Col>
                        <Button type="submit" size={"sm"} disabled={isLoading || props.disableSubmit}
                                variant={props.variant}
                                className={"w-100"}>{props.submitLabel ?? "Save"}</Button>
                    </Col>}
                </Row>
            </Form>
        </div>
    )
}

export interface FormSubmitPayload<T> {
    value: T
    altSubmitButtonClicked: boolean
}

interface Props<T> {
    schema: IModelSchema<T>
    initialValue?: T | null
    initialValueLazy?: ApiResult<T>
    editId?: string
    forceValidation?: boolean
    fields: (FormField<T>[] | FormField<T>)[]
    submitLabel?: string
    altSubmitLabel?: string
    variant?: ButtonVariant
    disableSubmit?: boolean
    isValid?: boolean
    loading?: boolean
    onCancel?: () => void
    onChange?: (state: T, validation: ModelValidationResult) => void
    onSubmit: null | ((value: FormSubmitPayload<T>) => Promise<boolean>)
}

export default AppForm