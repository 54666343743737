import {ReactNode, useContext} from "react";
import AppProgressBar from "./AppProgressBar";
import {AppProgressContext} from "./AppProgressContext";

function ProgressWrapper(props: Props): React.JSX.Element {

    const progressProps = useContext(AppProgressContext)


    if (!progressProps.isActive || !progressProps.current) {
        return props.children
    }

    return <AppProgressBar progress={progressProps.progress} current={progressProps.current}/>
}

interface Props {
    children: React.JSX.Element
}

export default ProgressWrapper