import {useContext} from "react";
import {useParams} from "react-router-dom";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {UserContext} from "../../context/UserContext";
import {useApiGet} from "../../hooks/useApiGet";
import {isSuccess, SocialMediaPlatform, SocialMediaProfile, TikTokPost, TikTokPostSchema} from "lib-shared";
import {SocialMediaProfilesClient, TikTokPostsClient} from "lib-client";
import ApiTable from "../../components/tables/ApiTable";
import ApiResultBlock from "../../components/ApiResultBlock";
import {Col, Row} from "react-bootstrap";
import SimpleTextStat from "../../components/SimpleTextStat";
import {createPostPageLink} from "./TikTokPostPage";
import {useAppNav} from "../../hooks/useAppNav";
import {createSocialPostsColumns} from "./TikTokPostsPage";
import IconButton from "../../components/common/IconButton";
import {toastIfError, toastIfErrors, toastResult} from "../../context/toasts/ToastManager";
import {TableAction} from "../../components/tables/TableActions";
import {AppClientContext, fixHref} from "../../utils/Utils";
import MaterialIcon from "../../components/common/MaterialIcon";
import ProfileAvatarIcon from "../../components/common/ProfileAvatarIcon";

function SocialMediaProfilePage() {

    const nav = useAppNav()
    const {clientContext} = useContext(UserContext)
    const params = useParams()
    const username = params["username"]

    const profile = useApiGet<SocialMediaProfile>(clientContext, SocialMediaProfilesClient.defaultClient, username)

    function rowClicked(post: TikTokPost) {
        nav(createPostPageLink({...post, platform: SocialMediaPlatform.TIKTOK}))
    }

    async function refresh(): Promise<void> {
        const result = await SocialMediaProfilesClient.defaultClient.refresh({
            platform: profile.value!.platform,
            username: username!
        }, clientContext)
        toastResult(result, clientContext)
        if (isSuccess(result))
            profile.rerun()
    }

    async function loadMorePosts(): Promise<void> {
        const result = await TikTokPostsClient.defaultClient.loadMorePosts({
            profile: {
                platform: SocialMediaPlatform.TIKTOK,
                username: username!
            }
        }, clientContext)
        toastIfError(clientContext, result)
        if (isSuccess(result)) {
            clientContext.toastProps.toast({
                title: "Success!",
                body: "Successfully added posts."
            })
        }
    }

    function actionCreator(posts: TikTokPost[]): TableAction[] {
        return createPostsActions(posts, clientContext)
    }

    return (
        <PowerPageContent title={profile.value?.username ?? ""}
                          icon={"tiktok"}
                          buttons={[
                              <IconButton key={"load-more-posts"} action={loadMorePosts}
                                          icon={"plus-circle"}
                                          label={"Load More Posts"}/>,
                              <IconButton key={"refresh"} action={refresh}
                                          icon={"arrow-clockwise"}
                                          label={"Refresh"}/>

                          ]}>
            <ApiResultBlock result={profile}>
                {(value) =>
                    (<>
                            <div className={"d-flex"}>
                                <div className={"flex-column"}>
                                    <a href={`https://tiktok.com/${username}`} target={"_blank"}>
                                        <ProfileAvatarIcon src={value.avatar_uri} style={"rounded-circle"}/>
                                    </a>
                                </div>
                                <div className={"flex-column m-3"}>
                                    <h3>{value.username}</h3>
                                    <div>{value.nickname}</div>
                                </div>
                            </div>
                            <div className={"m-3"}>{value.signature}</div>
                            <Row className={"mt-3"}>
                                <Col><SimpleTextStat value={value.digg_count} label={"diggs"}/></Col>
                                <Col><SimpleTextStat value={value.heart_count} label={"likes"}/></Col>
                                <Col><SimpleTextStat value={value.follower_count} label={"followers"}/></Col>
                            </Row>
                            <Row>
                                <Col><SimpleTextStat value={value.video_count} label={"posts"}/></Col>
                                <Col><SimpleTextStat value={value.following_count} label={"following"}/></Col>
                                <Col><SimpleTextStat value={new Date(value.profile_create_time)}
                                                     label={"joined"}/></Col>
                            </Row>
                        </>
                    )}
            </ApiResultBlock>
            <h3 className={"mt-5"}>Posts</h3>
            <ApiTable<TikTokPost> client={TikTokPostsClient.defaultClient}
                                  noDataMessage={"No Posts."}
                                  listOptions={{
                                      filterFields: [
                                          {
                                              field: TikTokPostSchema.Username.name,
                                              value: [username!]
                                          },
                                      ]
                                  }}
                                  tableSettings={{
                                      idExtractor: (post: TikTokPost) => `${post.username}#${post.post_id}`,
                                      filterOptions: [],
                                      rowClickCallback: rowClicked,
                                      columns: createSocialPostsColumns(false),
                                      actionCreator
                                  }}
            />
        </PowerPageContent>
    )
}

export function createPostsActions(posts: TikTokPost[], clientContext: AppClientContext): TableAction[] {
    return [
        {
            icon: "cpu",
            label: "Analyze",
            enabled: posts.every(c => !c.video_summary && c.video_uri),
            supportsMultiRows: true,
            action: async () => {
                const results = await Promise.all(posts.map(p => TikTokPostsClient.defaultClient.enrichPost({
                    ...p,
                    platform: SocialMediaPlatform.TIKTOK
                }, clientContext)))
                toastIfErrors(results, clientContext)
                const anyErrors = results.filter(r => !isSuccess(r)).length
                if (anyErrors == 0) {
                    clientContext.toastProps.toast({
                        title: "Success!",
                        body: "Successfully added posts."
                    })
                }
            },
        },
    ];
}

export function makeProfileLink(profile: SocialMediaProfile | null): string {
    return fixHref(`/social/profiles/${profile?.platform}/${profile?.username}`)
}


export default SocialMediaProfilePage