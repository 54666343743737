import {ReactNode} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Placement} from "react-bootstrap/esm/types";
import {OverlayInjectedProps} from "react-bootstrap/Overlay";


function InfoTip(props: Props) {
    const {children} = props

    function renderTooltip(props: OverlayInjectedProps): ReactNode {
        return (
            <Tooltip id="button-tooltip" {...props}>
                {children}
            </Tooltip>
        )
    }

    if (props.children == "" || props.children == undefined) {
        return <></>
    }


    return (
        <OverlayTrigger
            placement={props.placement ?? "right"}
            defaultShow={false}
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip}
        >
            <i className="bi bi-info-circle ms-2 me-2"></i>
        </OverlayTrigger>
    );
}

interface Props {

    readonly placement?: Placement;
    readonly children: string | ReactNode
}

export default InfoTip