import * as React from "react";
import {lerpColors} from "../../utils/Utils";

function MessageLengthRatingBar(props: Props) {

    const {minLength, maxLength} = props;
    const message = props.message ?? ""

    const rating01 = Math.min(1, Math.max(0, message.length / (minLength * 1.3)))

    let ratingString = "Perfect 👍"
    if (message.length < minLength)
        ratingString = "Too short 👎"
    else if (message.length > maxLength)
        ratingString = "Too long ✋"

    const ratingColor = lerpColors(rating01, ["#be4a4a", "#f3e935", "#48ab41"])

    return (
        <>
            <div style={{color: ratingColor}}>{ratingString}</div>
            <div className="progress" style={{height: "10px"}}>
                <div role="progressbar" className="progress-bar" aria-valuenow={rating01}
                     aria-valuemin={0}
                     aria-valuemax={1}
                     style={{width: `${rating01 * 100}%`, backgroundColor: ratingColor}}/>
            </div>
        </>
    )
}

interface Props {
    readonly message: string | undefined
    readonly minLength: number
    readonly maxLength: number
}

export default MessageLengthRatingBar