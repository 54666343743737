import {BaseCrudClient, ClientContext} from "lib-client";
import {TableAction} from "./TableActions";
import {IArchivable, Model} from "lib-shared";
import {ConfirmModalProps, Resource} from "../../context/confirmModal/ConfirmModalContext";
import {eventBus} from "../../utils/EventBus";

export function makeArchiveActions<T extends Model & IArchivable>(selectedItems: T[], confirmProps: ConfirmModalProps, client: BaseCrudClient<T>, clientContext: ClientContext): TableAction[] {
    const resources: Resource[] = selectedItems.map(item => {
        return {
            id: client.schema.getPrincipalId(item),
            name: client.schema.getFriendlyName(item)
        }
    })
    return [
        {
            icon: "archive",
            label: "Archive",
            color: "danger",
            enabled: selectedItems.every(c => !c.is_archived),
            supportsMultiRows: true,
            action: () => {
                confirmProps.confirm({
                    action_type: "archive",
                    resources: resources,
                    client: client,
                });
            },
        },
        {
            icon: "archive",
            label: "Un Archive",
            enabled: selectedItems.every(c => c.is_archived),
            supportsMultiRows: true,
            action: async () => {
                await Promise.all(resources.map(item => client.unarchive({id: item.id}, clientContext)))
                eventBus.trigger("item-changed", selectedItems[0])
            },
        }
    ]
}