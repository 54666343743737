import {ColorType, colorTypeToClass} from "../../utils/Utils";
import MaterialIcon from "../common/MaterialIcon";

function SlimAttribute(props: Props) {

    return (
        <div className={`${colorTypeToClass(props.color)} m-3`}>
            {!props.spinner && <MaterialIcon icon={props.icon} margin={2}/>}
            {props.spinner && <div className="spinner-border spinner-border-sm me-2" role="status"><span className="visually-hidden">Loading...</span></div>}
            {props.label}
        </div>
    )
}

interface Props {
    readonly label: string
    readonly color?: ColorType
    readonly spinner?: boolean
    readonly icon: string
}

export default SlimAttribute