import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./context/UserContext";
import ToastManager from "./context/toasts/ToastManager";
import { OrgProvider } from "./context/OrgContext";
import MultiModalManager from "./context/MultiModalManager";
import ConfirmModalManager from "./context/confirmModal/ConfirmModalContext";
import { AppProgressProvider } from "./components/progressbar/AppProgressContext";
import ProgressWrapper from "./components/progressbar/ProgressWrapper";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <StrictMode>
    <ToastManager>
      <MultiModalManager>
        <AuthProvider>
          <OrgProvider>
            <ConfirmModalManager>
              <AppProgressProvider>
                <App />
              </AppProgressProvider>
            </ConfirmModalManager>
          </OrgProvider>
        </AuthProvider>
      </MultiModalManager>
    </ToastManager>
  </StrictMode>,
);
