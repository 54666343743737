import {
    ApiResponse,
    BaseModelSchema,
    CountItemsOptions,
    ICountApiService,
    IListApiService,
    ListPaginateOptions,
    OrgMembership, OrgMembershipSchema
} from "lib-shared";
import {ClientContext, OrgMembershipsClient} from "lib-client";
import {IReadonlyClient} from "lib-client/dist/clients/BaseReadOnlyClient";

export class MyOrgMembershipsListClient implements IReadonlyClient<OrgMembership> {
    public static readonly defaultClient: MyOrgMembershipsListClient = new MyOrgMembershipsListClient()

    get schema(): BaseModelSchema<OrgMembership> {
        return OrgMembershipSchema
    }

    list(req: ListPaginateOptions, ctx: ClientContext): Promise<ApiResponse<OrgMembership[]>> {
        return OrgMembershipsClient.defaultClient.listMyMemberships(req, ctx)
    }

    count(req: CountItemsOptions, ctx: ClientContext): Promise<ApiResponse<number>> {
        // todo this is wrong
        return OrgMembershipsClient.defaultClient.count(req, ctx)
    }
}