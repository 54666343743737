import {EmailProvider, getResultOrNull, SenderEmailProviderType, SenderProfile, SenderProfileSchema} from "lib-shared";
import * as React from "react";
import {useContext, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {EmailProvidersClient, SenderProfilesClient} from "lib-client";
import {UserContext} from "../../context/UserContext";
import {eventBus} from "../../utils/EventBus";
import {useApiList} from "../../hooks/useApiList";
import AppForm, {FormSubmitPayload} from "../forms/AppForm";
import EmailProviderEditorModal from "../../routes/settings/EmailProviderEditorModal";
import AppModal from "../AppModal";
import {useApiGet} from "../../hooks/useApiGet";
import {FormValueSelectItem} from "../forms/FormFields";
import {v4 as uuidv4} from "uuid"
import {toastIfError} from "../../context/toasts/ToastManager";

export const SenderProfileEditorKey = "sender_profile_id"

function SenderProfileEditor(props: Props) {
    const {clientContext, appUser} = useContext(UserContext);
    const {editId} = props
    const isCreate: boolean = editId == ""

    const [showEmailProvider, setShowEmailProvider] = useState(false)
    const providers = useApiList<EmailProvider>(clientContext, EmailProvidersClient.defaultClient, {}, {})

    const initialProfile = useApiGet<SenderProfile>(clientContext, SenderProfilesClient.defaultClient, editId)

    async function Create(payload: FormSubmitPayload<SenderProfile>) {
        const emailProvider = providers.value!.find(i => i.email_provider_id == payload.value.email_provider_id) ?? null

        const profile: SenderProfile = {
            ...payload.value,
            sender_profile_id: payload.value.sender_profile_id || uuidv4(),
            email_provider_type: emailProvider == null ? SenderEmailProviderType.Manual : emailProvider.email_provider_type,
            email_provider_id: emailProvider?.email_provider_id ?? null,
        }
        const response = await new SenderProfilesClient().createOrUpdate(profile, isCreate, clientContext)


        const value = getResultOrNull(response)
        if (!value) {
            toastIfError(clientContext, response)
            return false
        }

        if (props.callback)
            props.callback(value)

        close()

        eventBus.trigger("item-changed", profile)
        return true
    }

    function close() {
        props.close()
    }

    const providerOptions: FormValueSelectItem[] = [
        // put this first because updating an existing Sender Profiles will have this value as null if they're manual and having this as first will cause it to be chosen for "null"
        {id: SenderEmailProviderType.Manual, label: "Manual (CSV Export)"}, ...providers.value?.map(p => {
            return {
                id: p.email_provider_id,
                label: `${p.email ?? "New Email Provider"} (${p.email_provider_type})`
            }
        }) ?? []]

    return (
        <>
            <AppModal show={props.show} onHide={close} name={"sender-profile-editor"}>
                <Modal.Header closeButton>
                    <Modal.Title>{isCreate ? "Create" : "Edit"} Sender Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AppForm onSubmit={Create}
                             onCancel={close}
                             schema={SenderProfileSchema}
                             initialValueLazy={initialProfile}
                             fields={[
                                 [
                                     {
                                         fieldType: "text",
                                         field: SenderProfileSchema.FirstName,
                                     },
                                     {
                                         fieldType: "text",
                                         field: SenderProfileSchema.LastName,
                                     }
                                 ],
                                 {
                                     fieldType: "text",
                                     field: SenderProfileSchema.Email,
                                 },
                                 {
                                     fieldType: "text",
                                     field: SenderProfileSchema.CompanyName,
                                 },
                                 {
                                     fieldType: "text",
                                     field: SenderProfileSchema.JobTitle,
                                 },
                                 {
                                     fieldType: "select",
                                     field: SenderProfileSchema.EmailProviderId,
                                     options: providerOptions,
                                     buttonProps: {
                                         variant: "primary",
                                         onClick: () => setShowEmailProvider(true),
                                         icon: "plus",
                                         label: ""
                                     }
                                 }
                             ]}/>
                </Modal.Body>
            </AppModal>
            <EmailProviderEditorModal followAppRedirect={false} show={showEmailProvider}
                                      close={() => setShowEmailProvider(false)}
                                      callback={(provider: EmailProvider) => {
                                          if (provider != null)
                                              providers.rerun()
                                      }}
            />
        </>

    )
}

interface Props {
    show: boolean
    editId: string
    close: () => void
    callback?: (profile: SenderProfile) => void
}

export default SenderProfileEditor