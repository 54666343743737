import {useParams} from "react-router-dom";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {Campaign, Draft, DraftSchema} from "lib-shared";
import {useApiGet} from "../../hooks/useApiGet";
import {UserContext} from "../../context/UserContext";
import {useContext} from "react";
import {getCampaignOutboxLink} from "./Outbox";
import {useAppNav} from "../../hooks/useAppNav";
import EmailEditor from "../../components/email/EmailEditor";
import LeadCard from "./LeadCard";
import {DraftsClient} from "lib-client";

function DraftEditorPage() {
    const nav = useAppNav()
    const {clientContext} = useContext(UserContext)

    const params = useParams();
    const draftId = params[DraftSchema.DraftId.name]!

    const draftResponse = useApiGet<Draft>(clientContext, DraftsClient.defaultClient, draftId, true)


    const campaign: Campaign | null = draftResponse.value?.campaign ?? null
    const draft: Draft | null = draftResponse.value

    function closeEmail() {
        nav(getCampaignOutboxLink(campaign))
    }

    return (
        <PowerPageContent title={`${draft?.lead?.email ?? ""} Draft`}
                          parentHref={getCampaignOutboxLink(campaign)}
                          buttons={[]}>
            <div className={"row"}>
                <div className={"col-xs-12 col-md-6"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <EmailEditor draft_id={draftId} close={closeEmail}/>
                        </div>
                    </div>
                </div>
                <div className={"col-xs-12 col-md-6"}>
                    <LeadCard lead={draftResponse.value?.lead ?? null}/>
                </div>
            </div>
        </PowerPageContent>
    )
}

export function linkDraftEditor(draft: Draft) {
    return `/outbox/draft/${draft.draft_id}`
}

export default DraftEditorPage