import {AppNavOptions, fixLink} from "../utils/Utils";
import {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {OrgContext} from "../context/OrgContext";

export function useAppNav(): AppNavFunction {
    const {org} = useContext(OrgContext);
    const nav = useNavigate()

    return (path: string, opts?: AppNavOptions) => {
        const {pathname, search} = fixLink(path, org, opts)
        nav({
            pathname,
            search,
        })
    }
}



export type AppNavFunction = (path: string, opts?: AppNavOptions) => void