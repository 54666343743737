import {Campaign, CampaignSchema, FilterOperator, isSuccess, Lead, LeadSchema, niceLeadString} from "lib-shared";
import * as React from "react";
import {useContext, useState} from "react";
import {UserContext} from "../../../context/UserContext";
import EditLeadModal from "../EditLeadModal";
import IconButton from "../../../components/common/IconButton";
import {ColumnSpec, FilterOption} from "../../../components/tables/BaseTable";
import PowerPageContent from "../../../components/powerPage/PowerPageContent";
import ListPickerModal from "../../../components/listPicker/ListPickerModal";
import {CampaignsClient, LeadsClient} from "lib-client";
import {useAppNav} from "../../../hooks/useAppNav";
import {ConfirmModalContext} from "../../../context/confirmModal/ConfirmModalContext";
import {useQueryState} from "../../../hooks/useQueryParam";
import ApiTable, {ApiFilter, ApiOrderBy, createColumn} from "../../../components/tables/ApiTable";
import {TableAction} from "../../../components/tables/TableActions";
import {toastIfError, toastIfErrors} from "../../../context/toasts/ToastManager";
import NotSummarizedMetric from "../NotSummarizedMetric";
import SpinnerOrSuccess from "../../../components/common/SpinnerOrSuccess";

export const LeadEditorKey = "lead_id"

function LeadsPage() {
    const {clientContext} = useContext(UserContext);
    const navigate = useAppNav()
    const {confirm} = useContext(ConfirmModalContext);

    const [editId, setEditId] = useQueryState(LeadEditorKey);

    const [sendLeadIds, setSendLeadIds] = useState<string[]>([]);

    function addLead() {
        setEditId("")
    }

    const columns: ColumnSpec<Lead, ApiOrderBy>[] = [
        createColumn(LeadSchema.LastName, true),
        createColumn(LeadSchema.FirstName, true),
        createColumn(LeadSchema.Email, true),
        {
            name: "Summarized & Researched",
            defaultColumn: true,
            cellExtractor: lead => {
                const isSummarized = lead.text_summary != null
                const isResearched = lead.is_researched
                return {
                    value: (isSummarized && isResearched).toString(),
                    displayValue: <SpinnerOrSuccess
                        statuses={[isSummarized, isResearched]}/>
                }
            },
        },
        createColumn(LeadSchema.UpdatedAt, true),
        createColumn(LeadSchema.CreatedAt, false),
    ]


    function actionCreator(leads: Lead[]): TableAction[] {
        return [
            {
                icon: "pencil",
                enabled: leads[0].is_researched,
                label: "Edit",
                action: () => setEditId(leads[0].lead_id)
            },
            {
                icon: "send",
                supportsMultiRows: true,
                enabled: leads.every(l => l.is_researched),
                label: "Send To Campaign",
                action: () => setSendLeadIds(leads.map(l => l.lead_id))
            },
            {
                icon: "arrow-clockwise",
                supportsMultiRows: true,
                enabled: leads.every(l => l.is_researched),
                label: "Re-summarize",
                action: async () => {
                    const client = new LeadsClient()
                    const results = await Promise.all(leads.map(l => client.summarizeLead({id: l.lead_id}, clientContext)))
                    toastIfErrors(results, clientContext)
                }
            },
            {
                icon: "trash",
                label: "Delete",
                color: "danger",
                supportsMultiRows: true,
                action: () => {
                    confirm({
                        client: LeadsClient.defaultClient,
                        action_type: "delete",
                        resources: leads.map(l => {
                            return {id: l.lead_id, name: niceLeadString(l)}
                        }),
                    })
                }
            }
        ]
    }

    return (
        <PowerPageContent title={"Leads"} buttons={[
            <IconButton key={"upload"} action={() => navigate("/leads/bulkAdd")} variant={"outline-primary"}
                        icon={"upload"}
                        label={"Bulk Upload"}/>,
            <IconButton key={"add"} action={addLead} icon={"plus-circle"} label={"New Lead"}/>,
        ]}>
            <NotSummarizedMetric group_id={null}/>
            <ApiTable<Lead> client={LeadsClient.defaultClient}
                            noDataMessage={"No Leads yet."}
                            tableSettings={{
                                columns: columns,
                                idExtractor: lead => lead.lead_id,
                                filterOptions: LeadFilters,
                                actionCreator,
                                rowClickCallback: (lead: Lead) => setEditId(lead.lead_id)
                            }}
            />

            <ListPickerModal<Campaign> show={sendLeadIds.length > 0}
                                       title={"Pick a Campaign"}
                                       description={"Pick a campaign to send this lead to."}
                                       client={CampaignsClient.defaultClient}
                                       filters={[{
                                           field: CampaignSchema.State.name,
                                           value: ["launched"]
                                       }]}
                                       getId={c => c.campaign_id}
                                       mapper={c => c.name}
                                       callback={async (campaign: Campaign | null) => {
                                           if (!campaign) {
                                               setSendLeadIds([])
                                               return
                                           }
                                           const result = await new CampaignsClient().addLeads({
                                               campaign_id: campaign.campaign_id,
                                               type: "lead_ids",
                                               values: sendLeadIds
                                           }, clientContext)
                                           toastIfError(clientContext, result)
                                           if (isSuccess(result))
                                               setSendLeadIds([])
                                       }}/>


            {editId != null && <EditLeadModal leadId={editId} close={() => setEditId(null)}/>}
        </PowerPageContent>
    )
}

export function linkToLeadEditor(lead: Lead) {
    return `/leads?${LeadEditorKey}=${lead.lead_id}`
}

export const LeadFilters: FilterOption<ApiFilter>[] = [
    {
        name: "Summarized",
        filters: [
            {
                displayName: "Summarized",
                displayValue: "true",
                field: "text_summary",
                isDefault: false,
                operator: FilterOperator.NOT_NULL,
                value: []
            },
            {
                displayName: "Summarized",
                displayValue: "false",
                field: "text_summary",
                isDefault: false,
                operator: FilterOperator.IS_NULL,
                value: []
            },
        ]
    }
]

export default LeadsPage