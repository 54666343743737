import Button from "../common/Button";
import MaterialIcon from "../common/MaterialIcon";
import {useAppNav} from "../../hooks/useAppNav";
import {ColorType} from "../../utils/Utils";

function ChecklistWidgetRow(props: Props) {
    const navigate = useAppNav()

    let color: ColorType | undefined = undefined
    let icon: string = "circle"

    switch (props.state) {
        case "Active":
            icon = "circle"
            break;
        case "Done":
            icon = "check-circle-fill"
            color = "success"
            break;
        case "Locked":
            icon = "lock-fill"
            color = "muted"
            break;
    }


    return (
        <div className="list-group-item py-3">
            <div className="d-flex g-3 align-items-center">
                <div className="flex-grow-1 d-flex">
                    <MaterialIcon icon={icon} color={color}/>
                    <div className="ms-2 text-center align-text-bottom">
                        {props.state == "Done" ? <span><s>{props.label}</s></span> :
                            <span className={props.state == "Locked" ? "text-muted" : ""}>{props.label}</span>}
                    </div>
                </div>
                <div className="ms-sm-auto">
                    {(props.state == "Active" || props.state == "Locked") &&
                        <Button variant={"outline-primary"} label={"Create"} disabled={props.state == "Locked"}
                                onClick={() => navigate(props.href)}/>}
                </div>
            </div>
        </div>
    )
}

interface Props {
    label: string
    state: ChecklistState
    href: string
}

export type ChecklistState = "Active" | "Done" | "Locked"

export default ChecklistWidgetRow