import {useEffect, useState} from "react";
import {ClientContext} from "lib-client";
import {v4 as uuidv4} from "uuid"
import {ApiResponse, getErrorOrNull, getResultOrNull} from "lib-shared";
import {toastIfError} from "../context/toasts/ToastManager";
import {AppClientContext} from "../utils/Utils";

// we take clientContext as an arg to prevent a circular dependency since this is used by UserContext
export function useApi<T>(clientContext: AppClientContext | undefined, req: (cc: ClientContext) => Promise<ApiResponse<T>>, deps: any[]): ApiResult<T> {
    return doApiThing<T>(clientContext, async (cc) => {
        const response = await req(cc)
        toastIfError(clientContext, response)
        return response
    }, {}, deps)
}

export function doApiThing<T>(clientContext: AppClientContext | undefined, req: (cc: ClientContext) => Promise<ApiResponse<T> | null>, requestParams: RequestParams, deps: any[]): ApiResult<T> {
    const [value, setValue] = useState<T | null>(null)
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [rerunDep, setRerunDep] = useState("")

    const anyPrereqsNull: boolean = (requestParams.prereqs ?? []).findIndex(x => x == null || x == "") != -1

    useEffect(() => {
        let didCancel = false;

        if (anyPrereqsNull) {
            setLoading(true)
            setValue(null)
            return
        }

        if (!clientContext)
            return

        if (clientContext.loadingFirebase) {
            return
        } else if (!clientContext.user) {
            setLoading(false)
            setValue(null)
            return
        }

        setLoading(true)

        req(clientContext)
            .then(response => {
                if (didCancel) {
                    console.log('Skipping cancelled request')
                    return
                }

                if (response == null) {
                    return
                }

                const error = getErrorOrNull(response)
                if (error) {
                    setValue(null)
                    toastIfError(clientContext, response)
                    setError(error.message)
                    console.error(error)
                } else {
                    const value = getResultOrNull(response)
                    setValue(value)
                    setError(null)
                }

                setLoading(false)
            })
            .catch((e) => {
                if (e instanceof Error) {
                    console.error(e)
                    setError(e.message)
                } else {
                    console.error("Failed to use api")
                    setError("An error occurred!")
                }
                setLoading(false)
                setValue(null)
            })


        return () => {
            // if this is called then we're making another request so don't use this result - that'd be a race condition
            didCancel = true;
        };
    }, [clientContext?.user?.uid, rerunDep, clientContext?.loadingFirebase, ...deps, anyPrereqsNull])

    const rerun = () => {
        setRerunDep(uuidv4())
    }

    // don't refresh if page isn't focused because it's wasteful
    const rerunIfFocused = () => {
        if (document.hasFocus()) {
            rerun()
        }
    }

    useEffect(() => {
        if (requestParams.refreshIntervalMs === undefined)
            return

        if (requestParams.refreshIntervalMs < 5000) {
            console.log("Too frequent refresh interval")
            return
        }

        const interval = setInterval(rerunIfFocused, 10000);

        return () => clearInterval(interval);
    }, [requestParams.refreshIntervalMs]);


    return {
        value,
        isLoading,
        error: error,
        rerun
    }
}

export interface ApiResult<T> {
    readonly value: T | null
    readonly isLoading: boolean
    readonly error: string | null
    readonly rerun: () => void
}

export interface RequestParams {
    // if any of these are null the request shouldn't fire
    readonly prereqs?: any[]
    readonly refreshIntervalMs?: number
}