import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {IModelField, ModelSchema} from "lib-shared";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import {EmailSignInField} from "./PasswordSignInSchema";
import {Row} from "react-bootstrap";
import {SignInPath} from "./SignInPage";
import {useAppNav} from "../../hooks/useAppNav";
import {useState} from "react";

// todo set a "continue URL"
// https://firebase.google.com/docs/auth/web/manage-users?sjid=6133996345188357324-NA#send_a_password_reset_email
function PasswordResetPage() {
    const navigate = useAppNav()

    const [resetRequestSubmitted, setResetRequestSubmitted] = useState(false)

    async function submit(req: FormSubmitPayload<PasswordReset>): Promise<boolean> {
        const auth = getAuth();
        await sendPasswordResetEmail(auth, req.value.email)
        setResetRequestSubmitted(true)
        return true
    }

    if (resetRequestSubmitted) {
        return (
            <div className="mx-auto">
                <Row>
                    <h3 className={"text-center"}>Reset Password</h3>
                    <p className={"text-muted text-sm text-center m-2"}>An email will be sent to the address shortly
                        with instructions on how to reset your password.</p>
                </Row>
            </div>
        )
    }

    return (
        <div className="mx-auto">
            <Row>
                <h3 className={"text-center"}>Reset Password</h3>
                <p className={"text-muted text-sm text-center m-2"}>Enter the email associated with your account to
                    reset your password.</p>
            </Row>
            <AppForm onSubmit={submit}
                     submitLabel={"Reset"}
                     onCancel={() => navigate(SignInPath)}
                     schema={PasswordResetSchema}
                     fields={[
                         {
                             fieldType: "text",
                             stringType: "email",
                             field: PasswordResetSchema.Email,
                         }
                     ]}
            />
        </div>

    )
}

interface PasswordReset {
    readonly email: string
}

class PasswordResetSchemaDef extends ModelSchema<PasswordReset> {
    get apiResourceName(): string {
        throw new Error('Method not implemented.');
    }

    get KeyColumns(): IModelField<any, PasswordReset>[] {
        throw new Error('Method not implemented.');
    }

    getFriendlyName(value: PasswordReset): string {
        throw new Error('Method not implemented.');
    }

    get SchemaName(): string {
        return "Reset Password"
    }

    public readonly Email = EmailSignInField
}

const PasswordResetSchema = new PasswordResetSchemaDef

export default PasswordResetPage

export const PasswordResetPagePath = "/password-reset"