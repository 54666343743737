function LoadingIndicator(props: Props) {
    return (
        <div className="d-flex justify-content-center">
            <div className={`spinner-border ${props.small ? "spinner-border-sm" : ""}`} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

interface Props {
    small?: boolean
}

export default LoadingIndicator;