import {UserContext} from "../../context/UserContext";
import AppNavigate from "../../hooks/AppNavigate";
import {ReactNode, useContext} from "react";
import LoadingFullPage from "../common/LoadingFullPage";
import {SignInPath} from "../../routes/signin/SignInPage";

function IsUserRegisteredGuard(props: Props) {
    const {fbUser, loadingFbUser, appUser} = useContext(UserContext);

    if (loadingFbUser)
        return <LoadingFullPage/>

    if (fbUser == null) {
        return <AppNavigate to={SignInPath}/>
    }

    return <>{props.children}</>
}

interface Props {
    readonly children: ReactNode
}

export default IsUserRegisteredGuard