import UsernamePasswordSignin from "./UsernamePasswordSignin";
import {
    AuthProvider,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    OAuthProvider,
    signInWithPopup,
    TwitterAuthProvider,
    UserCredential
} from "firebase/auth";
import {useAppNav} from "../../hooks/useAppNav";
import {Row} from "react-bootstrap";
import {useContext, useState} from "react";
import {UserContext} from "../../context/UserContext";
import IconButton from "../../components/common/IconButton";
import {UsersClient} from "lib-client";
import {ApiErrorCode, getErrorOrNull, makeUserId, User} from "lib-shared";
import {toastIfError} from "../../context/toasts/ToastManager";
import {getDefaultPageForAppMode} from "../../AppMode";

export const SignInPath = "/signin"

function SignInPage() {
    const navigate = useAppNav()
    const {fbUser, loadingAppUser, appUser, clientContext} = useContext(UserContext);

    const [showCredentialsForm, setShowCredentialsForm] = useState(false)
    const [isRegisterMode, setIsRegisterMode] = useState(true)

    if (fbUser) {
        // user is already signed in
        navigate(getDefaultPageForAppMode())
        return <></>
    }

    async function signInWithAuthProvider(provider: AuthProvider) {
        const auth = getAuth()

        try {
            const result = await signInWithPopup(auth, provider)
            const user = result.user
            console.log(`sign in success! ${user.uid}`)
            await onSignedIn(result)
        } catch (error) {
            if (error instanceof Error) {
                const errorCode = "code" in error ? error.code : "unknown"
                const errorMessage = error.message
                console.log(`Failed log in: ${error}: ${errorMessage}`)
                if ("errorCode" in error) {
                    if (errorCode == "auth/user-disabled") {
                        navigate("/disabled")
                    }
                }
            } else {
                console.error(error)
            }
        }

    }

    async function onSignedIn(creds: UserCredential): Promise<boolean> {
        const user: User = {
            user_id: makeUserId(creds.user.uid),
            first_name: creds.user.displayName ?? "User",
            last_name: "",
            email: creds.user.email!,
        }
        const result = await UsersClient.defaultClient.create(user, clientContext)
        const error = getErrorOrNull(result)
        if (error) {
            if (error.code == ApiErrorCode.RECORD_ALREADY_EXISTS) {
                // user already registered - that's fine.
            } else {
                toastIfError(clientContext, result)
                return false
            }
        }
        navigate(getDefaultPageForAppMode())
        return true
    }

    const options: SignInOption[] = [
        {
            name: "Google",
            logo: "google",
            signInAction: () => signInWithAuthProvider(new GoogleAuthProvider()),
        },
        {
            name: "Facebook",
            logo: "facebook",
            signInAction: () => signInWithAuthProvider(new FacebookAuthProvider()),
        },
        {
            name: "Twitter",
            logo: "twitter-x",
            signInAction: () => signInWithAuthProvider(new TwitterAuthProvider()),
        },
        {
            name: "Microsoft",
            logo: "microsoft",
            signInAction: () => signInWithAuthProvider(new OAuthProvider("microsoft.com")),
        },
        {
            name: "Email",
            logo: "envelope",
            signInAction: () => setShowCredentialsForm(true),
        },
    ]

    function showOtherLink() {
        if (isRegisterMode) {
            return <>Already have an account? <a href={"#"} onClick={() => setIsRegisterMode(false)}>Sign in</a></>
        } else {
            return <>Don't have an account? <a href={"#"} onClick={() => setIsRegisterMode(true)}>Register</a></>
        }
    }

    return (
        <div className="mx-auto">
            <Row>
                <h3 className={"text-center"}>{isRegisterMode ? "Register" : "Sign In"}</h3>
                <p className={"text-muted text-sm text-center m-2"}>Create a new ZENSEND account for free. No credit
                    card required.</p>
            </Row>
            {showCredentialsForm &&
                <UsernamePasswordSignin isRegisterMode={isRegisterMode}
                                        toggleRegisterMode={setIsRegisterMode}
                                        signInCallback={onSignedIn}
                                        cancel={() => setShowCredentialsForm(false)}/>}
            {!showCredentialsForm && options.map(o => {
                return (
                    <Row key={o.name + "-sign-in"} className={"m-2 justify-content-center"}>
                        <IconButton action={o.signInAction}
                                    variant={"neutral"}
                                    icon={o.logo}

                                    label={`Continue with ${o.name}`}/>
                    </Row>
                )
            })}
            <div className={"text-sm text-center mt-5"}>
                {showOtherLink()}
            </div>
        </div>
    )
}

interface SignInOption {
    readonly signInAction: () => void
    readonly logo: string
    readonly name: string
}

export default SignInPage