import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import MaterialIcon from "../../components/common/MaterialIcon";
import {UserContext} from "../../context/UserContext";
import {signOut} from "firebase/auth";
import {auth} from "../../utils/Firebase";
import {UsersClient} from "lib-client";
import {useAppNav} from "../../hooks/useAppNav";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {User, UserSchema} from "lib-shared";
import CopyLink from "../../utils/CopyLink";
import {toastIfError} from "../../context/toasts/ToastManager";

function AccountPage() {
    const {clientContext, appUser, loadingAppUser, fbUser} = useContext(UserContext);
    const navigate = useAppNav()

    const [token, setToken] = useState("")

    async function logToken() {
        if (fbUser) {
            const token = await fbUser.getIdToken()
            setToken(token)
        }
    }

    useEffect(() => {
        logToken()
    }, []);

    async function signout() {
        await signOut(auth)
        navigate('/')
    }

    async function save(payload: FormSubmitPayload<User>): Promise<boolean> {
        const response = await new UsersClient().update({value: payload.value}, clientContext)
        toastIfError(clientContext, response)
        return true
    }

    return (
        <PowerPageContent title={"Account"}>
            <AppForm onSubmit={save} loading={loadingAppUser} schema={UserSchema}
                     initialValue={appUser}
                     fields={[
                         [
                             {
                                 fieldType: "text",
                                 field: UserSchema.FirstName,
                             },
                             {
                                 fieldType: "text",
                                 field: UserSchema.LastName,
                             },
                         ],
                     ]}/>

            <div className={"mt-5"}>
                <Button variant="primary" onClick={signout}><MaterialIcon icon={"logout"} size={18}/>Sign Out</Button>
            </div>

            {appUser &&
                <div className={"text-muted mt-5 "}>
                    <h6>Support Info</h6>
                    <div>User Email: {appUser?.email}</div>
                    <div>User ID: {appUser?.user_id}<CopyLink label={"Copy ID"} value={appUser.user_id}/></div>
                    <div>ID Token: DO NOT SHARE! HIDDEN.<CopyLink label={"Copy Token"} value={token}/></div>
                    <p>Sign In Status: {appUser && 'active'}</p>

                </div>

            }
        </PowerPageContent>
    )
}
export default AccountPage