import {ApiResponse, BaseModelSchema, IQuotaService, QuotaConfig, QuotaId, QuotaSchema, QuotaStatus} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext, sendApi} from "../FetchUtils";

export class QuotaClient extends BaseCrudClient<QuotaConfig> implements IQuotaService<ClientContext> {
    public static readonly defaultClient: QuotaClient = new QuotaClient()

    public get schema(): BaseModelSchema<QuotaConfig> {
        return QuotaSchema
    }

    getRemainingQuota(quota: QuotaId, ctx: ClientContext): Promise<ApiResponse<QuotaStatus>> {
        return sendApi(`/${this.resourceName}/remainingQuota`, "POST", quota, ctx);
    }
}