import {ReactNode, useContext, useEffect, useState} from "react"
import {Modal} from "react-bootstrap"
import {MultiModalContext} from "../context/MultiModalManager";
import {getCampaignOutboxLink} from "../routes/outbox/Outbox";

// designed to handle having multiple modals open at once
function AppModal(props: Props) {
    const {activeId, addModal, closeModal} = useContext(MultiModalContext);

    const id = props.name

    const [forceHide, setForceHide] = useState(false)

    function close() {
        closeModal(id)
    }

    useEffect(() => {
        if (props.show) {
            addModal(id)
        } else {
            closeModal(id)
        }
        // if we lose this then close it
        return close
    }, [props.show]);

    function onHide() {
        props.onHide()
    }

    const isActive = activeId == id

    function esc(event: KeyboardEvent) {
        if (event.key === "Escape") {
            event.stopPropagation()
            event.preventDefault()
            props.onHide()
        }
    }

    useEffect(() => {
        const options: AddEventListenerOptions = {once: false, passive: false}
        document.addEventListener("keydown", esc, options);

        return () => {
            document.removeEventListener("keydown", esc, options);
        };
    }, [esc]);

    useEffect(() => {
        setForceHide(false)
    }, [props.show]);

    return (
        <Modal show={props.show && !forceHide} onHide={onHide} size={props.size}
               className={isActive ? "visible" : "invisible"} keyboard={false}>
            {props.children}
        </Modal>
    )
}

export interface BaseModalProps {
    readonly show: boolean
    readonly close: () => void
}

interface Props {
    readonly name: string
    readonly size?: 'sm' | 'lg' | 'xl'
    readonly show: boolean
    readonly onHide: () => void
    readonly children: ReactNode
}

export default AppModal