import * as React from "react";
import {useContext, useState} from "react";
import {UserContext} from "../../context/UserContext";
import {useAppNav} from "../../hooks/useAppNav";
import {OrganizationsClient} from "lib-client";
import {ColumnSpec} from "../../components/tables/BaseTable";
import {isSuccess, Organization, OrganizationSchema} from "lib-shared";
import Button from "react-bootstrap/Button";
import ApiTable, {ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import Form from "react-bootstrap/Form";
import {blockForm} from "../../utils/Utils";
import {toastIfError} from "../../context/toasts/ToastManager";

function AdminOrg() {

    const {fbUser, clientContext, appUser} = useContext(UserContext);

    const nav = useAppNav()

    const [orgName, setOrgName] = useState("")

    async function orgSubmit() {
        const response = await new OrganizationsClient().create({
            org_id: clientContext.org_id!,
            enable_third_party_data_enrichment: false,
            name: orgName,
        }, clientContext)
        toastIfError(clientContext, response)

        if (isSuccess(response))
            nav("/admin")
    }

    const columns: ColumnSpec<Organization, ApiOrderBy>[] = [
        createColumn(OrganizationSchema.Name, true),
        createColumn(OrganizationSchema.OrgId, true),
        createColumn(OrganizationSchema.CreatedAt, true),
        createColumn(OrganizationSchema.UpdatedAt, false),
    ];

    return (
        <div>
            <h2>Organizations</h2>
            <ApiTable<Organization> client={OrganizationsClient.defaultClient}
                                    noDataMessage={"No Orgs yet."}
                                    tableSettings={{
                                        columns,
                                        idExtractor: (org: Organization) => org.org_id,
                                        filterOptions: [],
                                    }}
                                    admin={true}
            />
            <Form onSubmit={blockForm(orgSubmit)}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="New Org" value={orgName}
                                  onChange={(e) => setOrgName(e.target.value)}/>
                </Form.Group>
                <Button variant="primary" type={"submit"}>
                    Add Group
                </Button>
            </Form>

        </div>
    )
}

export default AdminOrg