import {OrgModel} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {CharacterSet, CharacterSetType, Required} from "../validation/Validators";
import {UserId} from "./User";


export interface Organization extends OrgModel {
    readonly name: string
    readonly enable_third_party_data_enrichment: boolean
}



class OrganizationSchemaDef extends OrgModelSchema<Organization> {
    public get apiResourceName(): string {
        return "organizations"
    }

    getFriendlyName(value: Organization): string {
        return value.name
    }

    get SchemaName(): string {
        return "Organization"
    }

    get KeyColumns(): IModelField<any, Organization>[] {
        return [this.OrgId];
    }

    public readonly Name = new FieldClass<string, Organization>({
        field_type: FieldType.TEXT,
        display_name: "Name", name: "name", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<Organization>(),
            new CharacterSet(CharacterSetType.BasicPlus),
        ]
    })

    public readonly EnableThirdPartyDataEnrichment = new FieldClass<boolean, Organization>({
        field_type: FieldType.TOGGLE,
        display_name: "Enable Third Party Data Enrichment",
        name: "enable_third_party_data_enrichment",
        defaultValue: false,
        field_source: FieldSourceType.USER_DEFINED,
        rules: []
    })
}

export const OrganizationSchema: OrganizationSchemaDef = new OrganizationSchemaDef()

export const Organization_Id = "org_id"