import * as React from "react";
import {useContext} from "react";
import {Organization, OrganizationSchema} from "lib-shared";
import {UserContext} from "../../context/UserContext";
import {JoinOrgPath} from "./AcceptInvitationPage";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {fixHref} from "../../utils/Utils";
import {Link} from "react-router-dom";
import {createOrg} from "./CreateOrgModal";
import {Row} from "react-bootstrap";
import InfoBlock from "../../components/common/InfoBlock";

function CreateOrgPage() {
    const {clientContext} = useContext(UserContext);

    async function create(payload: FormSubmitPayload<Organization>): Promise<boolean> {
        return createOrg(payload, clientContext)
    }

    return (
        <div className="mx-auto">
            <Row>
                <h3 className={"text-center"}>Create a New Organization</h3>
                <p className={"text-muted text-sm text-center m-2"}>Create a new Organization for your company.</p>
            </Row>
            <InfoBlock type={"info"}>
                Do you have an Invite Code or has someone else from
                your company already set things up? <Link to={fixHref(JoinOrgPath)}>Click here</Link>
            </InfoBlock>

            <AppForm
                onSubmit={create}
                schema={OrganizationSchema}
                submitLabel={"Create"}
                fields={[
                    {
                        fieldType: "text",
                        field: OrganizationSchema.Name
                    }
                ]}
            />
        </div>
    )
}

export const SetupOrgPath = "/setup-org"



export default CreateOrgPage