import ChecklistWidgetRow, {ChecklistState} from "./ChecklistWidgetRow";
import {ApiResponse, FilterField, getResultOrNull, IReadonlyApiService, isSuccess} from "lib-shared";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/UserContext";
import ResultBlock from "../ResultBlock";
import {ClientContext} from "lib-client";

function ChecklistWidget(props: Props) {

    const {fbUser, clientContext} = useContext(UserContext);


    const [state, setState] = useState<{ [key: string]: number }>({})
    const [loading, setLoading] = useState(true)


    async function getValues() {
        setLoading(true)
        const promises = props.goals.map<Promise<ApiResponse<number>>>(goal => {
            return goal.query.client.count({
                filterFields: goal.query.filters
            }, clientContext)
        })

        const results = await Promise.all(promises)

        let result: { [key: string]: number } = {}
        for (let i = 0; i < results.length; i++) {

            const value = getResultOrNull(results[i])

            if (!value)
                return

            result = {
                ...result,
                [props.goals[i].name]: value
            }
        }
        setState(result)
        setLoading(false)
    }

    useEffect(() => {
        getValues()
    }, []);


    function isDone(goal: Goal) {
        const value = state[goal.name] ?? 0
        return goal.target <= value
    }

    return (
        <div className="card">
            <div className="card-body p-7">
                <h5 className="h3 font-display fw-bold mb-3">Getting Started</h5>
                <p className={"text-muted"}>Complete these tasks to complete your set up!</p>
                <ResultBlock isLoading={loading} error={null}>
                    <div className="list-group list-group-flush mb-5">
                        {props.goals.map((goal, i) => {
                            const value = state[goal.name] ?? 0

                            const done = isDone(goal)
                            const prevDone = i == 0 || isDone(props.goals[i - 1])
                            const locked = !done && !prevDone

                            let clState: ChecklistState
                            if (done)
                                clState = "Done"
                            else if (locked)
                                clState = "Locked"
                            else
                                clState = "Active"

                            return <ChecklistWidgetRow key={goal.name} label={goal.name} state={clState}
                                                       href={goal.href}/>
                        })}
                    </div>
                </ResultBlock>
            </div>
        </div>
    )
}

interface Props {
    goals: Goal[]
}

export interface Goal {
    readonly name: string
    readonly query: Query
    readonly href: string
    readonly target: number
}

export interface Query {
    readonly client: IReadonlyApiService<any, ClientContext>
    readonly filters: FilterField[]
}

export default ChecklistWidget