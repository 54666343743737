import OptionsDropdown, {LabelAndAction} from "../common/OptionsDropdown";

function TableActions(props: Props) {

    let actions = props.actions

    if (props.forceEnableActions) {
        actions = actions.map(a => {
            return {
                ...a,
                enabled: true
            }
        })
    }

    return (
        <OptionsDropdown id={`dropdown-checked`}
                         className={"btn-square btn-neutral w-rem-6 h-rem-6 p-3"}
                         buttonContent={<i className="bi bi-three-dots"></i>}
                         actions={actions}/>
    )
}

export interface TableAction extends LabelAndAction {
    // default false
    readonly supportsMultiRows?: boolean
}

interface Props {
    readonly actions: TableAction[]
    readonly forceEnableActions?: boolean
}

export default TableActions