import {ReactNode} from "react";
import {ApiResult} from "../hooks/useApi";
import ResultBlock from "./ResultBlock";

function ApiResultBlock<T>(props: Props<T>) {
    const isReady = !props.result.error && !props.result.isLoading

    return (
        <ResultBlock isLoading={props.result.isLoading} error={props.result.error}>
            {isReady && props.result.value != null && props.children(props.result.value!)}
        </ResultBlock>
    )
}

interface Props<T> {
    result: ApiResult<T>
    children: (value: T) => ReactNode
}

export default ApiResultBlock