import {
    Draft,
    DraftState,
    EmailSection,
    getDraftSectionOrDefault,
    getSimpleGenCategory, isSuccess,
    isUserEditableState,
    SimpleGenerationCategory
} from "lib-shared";
import "./EmailEditor.css"
import useAutosave from "../../hooks/useAutosave";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import SecondsAgo from "../common/SecondsAgo";
import {DraftsClient} from "lib-client";
import {UserContext} from "../../context/UserContext";
import Button from "../common/Button";
import OptionsDropdown, {LabelAndAction} from "../common/OptionsDropdown";
import IconButtonMinimalist from "../common/IconButtonMinimalist";
import SlimAttribute from "../metrics/SlimAttribute";
import {useApiGet} from "../../hooks/useApiGet";
import ApiResultBlock from "../ApiResultBlock";
import MaterialIcon from "../common/MaterialIcon";
import {toastIfError} from "../../context/toasts/ToastManager";

// just make it look like a familiar email interface
function EmailEditor(props: Props) {

    const {clientContext} = useContext(UserContext)
    const {draft_id} = props

    const [lastSaveTime, setLastSaveTime] = useState<Date | null>(null)

    const draftResponse = useApiGet<Draft>(clientContext, DraftsClient.defaultClient, draft_id, true)

    const isEditableState = isUserEditableState(draftResponse.value?.state ?? "ready")

    const category: SimpleGenerationCategory = getSimpleGenCategory(draftResponse.value?.generation_category ?? "generatedWithName")


    const [subject, setSubject] = useState("")
    const [greeting, setGreeting] = useState("")
    const [body, setBody] = useState("")
    const [closing, setClosing] = useState("")

    useEffect(() => {

        if (draftResponse.value && draftResponse.value.campaign) {
            setSubject(getDraftSectionOrDefault(draftResponse.value, draftResponse.value.campaign, EmailSection.Subject))
            setGreeting(getDraftSectionOrDefault(draftResponse.value, draftResponse.value.campaign, EmailSection.Greeting))
            setBody(getDraftSectionOrDefault(draftResponse.value, draftResponse.value.campaign, EmailSection.Body))
            setClosing(getDraftSectionOrDefault(draftResponse.value, draftResponse.value.campaign, EmailSection.Closing))
        } else {
            setSubject("")
            setGreeting("")
            setBody("")
            setClosing("")
        }
        setLastSaveTime(null)
    }, [draftResponse.value, draftResponse.isLoading]);

    async function autosave() {
        if (!draftResponse.value) {
            return
        }
        if (!isEditableState) {
            console.log(`Can't edit email in state ${draftResponse.value.state}`)
            return
        }

        const draft: Draft = {
            ...draftResponse.value,
            subject: subject,
            greeting: greeting,
            body: body,
            closing: closing,
        }

        await new DraftsClient().update({value: draft}, clientContext)
        setLastSaveTime(new Date())
    }

    async function setState(state: DraftState) {
        if (!draftResponse.value) {
            return
        }

        const updated: Draft = {
            ...draftResponse.value,
            subject: subject,
            greeting: greeting,
            body: body,
            closing: closing,
            state: state
        }
        const result = await new DraftsClient().update({value: updated}, clientContext)
        toastIfError(clientContext, result)

        if (isSuccess(result)) {
            props.close()
        }
    }

    useAutosave(props.draft_id, body + subject + greeting + closing, autosave, 5000)

    const lines = Math.max(10, (body?.match(/\n/g) || []).length)

    const actions: LabelAndAction[] = [
        {
            label: "Skip",
            icon: "skip-end",
            enabled: draftResponse.value?.state != "skipped",
            subText: "Skip this email for now and address is later",
            action: () => setState("skipped")
        },
        {
            label: "UnSkip",
            icon: "play",
            enabled: draftResponse.value?.state == "skipped",
            subText: "Return this draft to a ready state",
            action: () => setState("ready")
        },
        {
            label: "Regenerate",
            icon: "arrow-clockwise",
            subText: "Regenerate this email",
            action: () => setState("generating")
        },
    ]

    if (!draft_id) {
        return <div>Draft Not Found!</div>
    }


    return (
        <div className="email-editor d-flex flex-column ps-3 pe-3">
            <ApiResultBlock result={draftResponse}>
                {(value) => (
                    <>
                        {category == "generated" &&
                            <SlimAttribute label={"This email is personalized by your AI"} color={"success"}
                                           icon={"check-circle-fill"}/>}
                        {category == "default" &&
                            <SlimAttribute label={"This email uses the default campaign message"} color={"muted"}
                                           icon={"info-circle-fill"}/>}
                        <div className="pb-2 pt-2 clickable-not flex-shrink-0">
                            To: {value?.lead?.email}
                        </div>
                        <div className="flex-shrink-0 border-bottom"/>
                        <div className="pb-2 pt-2 flex-shrink-0">
                            Subject: <input className={""} type="text" placeholder="Subject"
                                            disabled={!isEditableState}
                                            style={{boxShadow: "border-box", width: "80%"}} value={subject}
                                            onChange={(e) => setSubject(e.target.value)}/>
                        </div>
                        <div className="flex-shrink-0 border-bottom"/>
                        <div className={"mt-1 mb-1 flex-fill"}>
                    <textarea className={""} placeholder="Write a greeting" value={greeting}
                              disabled={!isEditableState}
                              style={{width: "100%"}}
                              rows={2}
                              cols={75}
                              onChange={(e) => setGreeting(e.target.value)}/>
                        </div>
                        <div className={"mt-1 mb-1 overflow-y-scroll flex-fill"}>
                    <textarea className={""} placeholder="Write a message" value={body}
                              disabled={!isEditableState}
                              style={{width: "100%"}}
                              rows={lines}
                              cols={75}
                              onChange={(e) => setBody(e.target.value)}/>
                        </div>
                        <div className={"mt-1 mb-1 flex-fill"}>
                    <textarea className={""} placeholder="Write a closing" value={closing}
                              disabled={!isEditableState}
                              style={{width: "100%"}}
                              rows={2}
                              cols={75}
                              onChange={(e) => setClosing(e.target.value)}/>
                        </div>

                        {lastSaveTime &&
                            <div className={"text-muted flex-shrink-0"}>Last saved <SecondsAgo date={lastSaveTime!}/>
                            </div>
                        }
                        <div className={"flex-shrink-0"}>
                            <div className={"float-start"}>
                                Train your AI
                                <IconButtonMinimalist className={"ms-2 me-2"} action={() => {
                                }} icon={"hand-thumbs-down"}/>
                                <IconButtonMinimalist className={"ms-2 me-2"} action={() => {
                                }} icon={"hand-thumbs-up"}/>
                            </div>

                            <Button variant="primary" className="float-end"
                                    disabled={!isEditableState}
                                    icon={"send"} label={"Send"}
                                    onClick={() => setState("sending")}/>
                            <div className={"float-end me-2"}>
                                <OptionsDropdown id="email-editor-options"
                                                 disabled={!isEditableState}
                                                 buttonContent={<MaterialIcon icon={"three-dots-vertical"} size={14}/>}
                                                 actions={actions}/>
                            </div>
                        </div>
                    </>
                )
                }

            </ApiResultBlock>
        </div>
    )
        ;
}

export interface Props {
    draft_id: string
    close: () => void
}

export default EmailEditor;

