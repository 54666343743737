import {BaseOrderBy, ColumnSpec, OrderByState} from "./BaseTable";
import MaterialIcon from "../common/MaterialIcon";
import {OrderByDirection} from "lib-shared";

function TableHeaderCell<TModel, TOrderBy extends BaseOrderBy>(props: Props<TModel, TOrderBy>) {
    const col = props.column

    let size = ""
    switch (col.size) {
        case "sm":
            size = "d-sm-table-cell"
            break;
        case "lg":
            size = "d-lg-table-cell"
            break;
        case "xl":
            size = "d-xl-table-cell"
            break;
    }

    const canOrderBy = col.orderBy != undefined
    const isActiveOrderBy = canOrderBy && props.order?.orderBy == col.orderBy

    function onClick() {
        if (!col.orderBy)
            return

        const dir: OrderByDirection = isActiveOrderBy ? (props.order?.dir == "asc" ? "desc" : "asc") : "asc"
        props.setOrderBy({
            orderBy: col.orderBy,
            dir: dir,
        })
    }

    return <th className={`w-md-32 ${size} ${canOrderBy ? "clickable" : ""}`} scope="col" onClick={onClick}>{col.name}
        <MaterialIcon icon={props.order?.dir == "asc" ? "caret-up-fill" : "caret-down-fill"} size={12}
                      display={isActiveOrderBy}
                      marginStart={3} color={"muted"}/>
    </th>
}

interface Props<TModel, TOrderBy extends BaseOrderBy> {
    readonly column: ColumnSpec<TModel, TOrderBy>
    readonly order: OrderByState<TOrderBy> | undefined
    readonly setOrderBy: (order: OrderByState<TOrderBy>) => void
}

export default TableHeaderCell