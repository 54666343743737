import {SetURLSearchParams, useSearchParams} from "react-router-dom"

export function useQueryState(key: string): [string | null, (value: string | null) => void] {

    const sp= useSearchParams()

    const params: URLSearchParams = sp[0]
    const setParams: SetURLSearchParams = sp[1]
    const value: string | null = params.get(key)

    function setter(x: string | null): void {

        if (x== null) {
            params.delete(key)
        }else {
            params.set(key, x)
        }

        setParams(params, {replace: true})
    }

    return [value, setter]
}
