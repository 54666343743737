import {ReactNode, useEffect, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {dateToNiceTimeAgo} from "../../utils/Utils";
import {OverlayInjectedProps} from "react-bootstrap/Overlay";

function SecondsAgo(props: Props) {

    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 10000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <span>{dateToNiceTimeAgo(props.date)}</span>
    )
}

interface Props {
    date: Date
}

export default SecondsAgo