import {FirebaseOptions} from "@firebase/app";
import {getEnvType} from "./Firebase";



export function getFirebaseConfig(): FirebaseOptions {
    // get these from firebase console > project settings > scroll down
    const config: FirebaseOptions = {
        apiKey: getEnvType() == "prod" ? "AIzaSyD75D9IpHoQHBtKcHTM6V7rNoXqGKBIYjc" : "AIzaSyCrwIzn0OHuR47SIL9FgYS0RuI36_Wm8sE",
        authDomain: getEnvType() == "prod" ? "zensend-prod.firebaseapp.com" : "zensend-dev.firebaseapp.com",
        projectId: getEnvType() == "prod" ? "zensend-prod" : "zensend-dev",
        appId: "1:19691918413:web:4d80f8e2dc3bce6d01f804",
    };


    if (!config) {
        throw new Error('No firebase config provided.')
    } else {
        return config
    }
}