import './App.scss';
import {RouterProvider} from 'react-router-dom';
import {routes} from "./routes/Routes";
import * as React from "react";


function App() {
    return (
        <div style={{height: "100%", maxHeight: "100%"}}>
            <div className={"h-100"}>
                <RouterProvider router={routes}/>
            </div>
        </div>
    );
}


export default App;
