import {useSearchParams} from "react-router-dom"

export function useQueryStateArray(key: string): [string[], (values: string[]) => void] {

    const [params, setParams] = useSearchParams()

    function setter(x: string[]): void {
        const result = new URLSearchParams()
        for (let x1 of x) {
            result.append(key, x1)
        }
        setParams(result, {replace: true})
    }

    const values: string[] = params.getAll(key)

    return [values, setter]
}
