import {LeadGroup, SegmentationClass} from "lib-shared";
import {Form, Row} from "react-bootstrap";

function SegmentToGroupMappingItem(props: Props) {
    const {mapping} = props

    function onChange(value: string) {
        props.callback({
            ...mapping,
            group: props.allGroups.find(g => g.lead_group_id == value) ?? null
        })
    }

    return (
        <div className={"row p-3 bg-light border-bottom"}>
            <Row className={"mb-2"}>
                <div className={(mapping.enabled ? "" : "text-muted") + ""}>{mapping.segment.name}</div>
            </Row>
            <Row>
                <div className={"col-9"}>
                    <select
                        className="form-select"
                        id={`select-${mapping.segment.class_id}`}
                        value={mapping.group?.lead_group_id}
                        autoComplete={"none"}
                        onChange={(e) => onChange(e.target.value)}
                    >
                        <option value={""}></option>
                        {props.allGroups.map(g => {
                            return <option key={`option-${mapping.segment.class_id}-${g.lead_group_id}`}
                                           value={g.lead_group_id}>{g.name}</option>
                        })}
                    </select>
                </div>
                <div className={"col-3"}>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id={`${mapping.segment.class_id}-switch`}
                        checked={mapping.enabled}
                        onChange={() => props.callback({...mapping, enabled: !mapping.enabled})}
                        label="Enabled"
                    />
                </div>
            </Row>
        </div>
    )
}

interface Props {
    readonly mapping: SegmentToGroupMapping
    readonly callback: (value: SegmentToGroupMapping) => void
    readonly allGroups: LeadGroup[]
}

export interface SegmentToGroupMapping {
    readonly segment: SegmentationClass
    readonly group: LeadGroup | null
    readonly enabled: boolean
}

export default SegmentToGroupMappingItem