import {BaseCrudClient} from "./BaseCrudClient";
import {
    ISegmentationJobResultsService,
    ModelSchema,
    SegmentationJobResults,
    SegmentationJobResultsSchema,
} from "lib-shared";
import {ClientContext} from "../FetchUtils";

export class SegmentationJobResultsClient
    extends BaseCrudClient<SegmentationJobResults>
    implements ISegmentationJobResultsService<ClientContext> {
    public static readonly defaultClient: SegmentationJobResultsClient = new SegmentationJobResultsClient()
    get schema(): ModelSchema<SegmentationJobResults> {
        return SegmentationJobResultsSchema;
    }
}