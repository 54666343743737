import {isSuccess, Lead, LeadSchema} from "lib-shared";
import * as React from "react";
import {useContext} from "react";
import AppForm, {FormSubmitPayload} from "../../../components/forms/AppForm";
import {LeadsClient} from "lib-client";
import {UserContext} from "../../../context/UserContext";
import {useApiGet} from "../../../hooks/useApiGet";
import {ApiResult} from "../../../hooks/useApi";
import {eventBus} from "../../../utils/EventBus";
import {toastIfError} from "../../../context/toasts/ToastManager";

function LeadEditor(props: Props) {
    const {lead} = props

    const {clientContext} = useContext(UserContext);

    const maybeLeadResult: ApiResult<Lead> | undefined =
        typeof props.lead === "string" ?
            useApiGet<Lead>(clientContext, LeadsClient.defaultClient, props.lead)
            : undefined

    async function submit(payload: FormSubmitPayload<Lead>): Promise<boolean> {

        const lead = {
            ...payload.value,
            lead_id: payload.value.email
        }

        const response = await new LeadsClient().createOrUpdate(lead, props.isCreate, clientContext)
        if (!isSuccess(response)) {
            toastIfError(clientContext, response)
            return Promise.resolve(false)
        }

        if (props.closeCallback)
            props.closeCallback()

        eventBus.trigger("item-changed", lead)
        return Promise.resolve(true)
    }

    return (
        <AppForm
            onSubmit={props.onChange ? null : submit}
            initialValueLazy={maybeLeadResult}
            initialValue={typeof props.lead === "object" ? props.lead : undefined}
            schema={LeadSchema}
            forceValidation={props.forceValidation}
            onChange={props.onChange}
            fields={[
                {
                    fieldType: "text",
                    field: LeadSchema.Email,
                    disabled: !props.isCreate,
                },
                [
                    {
                        fieldType: "text",
                        field: LeadSchema.FirstName
                    },
                    {
                        fieldType: "text",
                        field: LeadSchema.LastName
                    },
                ],
            ]}

        />
    )
}

export interface Props {
    readonly forceValidation?: boolean
    readonly isCreate: boolean
    readonly lead: Lead | string
    readonly onChange?: (lead: Lead) => void
    readonly closeCallback?: () => void
}

export default LeadEditor