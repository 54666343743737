import {UserContext} from "../../context/UserContext";
import {updatePassword} from "firebase/auth";
import {useContext} from "react";
import {IModelField, ModelSchema} from "lib-shared";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {PasswordSignInField} from "../signin/PasswordSignInSchema";

function ProfilePage() {
    const {fbUser, loadingAppUser, appUser, clientContext} = useContext(UserContext);

    async function updateUserPassword(req: FormSubmitPayload<PasswordChange>): Promise<boolean> {
        await updatePassword(fbUser!, req.value.newPassword)
        return true
    }

    return (
        <div>
            <AppForm onSubmit={updateUserPassword}
                     schema={PasswordChangeSchema}
                     fields={[
                         {
                             fieldType: "text",
                             stringType: "password",
                             field: PasswordChangeSchema.Password,
                         }
                     ]}
            />
        </div>
    )
}

interface PasswordChange {
    readonly newPassword: string
}

class PasswordChangeSchemaDef extends ModelSchema<PasswordChange> {
    get apiResourceName(): string {
        throw new Error('Method not implemented.');
    }

    get KeyColumns(): IModelField<any, PasswordChange>[] {
        throw new Error('Method not implemented.');
    }

    getFriendlyName(value: PasswordChange): string {
        throw new Error('Method not implemented.');
    }

    get SchemaName(): string {
        return "New Password"
    }

    public readonly Password = PasswordSignInField
}

const PasswordChangeSchema = new PasswordChangeSchemaDef


export default ProfilePage