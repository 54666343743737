import {Campaign, CampaignSchema} from "lib-shared"
import {useApiList} from "../../../hooks/useApiList";
import {UserContext} from "../../../context/UserContext";
import {ChangeEvent, useContext} from "react";
import {Form} from "react-bootstrap";
import {CampaignsClient} from "lib-client";

function WizardSectionCampaign(props: Props) {

    const {clientContext} = useContext(UserContext)

    const campaignsResponse = useApiList<Campaign>(clientContext, CampaignsClient.defaultClient, {
        filterFields: [{
            field: CampaignSchema.State.name,
            value: ["launched"]
        }]
    }, {})

    const campaigns = campaignsResponse?.value ?? []

    const options = campaigns.map(campaign => <option key={campaign.campaign_id}
                                                      value={campaign.campaign_id}>{campaign.name}</option>)

    function onChange(e: ChangeEvent<HTMLSelectElement>) {
        const id: string | null = e.target.value
        if (id == "") {
            props.setCampaign(null)
            return
        }
        const campaign = campaigns.find(g => e.target.value == g.campaign_id)
        props.setCampaign(campaign!)
    }

    return (
        <div>
            <p className={"text-muted mb-3 mt-3"}>You can optionally add these leads to a campaign.</p>
            <Form.Group className="mb-3" controlId={"select-campaign"}>
                <Form.Label>Select a Campaign</Form.Label>
                <Form.Select aria-label="Default select example" onChange={onChange}
                             value={props.campaign?.campaign_id}>
                    <option key={"null"} value={""}></option>
                    {options}
                </Form.Select>
            </Form.Group>
        </div>
    )
}

interface Props {
    readonly campaign: Campaign | null
    readonly setCampaign: (c: Campaign | null) => void
}

export default WizardSectionCampaign