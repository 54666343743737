import {IProspectingJobsService, ModelSchema, ProspectingJob, ProspectingJobSchema,} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext} from "../FetchUtils";

export class ProspectingJobsClient extends BaseCrudClient<ProspectingJob> implements IProspectingJobsService<ClientContext> {
    public static readonly defaultClient: ProspectingJobsClient = new ProspectingJobsClient()

    get schema(): ModelSchema<ProspectingJob> {
        return ProspectingJobSchema;
    }

}