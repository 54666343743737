import {FilterField, IReadonlyApiService} from "lib-shared";
import {ColorType} from "../../utils/Utils";
import {useApiCount} from "../../hooks/useApiList";
import {UserContext} from "../../context/UserContext";
import {useContext} from "react";
import SlimAttribute from "./SlimAttribute";
import {ClientContext} from "lib-client";

function SlimMetric(props: Props) {

    const {clientContext} = useContext(UserContext);

    const count = useApiCount(clientContext, props.client, {
        hydrate: props.hydrate ?? false,
        filterFields: props.filters
    }, {})

    if (count.isLoading || count.error || !count.value || count.value < props.minValue) {
        return <></>
    }

    return (
        <SlimAttribute label={` ${count.value} ${props.label}`} color={props.color} icon={props.icon}
                       spinner={props.spinner}/>
    )
}

interface Props {
    readonly client: IReadonlyApiService<any, ClientContext>
    readonly filters: FilterField[]
    readonly hydrate?: boolean
    readonly label: string
    readonly color: ColorType
    // value must be larger than this to get displayed
    readonly minValue: number
    readonly spinner?: boolean
    readonly icon: string
}

export default SlimMetric