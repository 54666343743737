import {OrgModel} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {EnumValues, MaxNumericValue, MinNumericValue, MultipleEnumValues, Required} from "../validation/Validators";

export interface ProspectingJob extends OrgModel {
    readonly job_id: string
    readonly num_leads: number
    readonly lead_group_name?: string
    readonly industry_filters: IndustryType[]
    readonly role_filters: RoleType[]
    readonly company_size_filters: CompanySizeType[]
    readonly state: ProspectingJobState
}


export enum ProspectingJobState {
    pending = "pending",
    completed = "completed",
    error = "error"
}

export enum IndustryType {
    aviation_and_aerospace = 'aviation & aerospace',
    banking = 'banking',
    apparel_and_fashion = 'apparel & fashion',
    automotive = 'automotive',
    computer_software = 'computer software',
    computer_hardware = 'computer hardware',
    computer_games = 'computer games',
    computer_and_network_security = 'computer & network security',
    construction = 'construction',
    consumer_goods = 'consumer goods',
    defense_and_space = 'defense & space',
    e_learning = 'e-learning',
    entertainment = 'entertainment',
    financial_services = 'financial services',
    food_and_beverages = 'food & beverages',
    gambling_and_casinos = 'gambling & casinos',
    graphic_design = 'graphic design',
    hospitality = 'hospitality',
    information_technology_and_services = 'information technology and services',
    legal_services = 'legal services',
    luxury_goods_and_jewelry = 'luxury goods & jewelry',
    military = 'military',
    music = 'music',
    oil_and_energy = 'oil & energy',
    retail = 'retail',
    staffing_and_recruiting = 'staffing and recruiting',
    venture_capital_and_private_equity = 'venture capital & private equity',
    wine_and_spirits = 'wine and spirits',
}

export enum RoleType {
    customer_service = 'customer_service',
    design = 'design',
    education = 'education',
    engineering = 'engineering',
    finance = 'finance',
    health = 'health',
    human_resources = 'human_resources',
    legal = 'legal',
    marketing = 'marketing',
    media = 'media',
    operations = 'operations',
    public_relations = 'public_relations',
    real_estate = 'real_estate',
    sales = 'sales',
    trades = 'trades'
}

export enum CompanySizeType {
    size_1_10 = '1-10',
    size_11_50 = '11-50',
    size_51_200 = '51-200',
    size_201_500 = '201-500',
    size_501_1000 = '501-1000',
    size_1001_5000 = '1001-5000',
    size_5001_10000 = '5001-10000',
    size_10001_plus = '10001+'

}

export class ProspectingJobSchemaDef extends OrgModelSchema<ProspectingJob> {
    public get KeyColumns(): IModelField<any, ProspectingJob>[] {
        return [this.OrgId, this.JobId]
    }

    getFriendlyName(value: ProspectingJob): string {
        return value.lead_group_name ?? value.job_id
    }

    get apiResourceName(): string {
        return "prospectingJobs";
    }

    get SchemaName(): string {
        return "ProspectingJob"
    }

    public readonly JobId = new FieldClass<string, ProspectingJob>({
        field_type: FieldType.TEXT,
        display_name: "Job ID",
        name: "job_id",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [
            new Required<ProspectingJob>(),
        ],
    });

    public readonly Name = new FieldClass<string, ProspectingJob>({
        field_type: FieldType.TEXT,
        display_name: "Name", name: "lead_group_name", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<ProspectingJob>(),
        ]
    })

    public readonly NumLeads = new FieldClass<number, ProspectingJob>({
        field_type: FieldType.TEXT,
        display_name: "Number of Leads", name: "num_leads", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<ProspectingJob>(),
            new MinNumericValue(1),
            new MaxNumericValue(5),
        ]
    })

    public readonly IndustryFilters = new FieldClass<string[], ProspectingJob>({
        field_type: FieldType.MULTI_SELECT,
        display_name: "Industries", name: "industry_filters", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<ProspectingJob>(),
            new MultipleEnumValues<ProspectingJob>(IndustryType),
        ]
    })

    public readonly RoleFilters = new FieldClass<string[], ProspectingJob>({
        field_type: FieldType.MULTI_SELECT,
        display_name: "Roles", name: "role_filters", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<ProspectingJob>(),
            new MultipleEnumValues<ProspectingJob>(RoleType),
        ]
    })

    public readonly CompanySizeFilters = new FieldClass<string[], ProspectingJob>({
        field_type: FieldType.MULTI_SELECT,
        display_name: "Company Size", name: "company_size_filters", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<ProspectingJob>(),
            new MultipleEnumValues<ProspectingJob>(CompanySizeType),
        ]
    })

    public readonly State = new FieldClass<string, ProspectingJob>({
        field_type: FieldType.TEXT,
        display_name: "State", name: "state", field_source: FieldSourceType.SYSTEM_DEFINED, rules: [
            new EnumValues<ProspectingJob>(ProspectingJobState),
        ]
    })
}

export const ProspectingJobSchema: ProspectingJobSchemaDef = new ProspectingJobSchemaDef()
