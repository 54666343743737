import * as React from "react";
import {useContext, useState} from "react";
import Modal from "react-bootstrap/Modal";
import AppModal from "../../components/AppModal";
import {ConfirmModalRequest} from "./ConfirmModalContext";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {eventBus} from "../../utils/EventBus";
import {
    FieldClass,
    FieldSourceType,
    FieldType,
    MatchTextExact,
    IModelField,
    ModelSchema,
    Required,
    isSuccess
} from "lib-shared";
import {UserContext} from "../UserContext";
import {toastIfError} from "../toasts/ToastManager";

function TypeConfirmModal(props: Props) {
    const [show, setShow] = useState(true)
    const [loading, setLoading] = useState(false)
    const {clientContext} = useContext(UserContext);

    const challenge = props.req.challenge ?? "confirm"

    function cancel() {
        setShow(false)
        props.close(props.requestId)
    }

    async function formSubmit(input: FormSubmitPayload<ConfirmInput>): Promise<boolean> {
        setLoading(true)

        try {
            const responses = await Promise.all(props.req.resources.map(r => {
                switch (props.req.action_type) {
                    case "delete":
                        return props.req.client.delete({id: r.id}, clientContext)
                    case "archive":
                        return props.req.client.archive({id: r.id}, clientContext)
                }
            }))

            for (let response of responses) {
                toastIfError(clientContext, response)
            }

            const failureCount = responses.filter(r => !isSuccess(r)).length

            // usually this is a ApiResponse which has an error field
            if (failureCount > 0) {
                console.error(`Failed to process ${failureCount}/${responses.length} request`)
                setLoading(false)
                return false
            }
            // this is probably going to require a table update so just do it
            eventBus.trigger("item-changed", null)
        } catch (e) {
            console.error(e)
            setLoading(false)
            return false
        }

        setLoading(false)
        setShow(false)
        props.close(props.requestId)
        return true
    }

    const resourceNameString = props.req.resources.length > 1 ? `${props.req.resources[0].name} and ${props.req.resources.length - 1} other items` : props.req.resources[0].name

    let actionName: string
    switch (props.req.action_type) {
        case "delete":
            actionName = "delete"
            break;
        case "archive":
            actionName = "archive"
            break;
    }

    return (
        <AppModal show={show} onHide={cancel} name={"confirm-delete"}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"mb-3"}><p>You are about to {actionName} <b>{resourceNameString}</b>.</p></div>
                <div>To {actionName} please type <b>{challenge}</b></div>
                <AppForm onSubmit={formSubmit}
                         loading={loading}
                         schema={confirmInputSchema}
                         fields={[
                             {
                                 fieldType: "text",
                                 placeholder: "confirm",
                                 field: confirmInputSchema.Input,
                             }
                         ]}
                         submitLabel={actionName.toUpperCase()}
                         onCancel={cancel}
                         variant={"danger"}/>
            </Modal.Body>
        </AppModal>
    );
}

interface ConfirmInput {
    readonly input: string
}

class ConfirmInputSchema extends ModelSchema<ConfirmInput> {
    getFriendlyName(value: ConfirmInput): string {
        return ""
    }

    get apiResourceName(): string {
        return ""
    }

    get KeyColumns(): IModelField<any, ConfirmInput>[] {
        return []
    }

    get SchemaName(): string {
        return "Confirm Input"
    }

    public readonly Input = new FieldClass<string, ConfirmInput>({
        name: "input",
        display_name: "",
        field_source: FieldSourceType.USER_DEFINED,
        field_type: FieldType.TEXT,
        rules: [
            new Required<ConfirmInput>(),
            new MatchTextExact<ConfirmInput>("confirm"),
        ]
    })
}

const confirmInputSchema = new ConfirmInputSchema()


interface Props {
    readonly requestId: string
    readonly req: ConfirmModalRequest<any>
    readonly close: (id: string) => void
}

export default TypeConfirmModal;