import ProgressBar from 'react-bootstrap/ProgressBar';
import {AppProgressProps, ProgressTask, ProgressValue} from "./AppProgressContext";

function AppProgressBar(props: Props) {

    const percent = Math.min((props.progress / props.current.total) * 100, 100)
    return (
        <div>
            <div className={"d-flex mt-3 mb-3"}>
                <div className="me-auto p-2">{props.current.message}</div>
                <div className={""}>{`${percent.toFixed(0)}%`}</div>
            </div>
            <ProgressBar variant={"primary"} now={percent}/>
        </div>
    )
}

interface Props {
    readonly current: ProgressTask
    readonly progress: number
}

export default AppProgressBar