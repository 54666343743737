import {
    BaseModelSchema,
    ITikTokPostEntitiesService,
    TikTokPostEntity,
    TikTokPostEntitySchema
} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext} from "../FetchUtils";


export class TikTokPostEntitiesClient extends BaseCrudClient<TikTokPostEntity> implements ITikTokPostEntitiesService<ClientContext> {
    public static readonly defaultClient: TikTokPostEntitiesClient = new TikTokPostEntitiesClient()

    get schema(): BaseModelSchema<TikTokPostEntity> {
        return TikTokPostEntitySchema;
    }
}