import {initializeApp} from 'firebase/app';
import {
    getAuth,
} from 'firebase/auth';
import {getFirebaseConfig} from './FirebaseConfig';
import {EnvType} from "lib-shared";

const app = initializeApp(getFirebaseConfig());
export const auth = getAuth(app);


export function getEnvType(): EnvType
{
    const host = window.location.hostname

    if (host == "localhost") {
        return "local"
    } else if (host == "app.dev.zensend.ai"|| host == "app.dev.zensend.ai") {
        return "dev"
    } else if (host == "zensend-prod.web.app" || host == "app.zensend.ai") {
        return "prod"
    }
    return "local"

}
