import {IModelField, ModelRule} from "./Validation";


export class Required<TModel> extends ModelRule<any, TModel> {
    constructor() {
        super("required", true);
    }

    protected doCheck(field: IModelField<any, TModel>, model: TModel, value: any | undefined): string | null {
        if (value == undefined) {
            return "Required."
        } else if (Array.isArray(value)) {
            if (value.filter(v => v.trim() != "").length == 0) {
                return "Required."
            }
        } else if (typeof value === "string" && value.trim() == "") {
            return "Required."
        }

        return null
    }
}

export class MinNumericValue<TModel> extends ModelRule<number, TModel> {
    private readonly min: number

    constructor(min: number) {
        super(`min value ${min}`);
        this.min = min;
    }

    protected doCheck(field: IModelField<number, TModel>, model: TModel, value: number): string | null {
        return value < this.min ? `Must be at least ${this.min}.` : null;
    }
}

export class MaxNumericValue<TModel> extends ModelRule<number, TModel> {
    private readonly max: number

    constructor(max: number) {
        super(`max value ${max}`);
        this.max = max;
    }

    protected doCheck(field: IModelField<number, TModel>, model: TModel, value: number): string | null {
        return value > this.max ? `Must be at most ${this.max}.` : null;
    }
}

export class MatchTextExact<TModel> extends ModelRule<string, TModel> {
    private readonly match: string

    constructor(match: string) {
        super(`match '${match}'`);
        this.match = match;
    }

    protected doCheck(field: IModelField<string, TModel>, model: TModel, value: string | undefined): string | null {
        if (value != this.match) {
            return "Does not match."
        }
        return null
    }
}

export class MinLength<TModel> extends ModelRule<string, TModel> {
    private readonly min: number

    constructor(min: number) {
        super(`min len ${min}`);
        this.min = min;
    }

    protected doCheck(field: IModelField<string, TModel>, model: TModel, value: string): string | null {
        if (value == "") {
            return null
        }
        return (value?.length ?? 0) < this.min ? `Must be at least ${this.min} characters.` : null;
    }
}

export class MaxLength<TModel> extends ModelRule<string, TModel> {
    private readonly max: number

    constructor(max: number) {
        super(`max len ${max}`);
        this.max = max;
    }

    protected doCheck(field: IModelField<string, TModel>, model: TModel, value: string): string | null {
        if (value == "") {
            return null
        }
        return (value?.length ?? 0) > this.max ? `Must be less than ${this.max} characters.` : null;
    }
}

export class CharacterRequirement<TModel> extends ModelRule<string, TModel> {
    private readonly regex: RegExp
    public static readonly lowercaseCharacters = new CharacterRequirement<any>(new RegExp(/^.*[a-z]+.*$/), "Lowercase character required.")
    public static readonly uppercaseCharacters = new CharacterRequirement<any>(new RegExp(/^.*[A-Z]+.*$/), "Uppercase character required.")
    public static readonly numericCharacters = new CharacterRequirement<any>(new RegExp(/^.*[0-9]+.*$/), "Numeric character required.")
    public static readonly symbolCharacters = new CharacterRequirement<any>(new RegExp(/^.*[\^$*.\[\]{}()?"!@#%&\/\\,<>':;|_~`]+.*$/), "Symbol character required (\"^$*.[]{}()?\"!@#%&/\\,><':;|_~`\".")

    constructor(regex: RegExp, friendlyName: string) {
        super(friendlyName);
        this.regex = regex;
    }

    protected doCheck(field: IModelField<string, TModel>, model: TModel, value: string): string | null {
        if (!this.regex.test(value)) {
            return this.name
        }
        return null
    }
}

export class EnumValues<TModel> extends ModelRule<string, TModel> {
    private readonly enums: any[]
    private readonly values: string[]

    constructor(...enums: any) {
        super(`Enum values`)
        this.enums = enums;
        this.values = this.enums.flatMap(e => Object.keys(e).map(key => e[key]));
    }

    protected doCheck(field: IModelField<string, TModel>, model: TModel, value: string): string | null {
        if (value == "") {
            return null
        } else if (!this.values.includes(value)) {
            return `Invalid value "${value}".`
        }
        return null
    }
}

export class MultipleEnumValues<TModel> extends ModelRule<string[], TModel> {
    private readonly enums: any[]
    private readonly values: string[]

    constructor(...enums: any) {
        super(`Enum values`)
        this.enums = enums;
        this.values = this.enums.flatMap(e => Object.keys(e).map(key => e[key]));
    }

    protected doCheck(field: IModelField<string[], TModel>, model: TModel, value: string[]): string | null {
        if (Array.isArray(value)) {
            for (let v of value) {
                if (!this.values.includes(v)) {
                    return `Invalid value "${v}".`
                }
            }
        }
        return null
    }
}

export enum CharacterSetType {
    Any,
    // letters numbers and whitespace
    Basic,
    Email,
    BasicPlus,
}

export class CharacterSet<TModel> extends ModelRule<string, TModel> {
    private readonly set: CharacterSetType
    static readonly anyRegex = new RegExp(/^.*$/)
    static readonly basicRegex = new RegExp(/^[a-z0-9\s]+$/i)
    static readonly basicPlusRegex = new RegExp(/^[a-z0-9\s\-\_\.]+$/i)
    static readonly emailRegex: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    constructor(set: CharacterSetType) {
        super(`CharacterSet ${set}`);
        this.set = set;
    }

    protected doCheck(field: IModelField<string, TModel>, model: TModel, value: string): string | null {
        let reg: RegExp
        let msg: string
        switch (this.set) {
            case CharacterSetType.Any:
                reg = CharacterSet.anyRegex
                // shouldn't happen
                msg = "???"
                break;
            case CharacterSetType.Basic:
                reg = CharacterSet.basicRegex
                msg = "Only letters, numbers and spaces allowed."
                break;
            case CharacterSetType.Email:
                reg = CharacterSet.emailRegex
                msg = "Invalid email address."
                break;
            case CharacterSetType.BasicPlus:
                reg = CharacterSet.basicPlusRegex
                msg = "No special characters allowed."
                break;
        }

        if (!reg.test(value)) {
            return msg
        }

        return null
    }
}
