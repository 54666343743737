import {
    ApiResponse,
    autoBind,
    BaseModelSchema,
    CountItemsOptions,
    GenericItemRequest,
    IBaseModel,
    ICountApiService,
    IListApiService,
    IReadonlyApiService,
    ListPaginateOptions
} from "lib-shared"
import {ClientContext, fetchApiCount, fetchApiGet, fetchApiPaginated, listAll,} from "../FetchUtils"

export interface IReadonlyClient<TValue extends IBaseModel> extends IListApiService<TValue, ClientContext>, ICountApiService<TValue, ClientContext> {
    get schema(): BaseModelSchema<TValue>
}

export abstract class BaseReadOnlyClient<TValue extends IBaseModel> implements IReadonlyApiService<TValue, ClientContext>, IReadonlyClient<TValue> {
    protected readonly props: ClientProps

    protected constructor(props: ClientProps = {}) {
        this.props = props
        autoBind(this)
    }

    public abstract get schema(): BaseModelSchema<TValue>

    public get resourceName(): string {
        return this.schema.apiResourceName
    }

    async get(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<TValue>> {
        return await fetchApiGet<TValue>(`/${this.resourceName}/${req.id}`, req, ctx)
    }

    async adminListAll(req: ListPaginateOptions, ctx: ClientContext): Promise<ApiResponse<TValue[]>> {
        const result = await fetchApiPaginated<TValue>(`/admin/${this.resourceName}`, req, ctx)
        return result
    }

    listAll(req: ListPaginateOptions, ctx: ClientContext): Promise<ApiResponse<TValue[]>> {
        return listAll(this, req, ctx, this.props)
    }

    async list(req: ListPaginateOptions, ctx: ClientContext): Promise<ApiResponse<TValue[]>> {
        const result = await fetchApiPaginated<TValue>(`/${this.resourceName}`, req, ctx)
        return result
    }

    async count(req: CountItemsOptions, ctx: ClientContext): Promise<ApiResponse<number>> {
        const result = await fetchApiCount(`/${this.resourceName}/count`, req, ctx);
        return result
    }
}

export interface ClientProps {
    readonly addProgress?: ProgressUpdateFn
}

export type ProgressUpdateFn = (value: number) => void
