import {ChangeEvent} from "react";

function FormMultiToggleItem(props: Props) {

    return (
        <div className="d-flex align-items-center">
            <label htmlFor={props.id}>{props.label}</label>
            <div className="form-check form-switch ms-auto">
                <input className="form-check-input me-n2" type="checkbox"
                       onChange={() => props.callback(props.id)}
                       disabled={props.disabled}
                       name={props.name} id={props.id} checked={props.checked}/>
            </div>
        </div>
    )
}

interface Props {
    readonly id: string
    readonly name: string
    readonly label: string
    readonly checked: boolean
    readonly disabled: boolean
    readonly callback: (id: string) => void
}

export default FormMultiToggleItem