import {ReactNode} from "react";
import {Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {fixHref} from "../utils/Utils";
import NavbarUser from "../components/powerPage/NavbarUser";
import ZLink from "../components/common/ZLink";
import {getDefaultPageForAppMode} from "../AppMode";

function SimplePage(props: Props) {
    return (
        <div className="bg-body-tertiary h-100">
            <div className="py-3">
                <div className="flex-fill justify-content-end flex-nowrap gap-6 ms-auto px-6 px-xxl-8">
                    <NavbarUser enableOrgFeatures={false}/>
                </div>
            </div>
            <div className={"container pt-5"}>
                <Row>
                    <div className={"col-lg-6 offset-lg-3 col-sm-12"}>
                        <ZLink className="navbar-brand d-inline-block w-lg-64" href={getDefaultPageForAppMode()}>
                            <img src="/logo.png" className="h-rem-10 p-1" alt="ZENSEND"/>
                        </ZLink>
                        <div className="card p-5">
                            {props.children}
                        </div>
                    </div>
                </Row>
            </div>
        </div>
    )
}

interface Props {
    readonly children: ReactNode
}

export default SimplePage