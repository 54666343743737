import {
    FirstPartyField,
    FirstPartyLeadData,
    Lead,
    LeadGroup,
    LeadGroupMembership, LeadSchema,
} from "lib-shared";
import LoadingIndicator from "../../components/LoadingIndicator";
import {fixHref} from "../../utils/Utils";
import {linkToLeadEditor} from "../leads/list/LeadsPage";
import {useApiList} from "../../hooks/useApiList";
import {UserContext} from "../../context/UserContext";
import {useContext} from "react";
import {ApiResult} from "../../hooks/useApi";
import InfoTip from "../../components/common/InfoTip";
import {
    FirstPartyFieldsClient,
    FirstPartyLeadDataClient,
    LeadGroupMembershipsClient,
    LeadGroupsClient
} from "lib-client";

function LeadCard(props: Props) {
    const {clientContext} = useContext(UserContext)

    function renderLoading(): JSX.Element {
        return (
            <div className={"card"}>
                <div className={"card-body"}>
                    <h5>Lead</h5>
                    <LoadingIndicator/>
                </div>
            </div>
        )
    }

    if (!props.lead) {
        return renderLoading()
    }

    const memberships =
        useApiList<LeadGroupMembership>(clientContext, LeadGroupMembershipsClient.defaultClient, {
            filterFields: [{
                field: LeadSchema.LeadId.name,
                value: [props.lead?.lead_id]
            }]
        }, {prereqs: [props.lead?.lead_id]})

    const groupsResult = useApiList<LeadGroup>(clientContext, LeadGroupsClient.defaultClient, {}, {})

    const allFieldsResponse = useApiList<FirstPartyField>(clientContext, FirstPartyFieldsClient.defaultClient, {}, {})

    const fieldDataResult: ApiResult<FirstPartyLeadData[]> = useApiList<FirstPartyLeadData>(clientContext, FirstPartyLeadDataClient.defaultClient, {
        filterFields: [
            {
                field: LeadSchema.LeadId.name,
                value: [props.lead?.lead_id]
            }
        ]
    }, {prereqs: [props.lead?.lead_id]})

    if (!props.lead || memberships.isLoading || groupsResult.isLoading || allFieldsResponse.isLoading || fieldDataResult.isLoading) {
        return renderLoading()
    }

    const {lead} = props

    const groups: LeadGroup[] = memberships.value!.map(m => groupsResult.value!.find(g => g.lead_group_id == m.lead_group_id)!)

    let name = `${lead.first_name ?? "???"} ${lead.last_name ?? "???"}`
    if (!lead.first_name && !lead.last_name) {
        name = "NAME UNKNOWN"
    }

    const firstPartyData = fieldDataResult.value!

    return (
        <div className={"card"}>
            <div className={"card-body"}>
                <h4>{name}<a className={"ms-3"} href={fixHref(linkToLeadEditor(lead))} target={"_blank"}><i
                    className="bi bi-box-arrow-up-right"/></a></h4>
                <div className="text-muted text-lowercase mt-1">{lead.email}</div>
                <h5 className={"mt-4 mb-2"}>Groups</h5>
                {groups.map(group => {
                    return <a href={`/groups/${group.lead_group_id}`} target={"_blank"} key={group.name}>
                        <span className="badge text-bg-light me-2">{group.name}</span>
                    </a>
                })}
                <h5 className={"mt-4"}>Summary</h5>
                {lead.text_summary && <p>{lead.text_summary}</p>}
                {!lead.text_summary &&
                    <div className={"text-danger"}><i className="bi bi-exclamation-triangle-fill"></i> None</div>}
                <h5 className={"mt-4 mb-2"}>First Party Data</h5>
                {firstPartyData.length == 0 && "None"}
                {
                    firstPartyData.map(fieldData => {
                        const field = allFieldsResponse.value!.find(f => f.field_id == fieldData.field_id)!

                        return (
                            <div key={field.field_id} className="d-flex bg-body-secondary gap-3 rounded-3 p-4 mb-2">
                                <div className="flex-fill vstack gap-2">
                                    <div className="d-flex mb-1">
                                        <div className="">
                                            <span
                                                className="d-block text-heading text-sm fw-semibold">{field.field_id}</span>
                                            <span
                                                className="d-block text-muted text-xs mt-2">{fieldData.field_value}</span>
                                        </div>
                                        <div className="ms-auto fw-bold text-heading text-sm">
                                            <InfoTip placement={"left"}>{field.field_description}</InfoTip></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

interface Props {
    //null if loading
    readonly lead: Lead | null
}

export default LeadCard