import {User, UserId} from "./User";
import {Organization} from "./Organization";
import {BaseModelSchema, FieldClass, FieldSourceType, FieldType, IModelField} from "../validation/Validation";
import {EnumValues} from "../validation/Validators";
import {OrgId} from "./Model";


export interface QuotaConfig {
    readonly entity: EntityId
    readonly sku: SKU
    readonly quota: number
}

export type EntityId = string & { __brand: "entity_id" }

export function makeEntityIdStr(id: string): EntityId {
    if (id.startsWith("org:")) {
        return id as EntityId
    } else if (id.startsWith("user")) {
        return id as EntityId
    } else {
        throw new Error(`Invalid entity id prefix: '${id}'`)
    }
}

export function parseSKU(sku: string): SKU {
    if (!isValidSKU(sku as SKU)) {
        throw new Error(`Invalid sku: '${sku}'`)
    }
    return sku as SKU
}

export function makeUserEntityId(id: UserId): EntityId {
    if (id == null) {
        throw new Error("user id cannot be null")
    }
    return ("user:" + id) as EntityId
}

export function makeOrgEntityId(id: OrgId): EntityId {
    if (id == null) {
        throw new Error("org id cannot be null")
    }
    return ("org:" + id) as EntityId
}

export function makeEntityId(entity: User | Organization): EntityId {
    if ("user_id" in entity) {
        return makeUserEntityId(entity.user_id)
    } else {
        return makeOrgEntityId(entity.org_id)
    }
}

export enum SKU {
    DEMO_WIDGET_1 = "demo_widget_1",
}

export function getDefaultQuotaForSKU(sku: SKU): number {
    switch (sku) {
        case SKU.DEMO_WIDGET_1:
            return 10
        default:
            return 0
    }
}

export function isValidSKU(sku: SKU): boolean {
    return Object.values(SKU).find(s => s == sku) != undefined
}

export function makeQuotaId(entity: EntityId, sku: SKU) {
    return `/quota/entity/${entity}/sku/${sku}`
}

export function getIdFromEntity(entityId: EntityId): ["org" | "user", string] {
    const split = entityId.split(":")

    if (split.length != 2) {
        throw new Error(`Failed to parse entity id '${entityId}'`)
    }

    const [tpe, id] = split

    if (tpe == "org") {
        return ["org", id]
    } else if (tpe == "user") {
        return ["user", id]
    } else {
        throw new Error(`Unknown entity type '${tpe}' in '${entityId}'`)
    }
}

export function parseQuotaId(id: string): [EntityId, SKU] {
    // const regexp = /\/quota\/entity\/(a-zA-Z\d-:]+)\/sku\/(a-zA-Z\d_-)/g;
    const regexp = /\/quota\/entity\/([a-zA-Z\d-:]+)\/sku\/([a-zA-Z\d_-]+)/g;
    const matches = id.matchAll(regexp);

    const match = matches.next().value as RegExpMatchArray


    if (match == undefined) {
        throw new Error(`Failed to parse quota id '${id}'`)
    }

    const entity = makeEntityIdStr(match[1])
    const sku = parseSKU(match[2])
    return [entity, sku]
}

export class QuotaSchemaDef extends BaseModelSchema<QuotaConfig> {
    get SchemaName(): string {
        return "NOT USED"
    }

    getFriendlyName(value: QuotaConfig): string {
        return value.entity + " " + value.sku
    }

    get apiResourceName(): string {
        return "quota";
    }

    get KeyColumns(): IModelField<any, QuotaConfig>[] {
        return [this.Entity];
    }

    public readonly Entity = FieldClass.createIdField("entity")
    public readonly Quota = new FieldClass<number, QuotaConfig>({
        field_type: FieldType.TEXT,
        display_name: "Generation Category",
        name: "generation_category",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: []
    })

    public readonly Sku = new FieldClass<string, QuotaConfig>({
        field_type: FieldType.TEXT,
        display_name: "SKU",
        name: "sku",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [
            new EnumValues(SKU)
        ]
    })
}

export const QuotaSchema: QuotaSchemaDef = new QuotaSchemaDef()
