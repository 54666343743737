export const eventBus: IEventBus = {
    addListener(event: EventType, callback: EventHandler): void {
        document.addEventListener(event, (e: Event) => {
            if (e instanceof CustomEvent)
                callback(e.detail)
            else
                callback(undefined)
        })
    },
    removeListener(event: EventType, callback: EventHandler): void {
        document.removeEventListener(event, callback);
    },
    trigger(event: EventType, data: any): void {
        document.dispatchEvent(new CustomEvent(event, {detail: data}));
    }

}

interface IEventBus {
    trigger(event: EventType, data: any): void

    addListener(event: EventType, callback: EventHandler): void

    removeListener(event: EventType, callback: EventHandler): void
}

export type EventHandler = (data: any) => void

export type EventType = "item-changed"