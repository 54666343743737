import {DemoWidgetPath} from "./routes/demoWidget/DemoWidget";

export enum AppMode {
    // don't use spaces - keep it url param safe
    NORMAL = "normal",
    DEMO_WIDGET_1 = "demo-widget-1",
}

const AppModeUrlParam: string = "mode"

export function getAppMode(url: URL): AppMode {
    if (url.searchParams.has(AppModeUrlParam)) {
        try {
            const result = (url.searchParams.get(AppModeUrlParam) as AppMode) ?? AppMode.NORMAL
            // we need to validate the AppMode type because javascript...
            if (Object.values(AppMode).find(mode => mode == result)) {
                return result
            } else {
                console.warn(`Invalid app mode: ${result}`)
                return AppMode.NORMAL
            }
        } catch (e) {
            console.error("Failed to parse app mode - defaulting to normal")
            console.error(e)
            return AppMode.NORMAL
        }
    } else {
        return AppMode.NORMAL
    }
}

export function setAppMode(url: URL, mode: AppMode): void {
    if (mode == AppMode.NORMAL) {
        // don't show app mode for normal
        url.searchParams.delete(AppModeUrlParam)
        return
    }
    url.searchParams.set(AppModeUrlParam, mode.toString())
}

export function getDefaultPageForAppMode(): string {
    const mode = getAppMode(new URL(window.location.href))
    switch (mode) {
        case AppMode.NORMAL:
            return "/"
        case AppMode.DEMO_WIDGET_1:
            return DemoWidgetPath
    }
}