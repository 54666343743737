import MaterialIcon from "./MaterialIcon";

function IconButtonMinimalist(props: Props) {
    return (
        <span className={`${props.className} clickable`} onClick={props.action}>
            <MaterialIcon
                icon={props.icon}
                margin={2}
                size={16}/>
        </span>
    )
}

interface Props {
    readonly className?: string
    readonly action: () => void
    readonly icon: string
}

export default IconButtonMinimalist