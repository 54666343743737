import Button from "react-bootstrap/Button";
import {CampaignsClient, DraftsClient, LeadsClient, SenderProfilesClient} from "lib-client";
import {useContext} from "react";
import {UserContext} from "../../context/UserContext";
import {ApiResponse, Draft, getResultOrNull, isSuccess} from "lib-shared";
import {OrgContext} from "../../context/OrgContext";
import {generateName} from "../../utils/NameGenerator";
import {v4 as uuidv4} from "uuid"
import {toastIfError} from "../../context/toasts/ToastManager";

function AdminDrafts(props: Props) {
    const {clientContext} = useContext(UserContext);
    const {org} = useContext(OrgContext);

    function randomItem<T>(list: ApiResponse<T[]>): T {
        const result = getResultOrNull(list)
        if (result == null)
            throw Error("Api Response not successful")
        return result[Math.floor(Math.random() * result.length)]
    }

    async function AddDraft() {

        const campaigns = await new CampaignsClient().list({}, clientContext)
        const senders = await new SenderProfilesClient().list({}, clientContext)
        const leads = await new LeadsClient().list({}, clientContext)

        const campaign = randomItem(campaigns)
        const sender = randomItem(senders)
        const lead = randomItem(leads)

        const subject = generateName()
        const draft: Draft = {
            org_id: org!.org_id,
            campaign_id: campaign.campaign_id,
            draft_id: uuidv4(),
            lead_id: lead.lead_id,
            body: "This is an email body",
            subject: subject,
            generation_category: "generatedWithName",
            greeting: `Hey ${lead.first_name},`,
            closing: `Sincerely,\n${sender.first_name}`,
            state: "ready"

        }
        const response = await new DraftsClient().create(draft, clientContext)
        if (!isSuccess(response)) {
            toastIfError(clientContext, response)
            return
        }

        clientContext.toastProps.toast({title: "Draft Added!", body: "Successfully added draft."})
    }

    return (
        <div>
            <Button onClick={AddDraft}>Add Draft</Button>
        </div>
    )
}

interface Props {

}

export default AdminDrafts