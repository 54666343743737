import {
    IEmailProvidersService,
    ApiResponse,
    EmailProvider,
    GenericItemRequest,
    FirstPartyFieldSchema, EmailProviderSchema, ModelSchema
} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {fetchApi, sendApi, ClientContext} from "../FetchUtils";
import {ClientProps} from "./BaseReadOnlyClient";

export class EmailProvidersClient extends BaseCrudClient<EmailProvider> implements IEmailProvidersService<ClientContext> {
    public static readonly defaultClient: EmailProvidersClient = new EmailProvidersClient()
    public get schema(): ModelSchema<EmailProvider> {
        return EmailProviderSchema
    }

    test(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return fetchApi(`/${this.resourceName}/${req.id}/test`, ctx)
    }

    forceRefreshAuth(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return sendApi(`/${this.resourceName}/${req.id}/forceRefreshAuth`, "POST", undefined, ctx)
    }
}