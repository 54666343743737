import { ReactNode } from "react"
import {Link} from "react-router-dom"
import {fixHref} from "../../utils/Utils";

function ZLink(props: Props) {

    return (
        <Link to={fixHref(props.href)} className={props.className}>{props.children}</Link>
    )
}

interface Props {
    readonly href: string
    readonly children: ReactNode
    readonly className?: string
}

export default ZLink