import {UserContext} from "../../context/UserContext";
import {eventBus} from "../../utils/EventBus";
import AppModal from "../../components/AppModal";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {useContext} from "react";
import {isSuccess, SegmentationClass, SegmentationClassSchema} from "lib-shared";
import {SegmentationClassClient} from "lib-client";
import {Modal} from "react-bootstrap";
import {useApiGet} from "../../hooks/useApiGet";
import {toastIfError} from "../../context/toasts/ToastManager";

function SegmentationClassEditorModal(props: Props) {
    const {fbUser, clientContext, appUser} = useContext(UserContext);

    const isCreate = props.edit_id == "";
    const {show, close} = props;

    const initialFieldValue = useApiGet<SegmentationClass>(
        clientContext,
        SegmentationClassClient.defaultClient,
        props.edit_id,
    );

    const cancel = () => {
        close();
    };

    async function submit(
        payload: FormSubmitPayload<SegmentationClass>,
    ): Promise<boolean> {
        const segmentationClass: SegmentationClass = {
            ...payload.value,
            org_id: clientContext.org_id!,
        };

        const response = isCreate
            ? await new SegmentationClassClient().create(
                segmentationClass,
                clientContext,
            )
            : await new SegmentationClassClient().update(
                segmentationClass,
                clientContext,
            );

        if (!isSuccess(response)) {
            toastIfError(clientContext, response);
            return false;
        }

        if (props.createFieldCallback) props.createFieldCallback(segmentationClass);

        close();

        eventBus.trigger("item-changed", segmentationClass);
        return true
    }

    return (
        <AppModal show={show} onHide={cancel} name={"field editor"}>
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? "New" : "Edit"} Class</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppForm
                    onSubmit={submit}
                    onCancel={cancel}
                    initialValueLazy={initialFieldValue}
                    schema={SegmentationClassSchema}
                    fields={[
                        {
                            fieldType: "text",
                            tip: "1-2 words to unique identify a segment (e.g. Luxury)",
                            field: SegmentationClassSchema.Name,
                        },
                        {
                            fieldType: "text",
                            tip: "Description of the segment (e.g. likely to buy luxury goods)",
                            field: SegmentationClassSchema.Description,
                        },
                    ]}
                />
            </Modal.Body>
        </AppModal>
    )
}

interface Props {
    show: boolean;
    createFieldCallback?: (field: SegmentationClass) => void;
    close: () => void;
    edit_id: string | null;
}

export default SegmentationClassEditorModal;
