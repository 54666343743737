import {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {FormFieldComponent} from "./FormFields";
import FormLabel from "./FormLabel";

function FormToggle<T>(props: Props<T>) {

    const [value, setValue] = useState<boolean>(props.initialValue)

    useEffect(() => {
        setValue(props.initialValue)
    }, [props.initialValue]);

    return (
        <Form.Group className="mb-3" controlId={props.field.name}>
            <FormLabel field={props.field}
                       value={value}
                       tip={props.tip}
                       labelSubText={props.labelSubText}
                       errors={props.errors}/>
            <div className="input-group">
                <div className="form-check form-switch ms-auto">
                    <input className="form-check-input me-n2" type="checkbox"
                           onChange={(e) => setValue(e.target.checked)}
                           disabled={props.disabled}
                           autoFocus={props.index == 1}
                           tabIndex={props.index}
                           name={props.field.display_name} id={props.field.name} checked={value}/>
                </div>
            </div>
        </Form.Group>
    )
}

interface Props<T> extends FormFieldComponent<boolean, T> {
}

export default FormToggle