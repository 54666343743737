import {Model} from "../models/Model";
import {FieldClass, FieldSourceType, FieldType} from "./Validation";
import {Required} from "./Validators";

export function makeIsArchivedField<T extends Model>(): FieldClass<boolean, T> {
    return new FieldClass<boolean, T>({
        field_type: FieldType.TEXT,
        display_name: "Is Archived",
        name: "is_archived",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [
            new Required<T>(),
        ],
    })
}