import {createContext, ReactNode, useState} from "react"
import {UserContext} from "./UserContext";


export const MultiModalContext = createContext<ModalContextProps>({
    activeId: null,
    closeModal: (s: string) => {
        console.error(`closeModal not ready for ${s}`)

    },
    addModal: (s: string) => {
        console.error(`addModal not ready for ${s}`)
    }
});

function MultiModalManager(props: Props) {

    const [stack, setStack] = useState<string[]>([])

    function addModal(id: string) {
        if (stack.includes(id)) {
            console.error(`modal stack already includes id: ${id}`)
        }
        // add to end and clear any pre-existing entries
        setStack([...stack, id])
    }

    function closeModal(id: string) {
        if (stack.includes(id)) {
            console.log(`Closing modal ${id}`)
            setStack([...stack.filter(s => s != id)])
        }
    }

    const value: ModalContextProps = {
        activeId: stack.length > 0 ? stack[stack.length - 1] : null,
        closeModal,
        addModal,
    }

    // console.log(`ModalManager activeId: ${value.activeId} stack size: ${stack.length}`)

    return (
        <MultiModalContext.Provider value={value}>
            {props.children}
        </MultiModalContext.Provider>
    );
}

export interface ModalContextProps {
    readonly activeId: string | null
    readonly closeModal: (id: string) => void
    readonly addModal: (id: string) => void
}

interface Props {
    children?: ReactNode
}

export default MultiModalManager