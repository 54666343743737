import * as React from "react";
import {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import Button, {ButtonProps as ButtonProps} from "../common/Button";
import {FormFieldComponent, FormValueSelectItem} from "./FormFields";
import FormLabel from "./FormLabel";

function FormSelect<TModel>(props: Props<TModel>) {
    const [value, setValue] = useState<string>(props.initialValue)

    useEffect(() => {
        console.log(`setting initial value: ${props.initialValue}`)
        setValue(props.initialValue)
    }, [props.initialValue, props.options.length]);

    const hasButton: boolean = props.buttonProps !== undefined

    return (
        <Form.Group className="mb-3" controlId={props.field.name}>
            <FormLabel field={props.field}
                       tip={props.tip}
                       value={value}
                       labelSubText={props.labelSubText}
                       errors={props.errors}/>
            <div className="input-group">
                <select
                    aria-label={props.field.display_name}
                    className="form-select" id={props.field.name} value={value}
                    autoComplete={"none"}
                    autoFocus={props.index == 1}
                    tabIndex={props.index}
                    disabled={props.disabled}
                    onChange={(e) => setValue(e.target.value)}
                >
                    {props.options.map(option =>
                        <option key={option.id} value={option.id}>{option.label}</option>)
                    }
                </select>
                {hasButton && props.buttonProps &&
                    <div className={"input-group-append ms-2"}><Button {...props.buttonProps}/></div>}
            </div>
        </Form.Group>
    )
}

interface Props<TModel> extends FormFieldComponent<string, TModel> {
    readonly options: FormValueSelectItem[]
    readonly buttonProps?: ButtonProps
}

export default FormSelect