import {IUsersService, ModelSchema, User, UserSchema} from "lib-shared";
import {ClientContext} from "../FetchUtils";
import {BaseCrudClient} from "./BaseCrudClient";

export class UsersClient extends BaseCrudClient<User> implements IUsersService<ClientContext> {
    public static readonly defaultClient: UsersClient = new UsersClient()

    get schema(): ModelSchema<User> {
        return UserSchema;
    }
}
