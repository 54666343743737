import SelectGroup from "../../../components/SelectGroup";
import * as React from "react";
import {LeadGroup} from "lib-shared";

function WizardSectionGroup(props: Props) {
    return (
        <div>
            <p className={"text-muted mb-3 mt-3"}>You can optionally add these leads to a group.</p>
            <SelectGroup callback={props.setGroup} group={props.group} allowNull={true}/>
        </div>
    )
}

interface Props {
    group: LeadGroup | null
    setGroup: (g: LeadGroup | null) => void
}

export default WizardSectionGroup