import {BaseCrudClient} from "./BaseCrudClient";
import {
    AddSegmentToGroupRequest,
    ApiResponse,
    GenericItemRequest,
    ISegmentationClassService,
    ModelSchema,
    SegmentationClass,
    SegmentationClassSchema
} from "lib-shared";
import {ClientContext, sendApi, sendArchive, sendUnarchive} from "../FetchUtils";

export class SegmentationClassClient
    extends BaseCrudClient<SegmentationClass>
    implements ISegmentationClassService<ClientContext> {
    public static readonly defaultClient: SegmentationClassClient = new SegmentationClassClient()

    async addSegmentToGroup(req: AddSegmentToGroupRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendApi<AddSegmentToGroupRequest, void>(`/${this.resourceName}/addSegmentToGroup`, "POST", req, ctx);
    }

    get schema(): ModelSchema<SegmentationClass> {
        return SegmentationClassSchema;
    }

    async archive(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendArchive(this, req, ctx)
    }

    async unarchive(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendUnarchive(this, req, ctx)
    }

}
