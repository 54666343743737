import {FormValueSelectItem} from "../../../components/forms/FormFields";
import {
    FieldClass,
    FieldSourceType,
    FieldType,
    IModelSchema,
    LeadGroup,
    LeadGroupMembership, LeadSchema,
    IModelField, isSuccess, getResultOrNull
} from "lib-shared";
import AppForm, {FormSubmitPayload} from "../../../components/forms/AppForm";
import {mapApiResult, useApiList} from "../../../hooks/useApiList";
import {UserContext} from "../../../context/UserContext";
import {useContext} from "react";
import {LeadGroupMembershipsClient, LeadGroupsClient} from "lib-client";
import {toastIfError} from "../../../context/toasts/ToastManager";

function LeadGroupsEditor(props: Props) {
    const {clientContext} = useContext(UserContext)

    const {leadId} = props

    const state = mapApiResult<LeadGroupMembership[], LeadMemberships>(
        useApiList<LeadGroupMembership>(clientContext, LeadGroupMembershipsClient.defaultClient, {
            filterFields: [{
                field: LeadSchema.LeadId.name,
                value: [leadId]
            }]
        }, {}),
        (c: LeadGroupMembership[] | null) => {
            return {
                groups: (c ?? []).map(i => i.lead_group_id)
            }
        }
    )

    const groupsResult = useApiList<LeadGroup>(clientContext, LeadGroupsClient.defaultClient, {}, {})

    const groups = groupsResult.value ?? []

    const toggles: FormValueSelectItem[] = groups.map(g => {
        return {
            id: g.lead_group_id,
            label: g.name
        }
    })

    const membershipsField = new FieldClass<string[], LeadMemberships>({
        display_name: "Groups",
        name: "groups",
        field_source: FieldSourceType.USER_DEFINED,
        field_type: FieldType.SELECT
    })

    const schema: IModelSchema<LeadMemberships> = {
        getFields(): IModelField<any, LeadMemberships>[] {
            return [membershipsField];
        },
        SchemaName: "Group Membership"
    }

    async function save(payload: FormSubmitPayload<LeadMemberships>): Promise<boolean> {
        const memberships = payload.value
        const newMemberships: LeadGroupMembership[] = memberships.groups.map(g => {
            return {
                org_id: clientContext.org_id!,
                lead_id: leadId,
                lead_group_id: g,
            }
        })

        const response = await new LeadGroupMembershipsClient().list({
            filterFields: [{
                field: LeadSchema.LeadId.name,
                value: [leadId]
            }]
        }, clientContext)

        const originalMemberships = getResultOrNull(response)
        if (!originalMemberships) {
            return Promise.resolve(false)
        }

        const membershipsResult = await new LeadGroupMembershipsClient().setIds(
            originalMemberships, newMemberships, (l) => l.lead_group_id,
            // there is nothing to update with these so just return false
            (a: LeadGroupMembership, b: LeadGroupMembership) => false, clientContext)


        if (!isSuccess(membershipsResult)) {
            toastIfError(clientContext, membershipsResult)
            return Promise.resolve(false)
        }

        return Promise.resolve(true)
    }

    return (
        <div>
            <AppForm onSubmit={save}
                     schema={schema}
                     initialValueLazy={state}
                     fields={[
                         {
                             fieldType: "multi-select",
                             field: membershipsField,
                             options: toggles,
                         }
                     ]}
            />
        </div>
    )
}

interface LeadMemberships {
    readonly groups: string[]
}

interface Props {
    leadId: string
}

export default LeadGroupsEditor