import {useEffect, useRef} from "react";

function useAutosave(
    id: string,
    data: string,
    onSave: () => void,
    intervalMs: number = 5000
) {

    const lastId = useRef("")
    const lastData = useRef("")
    const flagReg = useRef(false)
    const lastWriteTime = useRef(0)
    const saveRef = useRef(onSave)

    function check() {
        if (!flagReg.current)
            return

        if (new Date().getTime() < lastWriteTime.current + intervalMs) {
            console.log("Autosave flag dirty but not inactive")
            return
        }

        save()
    }

    function save() {
        if (!flagReg.current)
            return
        console.log("Auto saving!")
        saveRef.current()
        flagReg.current = false
    }

    useEffect(() => {
        const interval = setInterval(check, intervalMs);

        return () => clearInterval(interval);
    }, []);

    // update save ref - otherwise we'll invoke "onSave" on an old function ref which is wack but that's react
    useEffect(() => {
        saveRef.current = onSave
    }, [onSave]);

    useEffect(() => {
        if (lastId.current != id) {
            save()
            lastId.current = id
            flagReg.current = false
            lastData.current = data
            return
        }

        if (lastData.current != data) {
            console.log("Autosave dirty")
            lastWriteTime.current = new Date().getTime()
            flagReg.current = true
            lastData.current = data
        }
    }, [data]);


    useEffect(() => {
        // just return save so we can save on unmount
        return () => {
            console.log("Disconnecting...")
            save()
        }
    }, []);

}

export default useAutosave