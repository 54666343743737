import {makeUserEntityId, makeUserId, SKU} from "lib-shared";
import {UserContext} from "../../context/UserContext";
import {useContext} from "react";
import {useApi} from "../../hooks/useApi";
import {DemoWidgetClient, QuotaClient} from "lib-client";
import Button from "../../components/common/Button";
import {toastResult} from "../../context/toasts/ToastManager";

function DemoWidget() {

    const {clientContext} = useContext(UserContext);

    const entityId = makeUserEntityId(makeUserId(clientContext.user?.uid))
    const quota = useApi(clientContext, (cc) => QuotaClient.defaultClient.getRemainingQuota({
        entity: entityId,
        sku: SKU.DEMO_WIDGET_1
    }, cc), [entityId])

    async function demo() {
        const result = await DemoWidgetClient.defaultClient.demo(undefined, clientContext)
        toastResult(result, clientContext)
        // update quota
        quota.rerun()
        console.log("Demo complete!")
    }

    return (
        <div>
            <div>Demo Widget!</div>
            <div>Uses Left: {quota.value?.remaining}/{quota.value?.total}</div>

            <Button variant={"primary"} label={"Demo"} onClick={demo}/>
        </div>
    )
}

export const DemoWidgetPath = "/demo-widget"

export default DemoWidget