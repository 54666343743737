import * as React from "react";
import {useContext, useState} from "react";
import {getResultOrNull, Lead, LeadGroup, LeadGroupMembership, LeadGroupSchema, LeadSchema} from "lib-shared";
import {useParams} from "react-router-dom";
import EditLeadModal from "../../leads/EditLeadModal";
import IconButton from "../../../components/common/IconButton";
import {UserContext} from "../../../context/UserContext";
import {ColumnSpec} from "../../../components/tables/BaseTable";
import PowerPageContent from "../../../components/powerPage/PowerPageContent";
import {useApiGet} from "../../../hooks/useApiGet";
import {useAppNav} from "../../../hooks/useAppNav";
import ListPickerModal from "../../../components/listPicker/ListPickerModal";
import {LeadGroupMembershipsClient, LeadGroupsClient, LeadsClient} from "lib-client";
import {eventBus} from "../../../utils/EventBus";
import SlimAttribute from "../../../components/metrics/SlimAttribute";
import ApiTable, {ApiOrderBy, createColumnFromNested} from "../../../components/tables/ApiTable";
import {useQueryState} from "../../../hooks/useQueryParam";
import {LeadEditorKey, LeadFilters} from "../../leads/list/LeadsPage";
import {toastIfError} from "../../../context/toasts/ToastManager";
import NotSummarizedMetric from "../../leads/NotSummarizedMetric";
import SpinnerOrSuccess from "../../../components/common/SpinnerOrSuccess";

function ViewGroupPage() {
    const {clientContext} = useContext(UserContext)
    const navigate = useAppNav()
    const params = useParams();
    const groupId = params["groupId"]

    if (!groupId) {
        navigate("/groups")
        return <></>
    }

    const groupsResponse = useApiGet<LeadGroup>(clientContext, LeadGroupsClient.defaultClient, groupId)
    const [editLeadId, setEditLeadId] = useQueryState(LeadEditorKey);
    const [showAddLead, setShowAddLead] = useState(false);

    const group: LeadGroup | null = groupsResponse.value

    if (!groupsResponse.isLoading && group == null) {
        console.error(`Group ${groupId} not found`)
        navigate("/groups")
    }


    const columns: ColumnSpec<LeadGroupMembership, ApiOrderBy>[] = [
        createColumnFromNested(LeadSchema.LastName, true, mem => mem.lead),
        createColumnFromNested(LeadSchema.FirstName, true, mem => mem.lead),
        createColumnFromNested(LeadSchema.Email, true, mem => mem.lead),
        {
            name: "Summarized & Researched",
            defaultColumn: true,
            cellExtractor: membership => {
                const isSummarized = membership.lead?.text_summary != null ?? false
                const isResearched = membership.lead?.is_researched ?? false
                return {
                    value: (isSummarized && isResearched).toString(),
                    displayValue: <SpinnerOrSuccess
                        statuses={[isSummarized, isResearched]}/>
                }
            },
        },
        createColumnFromNested(LeadSchema.UpdatedAt, true, mem => mem.lead),
        createColumnFromNested(LeadSchema.CreatedAt, false, mem => mem.lead),
    ]

    async function addLeadToGroup(lead: Lead | null) {
        if (lead == null) {
            setShowAddLead(false)
            return
        }
        const membership: LeadGroupMembership = {
            lead_group_id: groupId!,
            lead_id: lead.lead_id,
            org_id: clientContext.org_id!

        }
        const response = await new LeadGroupMembershipsClient().create(membership, clientContext)

        const create = getResultOrNull(response)
        if (!create) {
            toastIfError(clientContext, response)
            return
        }
        eventBus.trigger("item-changed", lead)
        setShowAddLead(false)
    }

    return (
        <PowerPageContent title={`${group?.name ?? ""} Group`}
                          description={`Leads in the ${group?.name ?? ""} group.`}
                          parentHref={"/groups"}
                          buttons={[
                              <IconButton action={() => setShowAddLead(true)} icon={"person-add"} label={"Add Lead"}/>
                          ]}>
            <NotSummarizedMetric group_id={groupId}/>
            {group?.prospecting_job_id &&
                <SlimAttribute label={"This group was created by a prospecting job"} color={undefined}
                               icon={"binoculars-fill"}/>}
            <ApiTable<LeadGroupMembership> client={LeadGroupMembershipsClient.defaultClient}
                                           listOptions={{
                                               fullyHydrated: true,
                                               filterFields:
                                                   [
                                                       // limit filter to just this group
                                                       {
                                                           field: LeadGroupSchema.LeadGroupId.name,
                                                           value: [groupId]
                                                       }
                                                   ]
                                           }}
                                           noDataMessage={"This group has no members yet."}
                                           tableSettings={{
                                               idExtractor: membership => membership.lead_id,
                                               filterOptions: LeadFilters,
                                               rowClickCallback: (lead) => setEditLeadId(lead.lead_id),
                                               columns: columns,
                                           }}
            />
            <EditLeadModal leadId={editLeadId} close={() => setEditLeadId(null)}/>
            <ListPickerModal show={showAddLead}
                             title={`Add lead`}
                             description={`Add lead to the ${group?.name} group.`}
                             client={LeadsClient.defaultClient}
                             filters={[]}
                             getId={l => l.lead_id}
                             mapper={l => `${l.email} - ${l.first_name} ${l.last_name}`}
                             callback={addLeadToGroup}/>
        </PowerPageContent>
    )
}

export default ViewGroupPage