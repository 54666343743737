import {
    ApiResponse,
    ISocialMediaProfilesService,
    LoadSocialMediaPostsRequest,
    ModelSchema, SetRequest,
    SocialMediaProfile, SocialMediaProfileKey,
    SocialMediaProfileSchema
} from "lib-shared";
import {ClientContext, sendApi} from "../FetchUtils";
import {BaseCrudClient} from "./BaseCrudClient";


export class SocialMediaProfilesClient extends BaseCrudClient<SocialMediaProfile> implements ISocialMediaProfilesService<ClientContext> {
    public static readonly defaultClient: SocialMediaProfilesClient = new SocialMediaProfilesClient()

    get schema(): ModelSchema<SocialMediaProfile> {
        return SocialMediaProfileSchema;
    }

    refresh(profile: SocialMediaProfileKey, ctx: ClientContext): Promise<ApiResponse<void>> {
        return sendApi<SocialMediaProfileKey, void>(`/${this.resourceName}/refresh`, "POST", profile, ctx)
    }
}