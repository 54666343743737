import { useQueryState } from "../../hooks/useQueryParam";
import { ColumnSpec, RowData } from "../../components/tables/BaseTable";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import IconButton from "../../components/common/IconButton";
import { FirstPartyField, FirstPartyFieldSchema, LeadSchema } from "lib-shared";
import FirstPartyFieldEditorModal from "./FirstPartyFieldEditorModal";
import { ConfirmModalContext } from "../../context/confirmModal/ConfirmModalContext";
import { useContext } from "react";
import ApiTable, {
  ApiOrderBy,
  createColumn,
} from "../../components/tables/ApiTable";
import { TableAction } from "../../components/tables/TableActions";
import {FirstPartyFieldsClient} from "lib-client";

export const FirstPartyFieldEditorKey = "field_id";

function FirstPartyFieldsPage(props: Props) {
  const { confirm } = useContext(ConfirmModalContext);
  const [editId, setEditId] = useQueryState(FirstPartyFieldEditorKey);

  const columns: ColumnSpec<FirstPartyField, ApiOrderBy>[] = [
    createColumn(FirstPartyFieldSchema.FieldId, true),
    createColumn(FirstPartyFieldSchema.DisplayOnly, true),
    createColumn(FirstPartyFieldSchema.CreatedAt, true),
    createColumn(FirstPartyFieldSchema.UpdatedAt, false),
  ];

  function actionCreator(fieldIds: FirstPartyField[]): TableAction[] {
    return [
      {
        icon: "pencil",
        label: "Edit",
        supportsMultiRows: false,
        action: () => {
          setEditId(fieldIds[0].field_id);
        },
      },
      {
        icon: "trash",
        label: "Delete",
        color: "danger",
        supportsMultiRows: true,
        action: () => {
          confirm({
            action_type: "delete",
            resources: fieldIds.map((f) => {
              return { id: f.field_id, name: f.field_id };
            }),
            client: FirstPartyFieldsClient.defaultClient,
          });
        },
      },
    ];
  }

  return (
    <PowerPageContent
      title={"First Party Fields"}
      buttons={[
        <IconButton
          key={"create-job"}
          action={() => setEditId("")}
          icon={"plus-circle"}
          label={"New Field"}
        />,
      ]}
    >
      <ApiTable<FirstPartyField>
        client={FirstPartyFieldsClient.defaultClient}
        noDataMessage={"No Fields yet."}
        tableSettings={{
          columns,
          idExtractor: (fpf) => fpf.field_id,
          filterOptions: [],
          actionCreator: actionCreator,
          rowClickCallback: (field: FirstPartyField) =>
            setEditId(field.field_id),
        }}
      />
      <FirstPartyFieldEditorModal
        show={editId != null}
        edit_id={editId}
        close={() => setEditId(null)}
      />
    </PowerPageContent>
  );
}

interface Props {}

export default FirstPartyFieldsPage;
