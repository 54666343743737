import {IArchivable, OrgModel} from "./Model";
import {EmailProvider, EmailProviderType} from "./EmailProvider";
import {
    FieldClass,
    FieldSourceType,
    FieldType,
    IModelField,
    ModelSchema, OrgModelSchema
} from "../validation/Validation";
import {CharacterSet, CharacterSetType, EnumValues, Required} from "../validation/Validators";
import {makeIsArchivedField} from "../validation/ValidationUtils";

export interface SenderProfile extends OrgModel, IArchivable {
    readonly sender_profile_id: string
    readonly email_provider_id: string | null
    readonly first_name: string
    readonly last_name: string
    readonly company_name: string
    readonly email: string
    readonly job_title: string
    readonly email_provider_type: SenderEmailProviderType | EmailProviderType
    // hydrated
    readonly email_provider?: EmailProvider
}

export enum SenderEmailProviderType {
    // for CSV
    Manual = "manual"
}


export class SenderProfileSchemaDef extends OrgModelSchema<SenderProfile> {
    get SchemaName(): string {
        return "SenderProfile"
    }

    getFriendlyName(value: SenderProfile): string {
        return value.email;
    }

    get apiResourceName(): string {
        return "senderProfiles";
    }

    get KeyColumns(): IModelField<any, SenderProfile>[] {
        return [this.SenderProfileId];
    }

    public readonly IsArchived = makeIsArchivedField()

    public readonly SenderProfileId = new FieldClass<string, SenderProfile>({
        field_type: FieldType.TEXT,
        display_name: "sender_profile_id",
        name: "sender_profile_id",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [
            new Required<SenderProfile>(),
        ]
    })

    public readonly FirstName = new FieldClass<string, SenderProfile>({
        field_type: FieldType.TEXT,
        display_name: "First Name", name: "first_name", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<SenderProfile>(),
        ]
    })

    public readonly LastName = new FieldClass<string, SenderProfile>({
        field_type: FieldType.TEXT,
        display_name: "Last Name", name: "last_name", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<SenderProfile>(),
        ]
    })

    public readonly CompanyName = new FieldClass<string, SenderProfile>({
        field_type: FieldType.TEXT,
        display_name: "Company Name", name: "company_name", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<SenderProfile>(),
        ]
    })

    public readonly JobTitle = new FieldClass<string, SenderProfile>({
        field_type: FieldType.TEXT,
        display_name: "Job Title", name: "job_title", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<SenderProfile>(),
        ]
    })

    public readonly Email = new FieldClass<string, SenderProfile>({
        field_type: FieldType.TEXT,
        display_name: "Email", name: "email", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<SenderProfile>(),
            new CharacterSet(CharacterSetType.Email)
        ]
    })

    // todo support enum validation
    public readonly EmailProviderId = new FieldClass<string, SenderProfile>({
        field_type: FieldType.TEXT,
        display_name: "Email Provider", name: "email_provider_id", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<SenderProfile>(),
        ]
    })

    public readonly EmailProviderType = new FieldClass<string, SenderProfile>({
        field_type: FieldType.TEXT,
        display_name: "Email Provider Type",
        name: "email_provider_type",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [
            new EnumValues<SenderProfile>(EmailProviderType, SenderEmailProviderType),
        ]
    })
}

export const SenderProfileSchema: SenderProfileSchemaDef = new SenderProfileSchemaDef()

export type IntegrationType = "manual" | EmailProviderType