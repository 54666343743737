import OrgButton from "../orgPicker/OrgButton";
import NavbarUser from "./NavbarUser";

function PowerTopBar() {

    return (
        <div className="d-none d-lg-flex py-3">

            <div className="hstack flex-fill justify-content-end flex-nowrap gap-6 ms-auto px-6 px-xxl-8">
                <OrgButton/>
                <div className="">
                    <NavbarUser enableOrgFeatures={true}/>
                </div>
            </div>
        </div>
    )
}

export default PowerTopBar