import {createBrowserRouter, Outlet} from "react-router-dom";
import SignInPage, {SignInPath} from "./signin/SignInPage";
import DashboardPage from "./dashboard/DashboardPage";
import Disabled from "./disabled/Disabled";
import GroupsPage from "./groups/GroupsPage";
import BulkAddLeadsPage from "./leads/add/BulkAddLeadsPage";
import ErrorPage from "./ErrorPage";
import ViewGroupPage from "./groups/view/ViewGroupPage";
import Outbox from "./outbox/Outbox";
import CampaignsPage from "./campaigns/list/CampaignsPage";
import SegmentationClassPage from "./segmentation/SegmentationClassPage";
import SegmentationJobPage from "./segmentation/SegmentationJobPage";
import LeadsPage from "./leads/list/LeadsPage";
import AccountPage from "./settings/AccountPage";
import EmailProvidersPage from "./settings/EmailProvidersPage";
import OrganizationPage from "./settings/OrganizationPage";
import SimplePage from "./SimplePage";
import PowerPage from "../components/powerPage/PowerPage";
import AdminPage from "./admin/AdminPage";
import OrgUsersPage from "./settings/OrgUsersPage";
import InvitationsPage from "./settings/InvitationsPage";
import SenderProfilesPage from "./settings/SenderProfilesPage";
import CreateOrgPage, {SetupOrgPath} from "./setup/CreateOrgPage";
import AcceptInvitationPage, {JoinOrgPath} from "./setup/AcceptInvitationPage";
import ProspectingJobsPage from "./prospecting/ProspectingJobsPage";
import FirstPartyFieldsPage from "./firstpartydata/FirstPartyFieldsPage";
import DraftEditorPage from "./outbox/DraftEditorPage";
import SocialMediaProfilesPage from "./socialmedia/SocialMediaProfilesPage";
import TikTokPostsPage from "./socialmedia/TikTokPostsPage";
import IsUserRegisteredGuard from "../components/routing/IsUserRegisteredGuard";
import SegmentationJobResultsPage from "./segmentation/SegmentationJobResultsPage";
import {DraftSchema} from "lib-shared";
import SocialMediaProfilePage from "./socialmedia/SocialMediaProfilePage";
import TikTokPostPage from "./socialmedia/TikTokPostPage";
import ProfilePage from "./profile/ProfilePage";
import {PasswordResetPagePath} from "./signin/PasswordResetPage";
import PasswordResetPage from "./signin/PasswordResetPage";
import DemoWidget, {DemoWidgetPath} from "./demoWidget/DemoWidget";

// Add new pages/routes here!
// if the user should be logged in wrap your page in <PR>...</PR>
export const routes = createBrowserRouter([
    {
        path: "/",
        children: [
            {
                path: "",
                element: (
                    <PowerPage>
                        <DashboardPage/>
                    </PowerPage>
                ),
            },
            {
                path: SignInPath,
                element: (
                    <SimplePage>
                        <SignInPage/>
                    </SimplePage>
                ),
            },
            {
                path: PasswordResetPagePath,
                element: (
                    <SimplePage>
                        <PasswordResetPage/>
                    </SimplePage>
                ),
            },
            {
                path: DemoWidgetPath,
                element: (
                    <IsUserRegisteredGuard>
                        <SimplePage>
                            <DemoWidget/>
                        </SimplePage>
                    </IsUserRegisteredGuard>
                ),
            },
            {
                path: "/disabled",
                element: (
                    <SimplePage>
                        <Disabled/>
                    </SimplePage>
                ),
            },
            {
                path: "/admin",
                element: (
                    <SimplePage>
                        <AdminPage/>
                    </SimplePage>
                ),
            },
            {
                path: "/profile",
                element: (
                    <SimplePage>
                        <ProfilePage/>
                    </SimplePage>
                ),
            },
            {
                path: SetupOrgPath,
                element: (
                    <IsUserRegisteredGuard>
                        <SimplePage>
                            <CreateOrgPage/>
                        </SimplePage>
                    </IsUserRegisteredGuard>
                ),
            },
            {
                path: JoinOrgPath,
                element: (
                    <IsUserRegisteredGuard>
                        <SimplePage>
                            <AcceptInvitationPage/>
                        </SimplePage>
                    </IsUserRegisteredGuard>
                ),
            },
            {
                path: "/settings",
                element: (
                    <PowerPage>
                        <Outlet/>
                    </PowerPage>
                ),
                children: [
                    {
                        path: "",
                        element: <AccountPage/>,
                    },
                    {
                        path: "/settings/profiles",
                        element: <SenderProfilesPage/>,
                    },
                    {
                        path: "/settings/providers",
                        element: <EmailProvidersPage/>,
                    },
                    {
                        path: "/settings/organization",
                        element: <OrganizationPage/>,
                    },
                    {
                        path: "/settings/users",
                        element: <OrgUsersPage/>,
                    },
                    {
                        path: "/settings/invitations",
                        element: <InvitationsPage/>,
                    },
                ],
            },
            {
                path: "/campaigns",
                children: [
                    {
                        path: "",
                        element: (
                            <PowerPage>
                                <CampaignsPage/>
                            </PowerPage>
                        ),
                    },
                ],
            },
            {
                path: "/segmentation",
                children: [
                    {
                        path: "/segmentation/classes",
                        element: (
                            <PowerPage>
                                <SegmentationClassPage/>
                            </PowerPage>
                        ),
                    },
                    {
                        path: "/segmentation/jobs",
                        element: (
                            <PowerPage>
                                <SegmentationJobPage/>
                            </PowerPage>
                        ),
                    },
                    {
                        path: "/segmentation/jobs/:jobId",
                        element: (
                            <PowerPage>
                                <SegmentationJobResultsPage/>
                            </PowerPage>
                        ),
                    },
                ],
            },
            {
                path: "firstPartyFields",
                element: (
                    <PowerPage>
                        <FirstPartyFieldsPage/>
                    </PowerPage>
                ),
            },
            {
                path: "/prospecting",
                children: [
                    {
                        path: "",
                        element: (
                            <PowerPage>
                                <ProspectingJobsPage/>
                            </PowerPage>
                        ),
                    },
                ],
            },
            {
                path: "/groups",
                children: [
                    {
                        path: "",
                        element: (
                            <PowerPage>
                                <GroupsPage/>
                            </PowerPage>
                        ),
                    },
                    {
                        path: ":groupId",
                        element: (
                            <PowerPage>
                                <ViewGroupPage/>
                            </PowerPage>
                        ),
                    },
                ],
            },
            {
                path: "/social",
                children: [
                    {
                        path: "/social/profiles",
                        element: (
                            <PowerPage>
                                <SocialMediaProfilesPage/>
                            </PowerPage>
                        ),
                    },
                    {
                        path: "/social/profiles/:platform/:username",
                        element: (
                            <PowerPage>
                                <SocialMediaProfilePage/>
                            </PowerPage>
                        ),
                    },
                    {
                        path: "/social/posts/:platform/:username/:postId",
                        element: (
                            <PowerPage>
                                <TikTokPostPage/>
                            </PowerPage>
                        ),
                    },
                    {
                        path: "/social/posts",
                        element: (
                            <PowerPage>
                                <TikTokPostsPage/>
                            </PowerPage>
                        ),
                    },
                ],
            },
            {
                path: "/outbox",
                children: [
                    {
                        path: "",
                        element: (
                            <PowerPage>
                                <Outbox/>
                            </PowerPage>
                        ),
                    },
                    {
                        path: `draft/:${DraftSchema.DraftId.name}`,
                        element: (
                            <PowerPage>
                                <DraftEditorPage/>
                            </PowerPage>
                        ),
                    },
                    {
                        path: ":campaignId",
                        element: (
                            <PowerPage>
                                <Outbox/>
                            </PowerPage>
                        ),
                    },
                ],
            },
            {
                path: "/leads",
                children: [
                    {
                        path: "",
                        element: (
                            <PowerPage>
                                <LeadsPage/>
                            </PowerPage>
                        ),
                    },
                    {
                        path: "bulkAdd",
                        element: (
                            <PowerPage>
                                <BulkAddLeadsPage/>
                            </PowerPage>
                        ),
                    },
                ],
            },
        ],

        errorElement: <ErrorPage/>,
    },
]);
