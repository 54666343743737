import AppModal from "../../components/AppModal";
import {Campaign, isSuccess} from "lib-shared";
import {Modal} from "react-bootstrap";
import {useApiGet} from "../../hooks/useApiGet";
import {UserContext} from "../../context/UserContext";
import {useContext} from "react";
import ApiResultBlock from "../../components/ApiResultBlock";
import Button from "../../components/common/Button";
import {CampaignsClient} from "lib-client";
import {toastIfError} from "../../context/toasts/ToastManager";

function CampaignDefaultModal(props: Props) {
    const {clientContext} = useContext(UserContext);

    const campaign = useApiGet<Campaign>(clientContext, CampaignsClient.defaultClient, props.campaign_id, false, {refreshIntervalMs: 5000})

    async function regenerate() {
        const result = await new CampaignsClient().regenerate({id: props.campaign_id}, clientContext)
        toastIfError(clientContext, result)
        if (isSuccess(result)) {
            props.close()
        }
    }

    return (
        <AppModal show={props.show} onHide={props.close} name={"campaign-default message"}>
            <Modal.Header closeButton>
                <Modal.Title>Default Campaign Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ApiResultBlock result={campaign}>
                    {(value) => (
                        <>
                            <h6 className={"text-muted mt-3 mb-2"}>Default Subject</h6>
                            <p className={"display-linebreak"}>{value.default_subject ?? "-"}</p>
                            <h6 className={"text-muted mt-3 mb-2"}>Default Greeting</h6>
                            <p className={"display-linebreak"}>{value.default_greeting ?? "-"}</p>
                            <h6 className={"text-muted mt-3 mb-2"}>Default Body</h6>
                            <p className={"display-linebreak"}>{value.default_body ?? "-"}</p>
                            <h6 className={"text-muted mt-3 mb-2"}>Default Closing</h6>
                            <p className={"display-linebreak"}>{value.default_closing ?? "-"}</p>
                        </>
                    )}

                </ApiResultBlock>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-primary"} label={"Regenerate"} onClick={regenerate}/>
            </Modal.Footer>
        </AppModal>
    )
}

interface Props {
    readonly show: boolean
    readonly close: () => void
    readonly campaign_id: string
}

export default CampaignDefaultModal