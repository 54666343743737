import {OrgModel} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {Required} from "../validation/Validators";

export interface LeadGroup extends OrgModel {
    readonly lead_group_id: string
    readonly prospecting_job_id?: string
    readonly name: string
}


export class LeadGroupSchemaDef extends OrgModelSchema<LeadGroup> {
    get SchemaName(): string {
        return "LeadGroup"
    }

    getFriendlyName(value: LeadGroup): string {
        return value.name
    }

    get apiResourceName(): string {
        return "leadGroups";
    }

    get KeyColumns(): IModelField<any, LeadGroup>[] {
        return [this.OrgId, this.LeadGroupId];
    }

    public readonly LeadGroupId = FieldClass.createIdField("lead_group_id")
    public readonly ProspectingJobId = FieldClass.createIdField("prospecting_job_id")

    public readonly Name = new FieldClass<string, LeadGroup>({
        field_type: FieldType.TEXT,
        display_name: "Name", name: "name", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<LeadGroup>(),
        ]
    })
}

export const LeadGroupSchema: LeadGroupSchemaDef = new LeadGroupSchemaDef()
