import {useQueryState} from "../../hooks/useQueryParam";
import {ColumnSpec} from "../../components/tables/BaseTable";
import PowerPageContent from "../../components/powerPage/PowerPageContent";
import IconButton from "../../components/common/IconButton";
import {SegmentationClass, SegmentationClassSchema} from "lib-shared";
import SegmentationClassEditorModal from "./SegmentationClassEditorModal";
import {ConfirmModalContext} from "../../context/confirmModal/ConfirmModalContext";
import {useContext} from "react";
import ApiTable, {ApiOrderBy, createColumn,} from "../../components/tables/ApiTable";
import {TableAction} from "../../components/tables/TableActions";
import {makeArchiveActions} from "../../components/tables/ApiTableUtils";
import {UserContext} from "../../context/UserContext";
import {SegmentationClassClient} from "lib-client";

export const SegmentationClassEditorKey = "class_id";

function SegmentationClassPage(props: Props) {
    const [editId, setEditId] = useQueryState(SegmentationClassEditorKey);
    const confirmProps = useContext(ConfirmModalContext);
    const {clientContext} = useContext(UserContext);

    const columns: ColumnSpec<SegmentationClass, ApiOrderBy>[] = [
        createColumn(SegmentationClassSchema.Name, true),
        createColumn(SegmentationClassSchema.Description, true),
        createColumn(SegmentationClassSchema.UpdatedAt, true),
        createColumn(SegmentationClassSchema.CreatedAt, false),
    ];

    function actionCreator(classes: SegmentationClass[]): TableAction[] {
        return [
            {
                icon: "pencil",
                label: "Edit",
                supportsMultiRows: false,
                action: () => {
                    setEditId(classes[0].class_id);
                },
            },
            ...makeArchiveActions(classes, confirmProps, SegmentationClassClient.defaultClient, clientContext)
        ];
    }

    return (
        <PowerPageContent
            title={"Segmentation Classes"}
            buttons={[
                <IconButton
                    key={"create-job"}
                    action={() => setEditId("")}
                    icon={"plus-circle"}
                    label={"New Class"}
                />,
            ]}
        >
            <ApiTable<SegmentationClass>
                client={SegmentationClassClient.defaultClient}
                noDataMessage={"No Classes yet."}
                enableArchive={true}
                tableSettings={{
                    columns,
                    idExtractor: (c) => c.class_id,
                    filterOptions: [],
                    actionCreator: actionCreator,
                    rowClickCallback: (cls: SegmentationClass) => setEditId(cls.class_id),
                }}
            />
            <SegmentationClassEditorModal
                show={editId != null}
                edit_id={editId}
                close={() => setEditId(null)}
            />
        </PowerPageContent>
    );
}

interface Props {
}

export default SegmentationClassPage;
