import {
    Campaign,
    CampaignSchema,
    CoreMessageMaxLength,
    CoreMessageMinLength,
    EmailSize, isSuccess,
    SenderProfile
} from "lib-shared";
import {generateName} from "../../utils/NameGenerator";
import MessageLengthRatingBar from "../../components/email/MessageLengthRatingBar";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useApiList} from "../../hooks/useApiList";
import {UserContext} from "../../context/UserContext";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import {CampaignsClient, SenderProfilesClient} from "lib-client";
import {v4 as uuidv4} from "uuid"
import SenderProfileEditor from "../../components/emailProviders/SenderProfileEditor";
import AppModal from "../../components/AppModal";
import {Modal} from "react-bootstrap";
import {eventBus} from "../../utils/EventBus";
import {FormValueSelectItem} from "../../components/forms/FormFields";
import {useApiGet} from "../../hooks/useApiGet";
import {toastIfError} from "../../context/toasts/ToastManager";


function CampaignEditor(props: Props) {
    const {clientContext, appUser} = useContext(UserContext);

    const [showProfileEditor, setShowProfileEditor] = useState(false)

    const initialCampaign = useApiGet<Campaign>(clientContext, CampaignsClient.defaultClient, props.campaign_id)

    const [campaignNameDefault, setCampaignNameDefault] = useState("")

    const isCreate = props.campaign_id == ""

    useEffect(() => {
        setCampaignNameDefault(generateName())
    }, [])

    function close() {
        props.close()
    }

    // used for duplicating
    let copyCampaign = props.copyCampaign;
    if (copyCampaign) {
        copyCampaign = {
            ...copyCampaign,
            name: `${copyCampaign.name} copy`,
            state: "draft",
            created_at: undefined,
            updated_at: undefined
        }
    }

    const senderProfiles = useApiList<SenderProfile>(clientContext, SenderProfilesClient.defaultClient, {}, {})

    const senderProfileOptions: FormValueSelectItem[] = (senderProfiles.value ?? [])
        .map(sp => {
            return {
                id: sp.sender_profile_id,
                label: `${sp.first_name} ${sp.last_name} - ${sp.email_provider_type}`
            }
        })

    async function save(payload: FormSubmitPayload<Campaign>): Promise<boolean> {
        const campaign: Campaign = {
            ...payload.value,
            campaign_id: props.campaign_id || uuidv4(),
            owner: payload.value.owner ?? appUser!.user_id,
            tone: "excited",
            call_to_action: "Set up a meeting",
            state: payload.altSubmitButtonClicked ? "draft" : "launched",
        }

        const response = await new CampaignsClient().createOrUpdate(campaign, isCreate, clientContext)

        if (!isSuccess(response)) {
            toastIfError(clientContext, response)
            return false
        }

        eventBus.trigger("item-changed", undefined)
        close()
        return true
    }


    return (
        <AppModal show={props.campaign_id != null} onHide={close} name={"Campaign editor"}>
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? "Create" : "Edit"} Campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppForm
                    onSubmit={save}
                    onCancel={close}
                    initialValueLazy={initialCampaign}
                    initialValue={copyCampaign}
                    altSubmitLabel={"Save Draft"}
                    submitLabel={"Save & Launch"}
                    schema={CampaignSchema}
                    fields={[
                        {
                            fieldType: "text",
                            field: CampaignSchema.Name,
                            defaultValue: campaignNameDefault,
                            buttonProps: {
                                variant: "primary",
                                onClick: () => setCampaignNameDefault(generateName()),
                                icon: "arrow-clockwise",
                                label: "",
                            }
                        },
                        {
                            fieldType: "text",
                            rows: 7,
                            field: CampaignSchema.CoreMessage,
                            labelSubText: (text: string) => <MessageLengthRatingBar message={text}
                                                                                    minLength={CoreMessageMinLength}
                                                                                    maxLength={CoreMessageMaxLength}/>

                        },
                        {
                            fieldType: "select",
                            field: CampaignSchema.EmailSize,
                            options: enumOptions(EmailSize)
                        },
                        {
                            fieldType: "select",
                            field: CampaignSchema.SenderProfileId,
                            options: senderProfileOptions,
                            buttonProps: {
                                variant: "primary",
                                onClick: () => setShowProfileEditor(true),
                                icon: "plus",
                                label: ""
                            }
                        }
                    ]}
                />
                <SenderProfileEditor show={showProfileEditor}
                                     close={() => setShowProfileEditor(false)}
                                     editId={""}
                                     callback={(p) => {
                                         if (p) {
                                             senderProfiles.rerun()
                                         }
                                     }}/>
            </Modal.Body>
        </AppModal>
    )
}

function enumOptions(enumValue: any): FormValueSelectItem[] {
    return Object.keys(enumValue).map(key => {
        return {
            id: enumValue[key],
            label: enumValue[key].toLowerCase()
        }
    })
}

interface Props {
    readonly campaign_id: string | null
    readonly copyCampaign: Campaign | null
    readonly close: () => void
}

export default CampaignEditor