import {OrgModel} from "./Model";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {Required} from "../validation/Validators";

export interface FirstPartyLeadData extends OrgModel {
    readonly lead_id: string
    readonly field_id: string
    readonly field_value: string
}


export class FirstPartyLeadDataSchemaDef extends OrgModelSchema<FirstPartyLeadData> {
    get SchemaName(): string {
        return "FirstPartyLeadData"
    }

    getFriendlyName(value: FirstPartyLeadData): string {
        return value.field_id
    }

    get apiResourceName(): string {
        return "firstPartyLeadData";
    }

    get KeyColumns(): IModelField<any, FirstPartyLeadData>[] {
        return [this.OrgId, this.LeadId, this.FieldId];
    }

    public readonly LeadId = new FieldClass<string, FirstPartyLeadData>({
        field_type: FieldType.TEXT,
        display_name: "Lead ID",
        name: "lead_id",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [
            new Required<FirstPartyLeadData>(),
        ],
    });

    public readonly FieldId = new FieldClass<string, FirstPartyLeadData>({
        field_type: FieldType.TEXT,
        display_name: "Name", name: "field_id", field_source: FieldSourceType.SYSTEM_DEFINED, rules: [
            new Required<FirstPartyLeadData>(),
        ]
    })


    public readonly Value = new FieldClass<string, FirstPartyLeadData>({
        field_type: FieldType.TEXT,
        display_name: "Value", name: "field_value", field_source: FieldSourceType.USER_DEFINED, rules: [
            new Required<FirstPartyLeadData>(),
        ]
    })
}

export const FirstPartyLeadDataSchema: FirstPartyLeadDataSchemaDef = new FirstPartyLeadDataSchemaDef()
