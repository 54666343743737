import {OrgModel} from "./Model";
import {
    FieldClass,
    FieldSourceType,
    FieldType,
    IModelField,
    ModelSchema,
    OrgModelSchema,
} from "../validation/Validation";
import {MinLength, Required} from "../validation/Validators";

export interface SegmentationJob extends OrgModel {
    // also name of the field
    readonly job_id: string;
    readonly name: string;
    readonly class_ids: string[];
    readonly lead_group_id?: string;
    readonly state: string;
}

export class SegmentationJobSchemaDef extends OrgModelSchema<SegmentationJob> {
    public get KeyColumns(): IModelField<any, SegmentationJob>[] {
        return [this.JobId]
    }

    get apiResourceName(): string {
        return "segmentationJobs";
    }

    getFriendlyName(value: SegmentationJob): string {
        return value.name;
    }

    get SchemaName(): string {
        return "SegmentationJob";
    }

    public readonly JobId = new FieldClass<string, SegmentationJob>({
        field_type: FieldType.TEXT,
        display_name: "Job ID",
        name: "job_id",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [new Required<SegmentationJob>(), new MinLength<SegmentationJob>(1)],
    });

    public readonly Name = new FieldClass<string, SegmentationJob>({
        field_type: FieldType.TEXT,
        display_name: "Name",
        name: "name",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [new Required<SegmentationJob>(), new MinLength<SegmentationJob>(1)],
    });

    public readonly SegmentClassIds = new FieldClass<string[], SegmentationJob>({
        field_type: FieldType.MULTI_SELECT,
        display_name: "Segments to segment",
        name: "class_ids",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [],
    });

    public readonly LeadGroupId = new FieldClass<string, SegmentationJob>({
        field_type: FieldType.SELECT,
        display_name: "Lead Group",
        name: "lead_group_id",
        field_source: FieldSourceType.USER_DEFINED,
        rules: [],
    });

    public readonly JobState = new FieldClass<string, SegmentationJob>({
        field_type: FieldType.TEXT,
        display_name: "Job State",
        name: "state",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        defaultValue: "pending",
        rules: [],
    });

    public readonly ExcludeNoneClass = new FieldClass<boolean, SegmentationJob>({
        field_type: FieldType.TOGGLE,
        display_name: "Exclude None",
        name: "exclude_none_class",
        field_source: FieldSourceType.USER_DEFINED,
        defaultValue: false,
        rules: [],
    });
}

export const SegmentationJobSchema: SegmentationJobSchemaDef =
    new SegmentationJobSchemaDef();
