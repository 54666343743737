import {PaginationResult} from "../hooks/usePaginatedCollection";
import {useState} from "react";
import {getPageNums, paginateArray} from "./Utils";
import {InMemOrderBy, InMemoryFilterField} from "../components/tables/InMemTable";
import {OrderByState} from "../components/tables/BaseTable";

export function useInMemPaginatedCollection<T>(props: Props<T>): PaginationResult<T> {
    const pageSize = props.pageSize ?? 25
    const [pageNum, setPageNum] = useState<number>(0)


    let {data} = props

    if (props.orderBy) {
        data = data.sort((a, b) => {
            const aHash = props.orderBy!.orderBy.extractor(a)
            const bHash = props.orderBy!.orderBy.extractor(b)
            return aHash?.localeCompare(bHash)
        })

        if (props.orderBy.dir == "desc")
            data = data.reverse()
    }

    function applyFilters(value: T): boolean {
        for (let filter of props.filters) {
            if (!filter.predicate(value))
                return false
        }
        return true
    }

    const page = paginateArray(data, pageNum, pageSize, applyFilters)

    const {
        startNum,
        endNum,
        totalCount,
        hasPrevPage,
        hasNextPage,
    } = getPageNums(pageNum, pageSize, data.length)


    function nextPage() {
        changePage(1)
    }

    function prevPage() {
        changePage(-1)
    }

    function changePage(delta: number) {
        setPageNum(pageNum + delta)
    }

    const pageResult: PaginationResult<T> = {
        data: page,
        pageSize,
        loading: false,
        startNum,
        endNum,
        totalCount,
        hasPrevPage,
        hasNextPage,
        next: nextPage,
        prev: prevPage,
        reload: () => {
        },
    }

    return pageResult
}

interface Props<T> {
    readonly data: T[]
    readonly filters: InMemoryFilterField<T>[]
    readonly orderBy?: OrderByState<InMemOrderBy<T>>
    readonly pageSize?: number
}
