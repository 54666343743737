import {
    FieldClass, FieldSourceType,
    FieldType,
    FirstPartyField,
    FirstPartyLeadData,
    IModelSchema,
    KeyValue, LeadSchema,
    IModelField, isSuccess, getResultOrNull
} from "lib-shared";
import AppForm, {FormSubmitPayload} from "../../components/forms/AppForm";
import IconButton from "../../components/common/IconButton";
import {useContext, useState} from "react";
import FirstPartyFieldEditorModal from "../firstpartydata/FirstPartyFieldEditorModal";
import {FormTextField} from "../../components/forms/FormFields";
import {useApiList} from "../../hooks/useApiList";
import {UserContext} from "../../context/UserContext";
import {compact, compactResult} from "../../utils/Utils";
import {ClientContext, FirstPartyFieldsClient, FirstPartyLeadDataClient} from "lib-client";
import {ApiResult} from "../../hooks/useApi";
import {toastIfError} from "../../context/toasts/ToastManager";

function FirstPartyFieldsEditor(props: Props) {
    const {clientContext} = useContext(UserContext);

    const [showFieldCreator, setShowFieldCreator] = useState(false)

    const compactedFieldData: ApiResult<KeyValue<string>> | undefined = loadDataMaybe(props)

    const compactedOverrideData: KeyValue<string> | undefined = props.data ? compact(props.data, (f) => [f.field_id, f.field_value]) : undefined

    const allFieldsResponse = useApiList<FirstPartyField>(clientContext, FirstPartyFieldsClient.defaultClient, {}, {})
    const allFields: FirstPartyField[] = allFieldsResponse.value ?? []

    const fields: FormTextField<KeyValue<string>>[] =
        allFields.map(f => {
            return {
                fieldType: "text",
                field: new FieldClass<string, KeyValue<string>>({
                    display_name: f.field_id,
                    name: f.field_id,
                    field_source: FieldSourceType.USER_DEFINED,
                    field_type: FieldType.TEXT,
                    rules: []
                }),
                clearable: true,
                tip: f.field_description,
            }
        })

    const schema: IModelSchema<KeyValue<string>> = {
        get SchemaName(): string {
            return "Adhoc First Party Fields";
        },
        getFields(): IModelField<any, KeyValue<string>>[] {
            return fields.map(f => f.field)
        }
    }

    if (props.show === false) {
        return <></>
    }

    async function save(payload: FormSubmitPayload<KeyValue<string>>): Promise<boolean> {
        const firstPartyLeadData: FirstPartyLeadData[] = extractFirstPartyLeadData(payload.value, props.leadId, clientContext)

        const originalFirstPartyDataResponse = await new FirstPartyLeadDataClient().list({
            filterFields: [
                {
                    field: LeadSchema.LeadId.name,
                    value: [props.leadId]
                }
            ]
        }, clientContext)

        const originalFirstPartyData = getResultOrNull(originalFirstPartyDataResponse)
        if (!originalFirstPartyData) {
            toastIfError(clientContext, originalFirstPartyDataResponse)
            return false
        }

        const firstPartyDataResponse = await new FirstPartyLeadDataClient()
            .setIds(originalFirstPartyData, firstPartyLeadData
                    // "delete" empty fields by filtering them out
                    .filter(d => d.field_value.length > 0),
                (f: FirstPartyLeadData) => f.field_id,
                (a, b) => a.field_value != b.field_value, clientContext)

        if (!isSuccess(firstPartyDataResponse)) {
            return false
        }
        return true
    }

    function onChange(value: KeyValue<string>) {
        if (props.onChange) {
            const firstPartyLeadData: FirstPartyLeadData[] = extractFirstPartyLeadData(value, props.leadId, clientContext)
            props.onChange(firstPartyLeadData)
        }
    }

    return (
        <div>
            <p className={"text-muted"}>Add first party data that you have on this lead here. These fields will be given
                to your AI when it's composing messages.</p>
            <div className={"mb-3"}/>
            <IconButton action={() => setShowFieldCreator(true)} icon={"plus-circle"} label={"New Field"}
                        variant={"outline-primary"}/>
            <div className={"mb-3"}/>
            <AppForm onSubmit={props.onChange ? null : save}
                     initialValueLazy={compactedFieldData}
                     initialValue={compactedOverrideData}
                     onChange={onChange}
                     schema={schema}
                     fields={fields}
            />
            <FirstPartyFieldEditorModal show={showFieldCreator}
                                        close={() => setShowFieldCreator(false)}
                                        edit_id={""}
                // we just added a field so reload them
                                        createFieldCallback={() => allFieldsResponse.rerun()}/>
        </div>
    )
}

function extractFirstPartyLeadData(obj: KeyValue<string>, leadId: string, cc: ClientContext): FirstPartyLeadData[] {
    return Object.keys(obj).map<FirstPartyLeadData>(k => {
        return {
            org_id: cc.org_id!,
            lead_id: leadId,
            field_id: k,
            field_value: obj[k]
        }
    })
}

function loadDataMaybe(props: Props): ApiResult<KeyValue<string>> | undefined {
    if (props.data) {
        return undefined
    }

    const {clientContext} = useContext(UserContext);

    const fieldDataResult: ApiResult<FirstPartyLeadData[]> =
        useApiList<FirstPartyLeadData>(clientContext, FirstPartyLeadDataClient.defaultClient, {
            filterFields: [
                {
                    field: LeadSchema.LeadId.name,
                    value: [props.leadId]
                }
            ]
        }, {})

    return compactResult(fieldDataResult, (f) => [f.field_id, f.field_value])
}

interface Props {
    show?: boolean
    leadId: string
    data?: FirstPartyLeadData[]
    onChange?: (fields: FirstPartyLeadData[]) => void
}

export default FirstPartyFieldsEditor