import PowerPageContent from "../../components/powerPage/PowerPageContent";
import * as React from "react";
import {useContext, useState} from "react";
import {ColumnSpec, FilterOption} from "../../components/tables/BaseTable";
import ApiTable, {ApiFilter, ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import {TableAction} from "../../components/tables/TableActions";
import {useAppNav} from "../../hooks/useAppNav";
import {createPostPageLink} from "./TikTokPostPage";
import {UserContext} from "../../context/UserContext";
import {createPostsActions} from "./SocialMediaProfilePage";
import IconButton from "../../components/common/IconButton";
import AddSocialMediaPostModal from "./AddSocialMediaPostModal";
import {SocialMediaPlatform, TikTokPost, TikTokPostSchema} from "lib-shared";
import {TikTokPostsClient} from "lib-client";
import ProfileAvatarIcon from "../../components/common/ProfileAvatarIcon";

function TikTokPostsPage() {
    const nav = useAppNav()
    const {clientContext} = useContext(UserContext)
    const [showNewPostModal, setShowNewPostModal] = useState(false)

    const filters: FilterOption<ApiFilter>[] = [
        {
            name: "Platform",
            filters: [
                {
                    displayName: "Platform",
                    field: "platform",
                    value: ["instagram"],
                    displayValue: "Instagram"
                },
                {
                    displayName: "Platform",
                    field: "platform",
                    value: ["TIKTOK"],
                    displayValue: "TIKTOK"
                },
            ]
        },
    ]

    function actionCreator(posts: TikTokPost[]): TableAction[] {
        return createPostsActions(posts, clientContext)
    }

    function rowClicked(post: TikTokPost) {
        nav(createPostPageLink({...post, platform: SocialMediaPlatform.TIKTOK}))
    }

    return (
        <PowerPageContent title={"Social Media Posts"}
                          buttons={[
                              <IconButton key={"new-post"} action={() => setShowNewPostModal(true)} icon={"plus"}
                                          label={"New Post"}/>
                          ]}>
            <ApiTable<TikTokPost> client={TikTokPostsClient.defaultClient}
                                  noDataMessage={"No Posts."}
                                  useQueryParamFilters={true}
                                  tableSettings={{
                                      idExtractor: (post: TikTokPost) => `${post.username}#${post.post_id}`,
                                      filterOptions: filters,
                                      columns: createSocialPostsColumns(true),
                                      rowClickCallback: rowClicked,
                                      actionCreator
                                  }}
            />
            <AddSocialMediaPostModal show={showNewPostModal} close={() => setShowNewPostModal(false)}/>
        </PowerPageContent>
    )
}

export function createSocialPostsColumns(showUsernameAndPlatform: boolean): ColumnSpec<TikTokPost, ApiOrderBy>[] {
    return [
        createColumn(TikTokPostSchema.Username, showUsernameAndPlatform, {
            cellConfig: {weight: "fw-semibold"}
        }),
        createColumn(TikTokPostSchema.VideoSummary, true),
        createColumn(TikTokPostSchema.VideoDurationSeconds, false),
        createColumn(TikTokPostSchema.PlayCount, true),
        createColumn(TikTokPostSchema.CreateTime, true, {
            isDefaultOrderBy: true,
            defaultOrderByDir: "desc",
        }),
        createColumn(TikTokPostSchema.MusicId, false),
        createColumn(TikTokPostSchema.Description, false),
        createColumn(TikTokPostSchema.CollectCount, false),
        createColumn(TikTokPostSchema.DiggCount, false),
        createColumn(TikTokPostSchema.ShareCount, true),
        createColumn(TikTokPostSchema.CommentCount, true),
        createColumn(TikTokPostSchema.VideoUri, false),
        createColumn(TikTokPostSchema.UpdatedAt, false),
        createColumn(TikTokPostSchema.CreatedAt, false),
    ]
}

export default TikTokPostsPage