import PowerPageContent from "../../components/powerPage/PowerPageContent";
import {ColumnSpec} from "../../components/tables/BaseTable";
import {useQueryState} from "../../hooks/useQueryParam";
import {ProspectingJob, ProspectingJobSchema} from "lib-shared";
import IconButton from "../../components/common/IconButton";
import ProspectingJobEditorModal from "./ProspectingJobEditorModal";
import {useContext} from "react";
import {ConfirmModalContext} from "../../context/confirmModal/ConfirmModalContext";
import ApiTable, {ApiOrderBy, createColumn} from "../../components/tables/ApiTable";
import {TableAction} from "../../components/tables/TableActions";
import {ProspectingJobsClient} from "lib-client";

export const ProspectingJobsEditorKey = "job_id"

function ProspectingJobsPage(props: Props) {

    const {confirm} = useContext(ConfirmModalContext);

    const [editId, setEditId] = useQueryState(ProspectingJobsEditorKey);

    const columns: ColumnSpec<ProspectingJob, ApiOrderBy>[] = [
        createColumn(ProspectingJobSchema.Name, true),
        createColumn(ProspectingJobSchema.UpdatedAt, true),
        createColumn(ProspectingJobSchema.CreatedAt, true),
    ]

    function actionCreator(jobs: ProspectingJob[]): TableAction[] {
        return [
            {
                icon: "pencil",
                enabled: jobs[0].state != "pending",
                label: "Edit",
                action: () => setEditId(jobs[0].job_id)
            },
            {
                icon: "trash",
                label: "Delete",
                color: "danger",
                supportsMultiRows: true,
                action: () => {
                    confirm({
                        client: ProspectingJobsClient.defaultClient,
                        action_type: "delete",
                        resources: jobs.map(j => {
                            return {id: j.job_id, name: j.lead_group_name ?? j.job_id}
                        })
                    })
                }
            }
        ]
    }

    return (
        <PowerPageContent
            title={"Prospecting Jobs"}
            buttons={[
                <IconButton key={"create-job"} action={() => setEditId("")} icon={"plus-circle"} label={"New Job"}/>
            ]}
        >
            <ApiTable<ProspectingJob> client={ProspectingJobsClient.defaultClient}
                                      noDataMessage={"No Jobs yet."}
                                      tableSettings={{
                                          columns,
                                          idExtractor: job => job.job_id,
                                          filterOptions: [],
                                          actionCreator: actionCreator
                                      }}
            />
            <ProspectingJobEditorModal editId={editId} close={() => setEditId(null)}/>
        </PowerPageContent>
    )
}

interface Props {

}

export default ProspectingJobsPage