import {FirstPartyField, FirstPartyLeadData, Lead} from "lib-shared";
import {ChangeEvent, useContext, useEffect, useRef, useState} from "react";

import * as Papa from "papaparse";
import {ParseResult} from "papaparse";
import {UserContext} from "../../../context/UserContext";
import Button from "../../../components/common/Button";
import AppModal from "../../../components/AppModal";
import {Col, Form, Modal, Row} from "react-bootstrap";
import {useApiList} from "../../../hooks/useApiList";
import CsvLeadImporterMappingItem from "./CsvLeadImporterMappingItem";
import FirstPartyFieldEditorModal from "../../firstpartydata/FirstPartyFieldEditorModal";
import {v4 as uuidv4} from "uuid"
import {StagedBulkUploadLead} from "./BulkAddLeadsPage";
import {ClientContext, FirstPartyFieldsClient} from "lib-client";

function CsvLeadImporter(props: Props) {
    const inputRef = useRef<HTMLInputElement>(null);
    const {clientContext} = useContext(UserContext)
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [hasHeader, setHasHeader] = useState(true)
    const [mappings, setMappings] = useState<ImporterFieldMapping[]>([])
    const [showFieldCreator, setShowFieldCreator] = useState(false)

    const allFieldsResponse = useApiList<FirstPartyField>(clientContext, FirstPartyFieldsClient.defaultClient, {}, {})
    const allFields: FirstPartyField[] = allFieldsResponse.value ?? []

    useEffect(() => {
        if (selectedFile)
            inferMappings(selectedFile)
    }, [allFields.length]);

    function onFileChange(event: ChangeEvent<HTMLInputElement>) {
        const file: File = event.target.files![0]

        console.log("onFileChange...")
        if (!file) {
            console.log("Undefined file - can happen when canceling after file already selected")
            return
        }

        setSelectedFile(file)
        inferMappings(file)
    }

    function inferMappings(file: File) {
        if (file == null)
            return

        console.log("inferring mappings...")

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results: ParseResult<any>, file: File) {
                const inferredMappings = infer(results, allFields)
                setMappings(inferredMappings)
            }
        })
    }

    function updateMapping(mapping: ImporterFieldMapping): void {
        const result = [...mappings]
        const index = result.findIndex(m => m.sourceColumnIndex == mapping.sourceColumnIndex)
        result[index] = mapping
        setMappings(result)
    }

    function importLeads() {
        parse(selectedFile!, mappings, hasHeader, clientContext, props.addLeads)
        close()
    }

    function close() {
        setSelectedFile(null)
        inputRef.current!.value = ""
    }

    return (
        <>
            <input id={"csv-file-upload"} ref={inputRef} type="file" onChange={onFileChange} accept={".csv"}
                   style={{display: 'none'}}/>
            <Button onClick={() => inputRef.current!.click()} variant={"outline-primary"} label={"Add File"}
                    icon={"card-list"}/>
            <AppModal show={selectedFile != null} onHide={close} name={"csv-import"}>
                <Modal.Header closeButton>Leads Import</Modal.Header>
                <Modal.Body>
                    <p>For best results, use a CSV file with a header row and include an "email", "first name" and "last
                        name" column. You may include first party data fields.</p>
                    <Row className={"m-4"}>
                        <Col>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id={`header-switch`}
                                checked={hasHeader}
                                onChange={() => setHasHeader(!hasHeader)}
                                label="Has Header Line"
                            />
                        </Col>
                        <Col>
                            <div className={"faux-link"} onClick={() => setShowFieldCreator(true)}>New First Party
                                Field
                            </div>
                        </Col>
                    </Row>
                    <div className={"mb-5"}>
                        {mappings.map(mapping => {
                            return <CsvLeadImporterMappingItem key={mapping.sourceColumnIndex}
                                                               mapping={mapping}
                                                               fields={allFields}
                                                               callback={updateMapping}/>
                        })}
                    </div>
                    <Row>
                        <Col>
                            <Button variant={"neutral"} label={"Cancel"}
                                    className={"w-100"}
                                    onClick={close}/>
                        </Col>
                        <Col>
                            <Button variant={"primary"} label={"Import"} className={"w-100"} onClick={importLeads}/>

                        </Col>

                    </Row>

                </Modal.Body>
            </AppModal>
            <FirstPartyFieldEditorModal show={showFieldCreator}
                                        close={() => setShowFieldCreator(false)}
                                        edit_id={""}
                // we just added a field so reload them
                                        createFieldCallback={() => allFieldsResponse.rerun()}/>
        </>
    )
}

function infer(file: ParseResult<any>, firstPartyFields: FirstPartyField[]): ImporterFieldMapping[] {
    const inferredMappings: ImporterFieldMapping[] = file.meta.fields!
        .flatMap((field, index) => {
            // normalize
            field = field.toLowerCase().trim()

            // user must define all column names otherwise it messes up the importer UI
            if (field == "")
                return []

            let mapping: ImporterFieldMapping
            if (field == "email" || field == "email address") {
                mapping = {
                    type: "primary",
                    output: "email",
                    enabled: true,
                    sourceColumnName: field,
                    sourceColumnIndex: index
                }
            } else if (field == "first_name" || field == "first" || field == "first name") {
                mapping = {
                    type: "primary",
                    output: "first_name",
                    enabled: true,
                    sourceColumnName: field,
                    sourceColumnIndex: index
                }
            } else if (field == "last_name" || field == "last" || field == "last name") {
                mapping = {
                    type: "primary",
                    output: "last_name",
                    enabled: true,
                    sourceColumnName: field,
                    sourceColumnIndex: index
                }
            } else {
                const firstPartyField = firstPartyFields.find(f => f.field_id.toLowerCase() == field)?.field_id ?? null
                mapping = {
                    type: "first party",
                    output: firstPartyField,
                    enabled: firstPartyField != null,
                    sourceColumnName: field,
                    sourceColumnIndex: index
                }
            }
            return [mapping]
        })
    return inferredMappings
}

function parse(file: File, mappings: ImporterFieldMapping[], hasHeader: boolean, cc: ClientContext, callback: (leads: StagedBulkUploadLead[]) => void) {
    Papa.parse(file, {
        header: hasHeader,
        skipEmptyLines: true,
        complete: function (results: ParseResult<any>, file: File) {
            function get(mapping: ImporterFieldMapping, data: any): string {
                const col = results.meta.fields![mapping.sourceColumnIndex]
                return data[col] as string
            }

            const fileLeads: StagedBulkUploadLead[] = results.data.map(row => {
                console.log("row: " + JSON.stringify(row))

                let lead: Lead = {
                    org_id: cc.org_id!,
                    lead_id: "",
                    owner: null,
                    email: "",
                    first_name: "",
                    last_name: "",
                    is_researched: false
                }
                let fields: FirstPartyLeadData[] = []

                for (const mapping of mappings) {
                    if (!mapping.enabled || mapping.output == "" || mapping.output == null)
                        continue

                    if (mapping.type == "primary") {
                        lead = {
                            ...lead,
                            [mapping.output]: get(mapping, row)
                        }
                    } else {
                        const field: FirstPartyLeadData = {
                            field_id: mapping.output,
                            field_value: get(mapping, row),
                            lead_id: lead.lead_id,
                            org_id: cc.org_id!

                        }
                        fields.push(field)
                    }
                }

                lead = {
                    ...lead,
                    // email is used for id so make sure this is in sync
                    lead_id: lead.email
                }

                return {
                    id: uuidv4(),
                    lead,
                    fields
                }
            })
            callback(fileLeads)
        }
    })
}

export interface ImporterFieldMapping {
    readonly type: "primary" | "first party"
    readonly sourceColumnIndex: number
    readonly sourceColumnName: string | null
    readonly output: string | null
    readonly enabled: boolean
}


export interface Props {
    readonly addLeads: (leads: StagedBulkUploadLead[]) => void
}

export default CsvLeadImporter