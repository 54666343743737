import {Campaign, LeadGroup} from "lib-shared"
import {StagedBulkUploadLead} from "./BulkAddLeadsPage";

function WizardSectionSummary(props: Props) {
    const {campaign, group, leads} = props

    return (
        <div>
            <p className={"text-muted mb-3 mt-3"}>{`You're about to upload ${leads.length} leads.`}</p>
            <table className="table">
                <tbody>
                <tr>
                    <th scope="row">Leads</th>
                    <td>{leads.length}</td>
                </tr>
                <tr>
                    <th scope="row">Group</th>
                    <td>{group?.name ?? "-"}</td>
                </tr>
                <tr>
                    <th scope="row">Campaign</th>
                    <td>{campaign?.name ?? "-"}</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

interface Props {
    readonly leads: StagedBulkUploadLead[]
    readonly group: LeadGroup | null
    readonly campaign: Campaign | null
}

export default WizardSectionSummary