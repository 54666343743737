import {createContext, ReactNode, useContext, useEffect} from "react";
import {Organization, Organization_Id, OrganizationSchema, OrgMembership} from "lib-shared";
import {UserContext} from "./UserContext";
import {ApiResult, useApi} from "../hooks/useApi";
import {getQueryParam} from "../utils/Utils";
import {OrgMembershipsClient} from "lib-client";


interface Props {
    children?: ReactNode
}

export interface OrgProps {
    readonly org: Organization | null
    readonly loading: boolean
}


export const OrgContext = createContext<OrgProps>({
    loading: true,
    org: null,
});

export const OrgProvider = (props: Props) => {
    const {clientContext} = useContext(UserContext);

    const orgId = getQueryParam(Organization_Id) ?? null

    if (orgId == null) {
        const lastUsedOrgId = localStorage.getItem(OrganizationSchema.OrgId.name)
        if (lastUsedOrgId) {
            const url = new URL(window.location.href)
            url.searchParams.set(Organization_Id, lastUsedOrgId)
            window.location.assign(url)
        }
    }

    const result: ApiResult<OrgMembership> = useApi<OrgMembership>(clientContext!, (cc) => new OrgMembershipsClient().getMembershipForOrg({
        id: orgId!,
        fullyHydrated: true
    }, cc), [orgId])

    useEffect(() => {
        // save preference to browser but only if we've verified it's legit by loading it from the API
        if (result.value)
            localStorage.setItem(OrganizationSchema.OrgId.name, result.value.org_id)
    }, [orgId, result.isLoading]);

    const value: OrgProps = {
        org: result.value?.org ?? null,
        loading: result.isLoading && orgId != null
    }

    return <OrgContext.Provider value={value}>{props.children}</OrgContext.Provider>
}
