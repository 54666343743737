import AppModal from "../../../components/AppModal";
import LeadEditor from "./LeadEditor";
import Button from "../../../components/common/Button";
import {Modal} from "react-bootstrap";
import {useState} from "react";
import FirstPartyFieldsEditor from "../FirstPartyFieldsEditor";
import AppTabs, {TabSpec} from "../../../components/common/AppTabs";
import {StagedBulkUploadLead} from "./BulkAddLeadsPage";

function BulkUploadLeadEditor(props: Props) {
    const {lead, fields} = props.value

    const [state, setState] = useState(props.value)

    function done(): void {
        const id = state.lead.email
        const result: StagedBulkUploadLead = {
            id: props.value.id,
            lead: {
                ...state.lead,
                // email is used for id and that might have changed
                lead_id: id,
            },
            fields: state.fields.map(f => {
                return {
                    ...f,
                    // email is used for id and that might have changed
                    lead_id: id
                }
            })
        }
        props.setValue(result)
        props.close()
    }

    const tabs: TabSpec[] = [
        {
            name: "Lead",
            element: <LeadEditor
                lead={lead}
                isCreate={true}
                onChange={(lead) => setState({...state, lead: lead})}
                forceValidation={true}
            />
        },
        {
            name: "First Party Fields",
            element: <FirstPartyFieldsEditor leadId={lead.lead_id}
                                             data={fields}
                                             onChange={(fields) => setState({...state, fields: fields})}
            />
        }
    ]

    return (
        <AppModal show={true} onHide={props.close} name={"bulk lead editor"}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Lead</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AppTabs tabs={tabs}/>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={done}
                    variant={"primary"}
                    label={"Done"}
                    className={"w-100"}/>
            </Modal.Footer>
        </AppModal>
    )
}

type TabType = "lead" | "first party fields"

interface Props {
    value: StagedBulkUploadLead
    setValue: (value: StagedBulkUploadLead) => void
    close: () => void
}

export default BulkUploadLeadEditor