import MaterialIcon from "./MaterialIcon";

function SpinnerOrSuccess(props: Props) {

    return (
        <>
            {
                props.statuses.map((x, i) => {
                    if (x) {
                        return <span className={"ms-3"} key={i}>
                            <MaterialIcon color={"success"}
                                          icon={"check-circle-fill"}/>
                        </span>
                    } else {
                        return <span className="spinner-border spinner-border-sm ms-3" role="status" key={i}>
                            <span className="visually-hidden">Loading...</span>
                        </span>
                    }

                })
            }
        </>)
}

interface Props {
    readonly statuses: boolean[]
}

export default SpinnerOrSuccess