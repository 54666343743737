import {OrgModel} from "./Model";
import {Lead} from "./Lead";
import {FieldClass, FieldSourceType, FieldType, IModelField, OrgModelSchema} from "../validation/Validation";
import {Required} from "../validation/Validators";

export interface LeadGroupMembership extends OrgModel {

    readonly lead_group_id: string
    readonly lead_id: string

    // hydrated
    readonly lead?: Lead
}


class LeadGroupMembershipSchemaDef extends OrgModelSchema<LeadGroupMembership> {
    get SchemaName(): string {
        return "LeadGroupMembership"
    }

    getFriendlyName(value: LeadGroupMembership): string {
        return value.lead_id
    }

    get apiResourceName(): string {
        return "leadGroupMemberships";
    }

    get KeyColumns(): IModelField<any, LeadGroupMembership>[] {
        return [this.OrgId, this.LeadGroupId, this.LeadId];
    }

    public readonly LeadGroupId = new FieldClass<string, LeadGroupMembership>({
        field_type: FieldType.TEXT,
        display_name: "Lead Group ID",
        name: "lead_group_id",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [
            new Required<LeadGroupMembership>(),
        ],
    });

    public readonly LeadId = new FieldClass<string, LeadGroupMembership>({
        field_type: FieldType.TEXT,
        display_name: "Lead ID",
        name: "lead_id",
        field_source: FieldSourceType.SYSTEM_DEFINED,
        rules: [
            new Required<LeadGroupMembership>(),
        ],
    });
}

export const LeadGroupMembershipSchema: LeadGroupMembershipSchemaDef = new LeadGroupMembershipSchemaDef()