import Form from "react-bootstrap/Form";
import * as React from "react";
import FormMultiToggleItem from "./FormMultiToggleItem";
import PaginationButtons from "../tables/PaginationButtons";
import FormLabel from "./FormLabel";
import {FormFieldComponent, FormValueSelectItem} from "./FormFields";
import {useInMemPaginatedCollection} from "../../utils/useInMemPaginatedCollection";
import {useEffect, useState} from "react";

function FormMultiToggle<T>(props: Props<T>) {

    const [value, setValue] = useState<string[]>(props.initialValue ?? [])

    useEffect(() => {
        setValue(props.initialValue ?? [])
    }, [props.initialValue]);

    function isChecked(id: string): boolean {
        return value.includes(id)
    }

    function callback(id: string) {
        const checked = isChecked(id)
        if (checked) {
            setValue(value.filter(v => v != id))
        } else {
            setValue([...(value ?? []), id])
        }
    }

    const values = (props.sortValues ?? true) ? props.options.sort((a, b) => a.label > b.label ? 1 : -1) : props.options

    const paginationResult = useInMemPaginatedCollection({
        pageSize: 6, data: values, orderBy: undefined, filters: []
    })

    const paginate = (!paginationResult.hasPrevPage && !paginationResult.hasNextPage)

    return (
        <Form.Group className="mb-3" controlId={props.field.name}>
            <FormLabel field={props.field}
                       value={value}
                       labelSubText={props.labelSubText}
                       errors={props.errors}/>
            <div className={"text-sm text-muted"}>{value.length} items selected</div>
            <div className="vstack gap-3 mt-4 ms-10">
                {paginationResult.data.map(value => {
                    return <FormMultiToggleItem key={value.id}
                                                name={`${props.field.name}[]`}
                                                {...value}
                                                checked={isChecked(value.id)}
                                                disabled={props.disabled ?? false}
                                                callback={callback}/>
                })
                }
            </div>
            {paginate && <PaginationButtons paginationResult={paginationResult}/>}
        </Form.Group>
    )
}

interface Props<T> extends FormFieldComponent<string[], T> {
    readonly sortValues?: boolean
    readonly disabled?: boolean
    readonly options: FormValueSelectItem[]
}

export default FormMultiToggle