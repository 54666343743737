import {IBaseModel} from "./Model";
import {
    BaseModelSchema,
    FieldClass,
    FieldSourceType,
    FieldType,
    IModelField,
    ModelSchema
} from "../validation/Validation";

export interface TikTokPostEntity extends IBaseModel {
    readonly username: string
    readonly post_id: string
    readonly id: string
    readonly entity: string
    readonly entity_type: EntityType
    readonly entity_sentiment: EntitySentiment
}

export enum EntityType {
    ORGANIZATION = "ORGANIZATION",
    PERSON = "PERSON",
    OTHER = "OTHER",
}

export enum EntitySentiment {
    NEUTRAL = "neutral",
    POSITIVE = "positive",
    NEGATIVE = "negative",
}


export class TikTokPostEntitySchemaDef extends BaseModelSchema<TikTokPostEntity> {
    public get KeyColumns(): IModelField<any, TikTokPostEntity>[] {
        return [this.Username, this.PostId, this.Id]
    }

    getFriendlyName(value: TikTokPostEntity): string {
        return value.post_id;
    }

    get apiResourceName(): string {
        return "tikTokPostEntities";
    }

    get SchemaName(): string {
        return "TikTokPostEntity"
    }

    public readonly PostId = new FieldClass<string, TikTokPostEntity>({
        field_type: FieldType.TEXT,
        display_name: "Post ID", name: "post_id", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly Username = new FieldClass<string, TikTokPostEntity>({
        field_type: FieldType.TEXT,
        display_name: "Username", name: "username", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly Id = new FieldClass<string, TikTokPostEntity>({
        field_type: FieldType.TEXT,
        display_name: "Id", name: "id", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly Entity = new FieldClass<string, TikTokPostEntity>({
        field_type: FieldType.TEXT,
        display_name: "Entity", name: "entity", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly EntityType = new FieldClass<string, TikTokPostEntity>({
        field_type: FieldType.TEXT,
        display_name: "Entity Type", name: "entity_type", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })

    public readonly EntitySentiment = new FieldClass<string, TikTokPostEntity>({
        field_type: FieldType.TEXT,
        display_name: "Sentiment", name: "entity_sentiment", field_source: FieldSourceType.SYSTEM_DEFINED, rules: []
    })
}

export const TikTokPostEntitySchema: TikTokPostEntitySchemaDef = new TikTokPostEntitySchemaDef()
