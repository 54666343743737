import BaseTable, {BaseFilterField, BaseOrderBy, OrderByState, RowData, TableCell, TableSettings} from "./BaseTable";
import PaginationButtons from "./PaginationButtons";
import {useState} from "react";
import {OrderByDirection} from "lib-shared";
import {useInMemPaginatedCollection} from "../../utils/useInMemPaginatedCollection";

function InMemTable<T>(props: Props<T>) {

    const defaultOrderBy = props.tableSettings.columns.find(c => c.orderBy)
    const [orderBy, setOrderByOption] = useState<OrderByState<InMemOrderBy<T>>>({
        orderBy: defaultOrderBy!.orderBy!,
        dir: "asc",
    })
    const [filters, setFilters] = useState<InMemoryFilterField<T>[]>([])

    const pageResult = useInMemPaginatedCollection<T>({
        data: props.data,
        orderBy: orderBy,
        filters: filters
    })

    return (
        <>
            <BaseTable<T, InMemoryFilterField<T>, InMemOrderBy<T>> tableSettings={props.tableSettings}
                                                                   data={pageResult.data}
                                                                   filters={filters} setFilters={setFilters}
                                                                   loading={false}
                                                                   isAutoRefresh={null}
                                                                   setIsAutoRefresh={(value: boolean) => {
                                                                   }}
                                                                   order={orderBy}
                                                                   setOrder={setOrderByOption}
                                                                   setShowArchive={()=> {}}
                                                                   showArchive={false}
                                                                   enableArchive={false}/>
            {pageResult.data.length == 0 &&
                <div className={"text-center p-5 m-5"}>
                    {props.noDataMessage}
                </div>}
            <PaginationButtons paginationResult={pageResult}/>
        </>
    )
}

interface Props<T> {
    readonly noDataMessage: string
    readonly tableSettings: TableSettings<T, InMemoryFilterField<T>, InMemOrderBy<T>>
    readonly data: T[]
}

export interface InMemOrderBy<T> extends BaseOrderBy {
    readonly label: string
    readonly extractor: (value: T) => string
}

export interface InMemoryFilterField<T> extends BaseFilterField {
    readonly predicate: (value: T) => boolean
}

export default InMemTable