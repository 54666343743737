import {EmailProviderType} from "lib-shared";
import {ReactNode} from "react";
import MaterialIcon from "../components/common/MaterialIcon";
import {numberToShortNum} from "./Utils";

export function emailProviderTypeWithIcon(ep: EmailProviderType| "manual"): ReactNode {
    switch (ep) {
        case "google":
            return <span><MaterialIcon icon={"google"} size={18} margin={2}/>Google</span>
        case "microsoft":
            return <span><MaterialIcon icon={"microsoft"} size={18} margin={2}/>Microsoft</span>
        default:
            return <span><MaterialIcon icon={"filetype-csv"} size={18} margin={2}/>Manual CSV</span>
    }
}

export function platformWithIcon(platform: string): ReactNode {
    if (platform.toLowerCase() == "tiktok") {
        return <span><MaterialIcon icon={"tiktok"} size={14} margin={1}/>TikTok</span>
    } else {
        return <span><MaterialIcon icon={"instagram"} size={14} margin={1}/>Instagram</span>
    }
}
export function numberToShortNumWithTip(num: number): ReactNode {
    return <span title={num + ""}>{numberToShortNum(num)}</span>
}