import {
    ApiResponse, BulkUploadRequest,
    GenericItemRequest,
    IOrgMembershipsService,
    ListPaginateOptions,
    ModelSchema,
    OrgMembership,
    OrgMembershipSchema
} from "lib-shared";
import {BaseCrudClient} from "./BaseCrudClient";
import {ClientContext, fetchApiGet, fetchApiPaginated, sendApi} from "../FetchUtils";


export class OrgMembershipsClient extends BaseCrudClient<OrgMembership> implements IOrgMembershipsService<ClientContext> {
    public static readonly defaultClient: OrgMembershipsClient = new OrgMembershipsClient()

    get schema(): ModelSchema<OrgMembership> {
        return OrgMembershipSchema;
    }

    async orgUsed(req: void, ctx: ClientContext): Promise<ApiResponse<void>> {
        return await sendApi<void, void>(`/${this.resourceName}/orgUsed`, "POST", req, ctx);
    }

    async getMembershipForOrg(req: GenericItemRequest, ctx: ClientContext): Promise<ApiResponse<OrgMembership>> {
        return await fetchApiGet<OrgMembership>(`/${this.resourceName}/forOrg/${req.id}`, req, ctx)
    }

    async listMyMemberships(req: ListPaginateOptions, ctx: ClientContext): Promise<ApiResponse<OrgMembership[]>> {
        return await fetchApiPaginated<OrgMembership>(`/${this.resourceName}/myOrgs`, req, ctx)
    }
}