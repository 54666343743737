import {ReactNode, useContext} from "react";
import {OrgContext} from "../../context/OrgContext";
import MaterialIcon from "../common/MaterialIcon";
import LoadingFullPage from "../common/LoadingFullPage";

function IsOrgSelectedGuard(props: Props) {
    const {org, loading} = useContext(OrgContext);

    if (loading)
        return <LoadingFullPage/>

    if (org == null) {
        return (
            <div className="">
                <h1 className={"mt-4"}><MaterialIcon icon={"exclamation-circle"} margin={3} size={32}/>Invalid Org</h1>
                <p className={"mt-4"}>This org either does not exist or you don't have permission to access it.</p>
                <p className={"mt-3"}>Please select another org.</p>
            </div>
        )
    }
    return <>{props.children}</>

}

interface Props {
    readonly children: ReactNode
}

export default IsOrgSelectedGuard