import LoadingIndicator from "./LoadingIndicator";
import {ApiResult} from "../hooks/useApi";
import {ReactNode} from "react";

function ResultBlock(props: Props) {

    if (props.isLoading)
        return <LoadingIndicator/>

    if (props.error) {
        return <div className={"text-danger"}>Error: {props.error}</div>
    }

    return (
        <>
            {props.children}
        </>
    )
}

interface Props {
    isLoading: boolean
    error: string | null
    children: ReactNode
}


export default ResultBlock